import { TPropertyInformation } from "types/addListing.types"
import { TDivClickEvent } from "types/components.types"
import { TFilterObject } from "types/filter.types"
import styles from "./AccessFilter.module.scss"
import config from "./AccessFIlter.config"
import UseQuery from "hooks/UseQuery"
import React from "react"
import clsx from "clsx"
import { TAccess } from "types/api.types"
import { motion } from "framer-motion"

type Props = {
  changeCallback?: (value: Partial<TPropertyInformation>) => void
  updateCallback?: (updatedValue: TFilterObject) => void
  keyToValue?: string
  defaultActiveValue?: string | string[]
}

interface IAccessFitlerConfig {
  id: number
  title1: string
  title2: string | null
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  key: string
  active: boolean
}

const AccessFilter: React.FC<Props> = ({ changeCallback, updateCallback, keyToValue, defaultActiveValue }) => {
  const { access } = UseQuery()
  const [accessFilterList, setAccessFilterList] = React.useState<IAccessFitlerConfig[]>(config)

  const handleActiveItem = (e: TDivClickEvent) => {
    const optionID = e.target.getAttribute("data-id")
    if (optionID) {
      const updatedAccessFilterList = getUpdatedAccessFilterList(optionID)
      const selectedKeysList = getSelectedKeys(updatedAccessFilterList)
      updateFilters(selectedKeysList)
      updateAddListing(selectedKeysList)
      setAccessFilterList(updatedAccessFilterList)
    }
  }

  const updateFilters = (selected: TAccess[]) => {
    if (updateCallback) updateCallback({ access: selected })
  }

  const updateAddListing = (selected: TAccess[]) => {
    if (changeCallback && keyToValue) changeCallback({ [keyToValue as keyof TPropertyInformation]: selected })
  }

  const getSelectedKeys = (list: IAccessFitlerConfig[]) =>
    list.map((i) => (i.active ? i.key : null)).filter((i) => i) as TAccess[]

  const getUpdatedAccessFilterList = (optionID: string | number) => {
    return accessFilterList.map((i) => (i.id === Number(optionID) ? { ...i, active: !i.active } : i))
  }

  const handleInitValues = () => {
    const keyList = access ? access : defaultActiveValue
    const keyListNoUndef = (keyList || "") as NonNullable<string>
    setAccessFilterList(config.map((i) => ({ ...i, active: keyListNoUndef.includes(i.key) })))
  }

  React.useEffect(() => {
    handleInitValues()
  }, [])

  return (
    <div className={styles.container}>
      {accessFilterList.map((i) => (
        <motion.div
          className={clsx({
            [styles.accessItem]: true,
            [styles.accessItem_active]: i.active,
          })}
          onClick={handleActiveItem}
          data-id={i.id}
          key={`accessItem${i.id}`}
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
        >
          <i.icon />
          <h2>
            {i.title1} {i.title2}
          </h2>
        </motion.div>
      ))}
    </div>
  )
}

export default AccessFilter
