import { AnimatePresence, usePresence } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import config from "./config"
import React from "react"

type Props = {}

const ResetPassword: React.FC<Props> = () => {
  const [isPresent, safeToRemove] = usePresence()
  const query = UseQuery()
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const activeComponent = config[currentStep]

  React.useEffect(() => {
    const { password } = query
    if (password) setCurrentStep((step) => step + 1)
  }, [])

  React.useEffect(() => {
    if (!isPresent) {
      const step = currentStep === 0 ? 1 : 0
      setCurrentStep(step)
      setTimeout(safeToRemove, 1000)
    }
  }, [isPresent])

  return (
    <AnimatePresence mode="wait">
      <activeComponent.component key={currentStep} />
    </AnimatePresence>
  )
}

export default ResetPassword
