import { ReactComponent as UserCircle } from "assets/icons/signup/postCircle.svg"
import BigButton from "components/BigButton/BigButton"
import styles from "./AddListingStep5.module.scss"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import React from "react"
import { fadeIn } from "constants/animationContants"

type Props = {
  redirectID: number | null
}

const AddListingStep5: React.FC<Props> = ({ redirectID }) => {
  const navigate = useNavigate()

  const redirectToMyProperties = () => navigate("/my-properties")

  const redirectToMap = () => navigate("/")

  const redirectToListing = () => {
    if (redirectID) return navigate(`/parcels/view/listed/${redirectID}/false`)
    redirectToMyProperties()
  }

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <div className={styles.title}>
        <h2>Congratulations!</h2>
      </div>
      <div className={styles.emailCircleWrapper}>
        <UserCircle />
      </div>
      <div className={styles.titleSmall}>
        <h2>Listing submitted for review</h2>
        <h2>and will be posted soon.</h2>
      </div>
      <div className={styles.bigButtonContainer}>
        <BigButton text="View Listing" clickCallback={redirectToListing} />
      </div>
      <div className={styles.haveAnAccount}>
        <h2>
         <span onClick={redirectToMap}>Return to map</span>
        </h2>
      </div>
    </motion.div>
  )
}

export default AddListingStep5
