import { TPropertyInformation } from "types/addListing.types"
import { TDivClickEvent } from "types/components.types"
import { IFilterData, TFilterObject } from "types/filter.types"
import styles from "./SortingFilter.module.scss"
import config from "./SortingFIlter.config"
import UseQuery from "hooks/UseQuery"
import React from "react"
import clsx from "clsx"
import { motion } from "framer-motion"

interface ISortingFilterConfig {
  id: number
  title: string
  active: boolean
  key: string
}

type Props = {
  filterLayout: boolean
  mapConfig?: ISortingFilterConfig[]
  layoutKey?: string
  activeAmount?: "single" | "many"
  keyToValue?: string
  defaultActiveValue?: string
  required?: boolean
  changeCallback?: (value: Partial<TPropertyInformation>) => void
  updateCallback?: (updatedValue: TFilterObject) => void
}

const SortingFilter: React.FC<Props> = ({
  filterLayout = true,
  mapConfig = config,
  layoutKey = "SortingFilterItem",
  activeAmount = "single",
  keyToValue,
  defaultActiveValue,
  required = false,
  changeCallback,
  updateCallback,
}) => {
  const query = UseQuery()
  const [sortButtonsList, setSortButtonsList] = React.useState<ISortingFilterConfig[]>(mapConfig)

  const manyActiveHandler = (id: number) => {
    return [...sortButtonsList].map((i) => (i.id === id ? { ...i, active: !i.active } : i))
  }

  const singleActiveHandler = (id: number) => {
    return [...sortButtonsList].map((i) => (i.id === id ? { ...i, active: !i.active } : { ...i, active: false }))
  }

  const handleActive = (e: TDivClickEvent) => {
    const id = Number(e.target.getAttribute("data-id"))
    const isSingle = activeAmount === "single"
    const updatedSortButtonsList = isSingle ? singleActiveHandler(id) : manyActiveHandler(id)
    const selectedKeys = getSelectedItems(updatedSortButtonsList)
    setSortButtonsList(updatedSortButtonsList)
    updateFilters(selectedKeys[0])
    addListing(selectedKeys[0])
  }

  const updateFilters = (selectedKey: string) => {
    if (updateCallback) updateCallback({ [keyToValue as keyof IFilterData]: selectedKey } as TFilterObject)
  }

  const addListing = (selectedKey: string) => {
    if (changeCallback) changeCallback({ [keyToValue as keyof TPropertyInformation]: selectedKey })
  }

  const getSelectedItems = (list: ISortingFilterConfig[]) =>
    list.map((i) => (i.active ? i.key : null)).filter((i) => i) as string[]

  const handleInitValues = () => {
    if (keyToValue) {
      const keyList = query[keyToValue] ? query[keyToValue] : defaultActiveValue
      const keyListNoUndef = (keyList || "") as NonNullable<string>
      setSortButtonsList(mapConfig.map((i) => ({ ...i, active: keyListNoUndef === i.key })))
    }
  }

  React.useEffect(() => {
    handleInitValues()
  }, [])

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [styles.container_notFilter]: !filterLayout,
      })}
    >
      {sortButtonsList.map((i) => (
        <motion.div
          className={clsx({
            [styles.sortingItem]: true,
            [styles.sortingItem_active]: i.active,
          })}
          onClick={handleActive}
          data-id={i.id}
          key={`${layoutKey}${i.id}`}
          whileHover={{ scale: 1 }}
          whileTap={{ scale: 0.95 }}
        >
          <h2>{i.title}</h2>
        </motion.div>
      ))}
    </div>
  )
}

export default SortingFilter
