import GoogleAuth from "../AuthentificationButtons/GoogleAuth"
import styles from "./SignUpSocialButtons.module.scss"
import React from "react"
import axios from "axios"
import { UseError } from "hooks"
import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"
import { INavigationContext, NavigationContext } from "context/navigationContext"

type Props = {
  validate?: () => boolean
}

const SignUpSocialButtons: React.FC<Props> = ({ validate = () => true }) => {
  const { goBack } = React.useContext(NavigationContext) as INavigationContext
  const { setCredentials } = React.useContext(CredentialsContext) as ICredentialsContext
  const { notify } = UseError()

  const authorizeUser = (token: string, endpoint: string, code: boolean = false) => {
    const data = code ? { code: token } : { access_token: token }
    axios
      .post(endpoint, data)
      .then((response) => {
        const key = response.data.key
        if (key) loginCallback(key)
      })
      .catch((err) => {
        const error = err?.response?.data.non_field_errors?.[0]
        notify(error)
      })
  }

  const loginCallback = (key: string) => {
    setCredentials(key)
    goBack(true)
  }

  return (
    <div className={styles.LoginButtonsList}>
      <GoogleAuth authorizeUser={authorizeUser} validate={validate} />
    </div>
  )
}

export default SignUpSocialButtons
