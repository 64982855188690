import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const resetPassword = async (email: string) => {
  const headers = getHeaders(false)
  return await axios.post(ENDPOINTS.RESET_PASSWORD, { email }, headers)
}

export default resetPassword
