import { ReactComponent as ArrowDown } from "assets/icons/propertyPage/arrowDown.svg"
import { config } from "components/ListFilter/listFilter.config"
import ListFilter from "components/ListFilter/ListFilter"
import { FiltersContext } from "context/FiltersContext"
import styles from "./ListProperties.module.scss"
import { FC, useContext, useState } from "react"
import { TSorting } from "types/filter.types"
import { IApiParcel } from "types/api.types"
import UseQuery from "hooks/UseQuery"
import clsx from "clsx"

type Props = {
  parcelsList: IApiParcel[] | null
}

const def = "created_at"

const ListProperties: FC<Props> = ({ parcelsList }) => {
  const { queryApplyProperties } = useContext(FiltersContext)
  const query = UseQuery()
  const initial = query?.ordering || def

  const [opened, setOpened] = useState(false)
  const [active, setActive] = useState(initial)

  const text = config.find((i) => i.value === active)?.text
  const amount = parcelsList?.length

  const onDropdown = () => setOpened((prev) => !prev)

  const onUpdate = (value: TSorting) => {
    setActive(value)
    setOpened(false)
    queryApplyProperties({ ordering: value })
  }

  return (
    <div className={styles.container}>
      <h2>{amount} results</h2>
      <div className={styles.sort} onClick={onDropdown}>
        <h2>
          Sort By: <span>{text}</span>
        </h2>
        <div
          className={clsx({
            [styles.arrow]: true,
            [styles.arrow_active]: opened,
          })}
        >
          <ArrowDown />
        </div>
      </div>
      <ListFilter opened={opened} onUpdate={onUpdate} active={active} />
    </div>
  )
}

export default ListProperties
