import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

interface IPostPropertyProps {
  file: File
  property: number
}

interface IPostPropertyResponse {
  uuid: string
  file: string
  property: number
}

export const postPropertyPhoto = async (data: IPostPropertyProps): Promise<AxiosResponse<IPostPropertyResponse>> => {
  const { file, property } = data
  const headers = getHeaders()
  const multiPartHeaders = { ...headers, "Content-Type": "multipart/form-data" }
  const formData = new FormData()
  formData.append("file", file)
  formData.append("property", String(property))
  return await axios.post(ENDPOINTS.POST_PROPERTY_PHOTO, formData, multiPartHeaders)
}

export default postPropertyPhoto
