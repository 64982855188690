import styles from "./MapPolygonsPopup.module.scss"
import React from "react"
import { AnimatePresence, motion } from "framer-motion"

type Props = {
  visible: boolean
}

const animation = {
  hidden: { opacity: 0, scale: 1.1 },
  show: { opacity: 1, scale: 1, transition: { type: "spring", stiffness: 200 } },
}

const MapPolygonsPopup: React.FC<Props> = ({ visible }) => {
  return (
    <AnimatePresence mode="wait">
      {visible ? (
        <motion.div
          className={styles.mapPolygonsPopupContainer}
          variants={animation}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <div className={styles.mapPolygonsPopup}>Zoom to see parcel boundaries</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default MapPolygonsPopup
