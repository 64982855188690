import { $actions as $app_actions } from "redux/reducers/app.reducer"
import { PayloadAction } from "@reduxjs/toolkit"
import { useLocation } from "react-router-dom"
import { createContext, useRef } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "redux/store"
import UseQuery from "hooks/UseQuery"
import qs from "qs"

const defaults = { listing_type: "FOR_LEASE" }

const messages = { abort: "new request is detected" }

export interface IBounding {
  x1: number
  y1: number
  x2: number
  y2: number
}

type TParcelRequest = "pagination" | "view"

type TRequest = Promise<PayloadAction<any>> & { abort: (reason?: string | undefined) => void; requestId: string }

interface IRequest {
  type: TParcelRequest
  request: TRequest
}

export interface IParcelRequestsContext {
  parcelRequest: () => void
  parcelInViewRequest: (boundingBox: IBounding) => void
  polygonInViewRequest: (boundingBox: IBounding) => void
}

export const ParcelsRequestsContext = createContext({} as IParcelRequestsContext)

export const ParcelRequestsProvider = (props: { children: JSX.Element }) => {
  const dispatch: AppDispatch = useDispatch()
  const requestsRef = useRef<IRequest[]>([])
  const { listing_type } = UseQuery()
  const { search } = useLocation()

  const isOffmarket = listing_type === "OFF_MARKET" || listing_type === "SOLD"

  const parseSearchWithDefaults = (mod?: boolean) => {
    const searchString = search.replace("?", "")
    const { listing_type } = qs.parse(searchString)
    const query = listing_type
      ? searchString
      : qs.stringify({ ...qs.parse(searchString), ...defaults }, { arrayFormat: "repeat", encode: false })
    return mod ? `?${query}` : query
  }

  const cancelRequests = (type: TParcelRequest) => {
    if (!requestsRef.current.length) return
    const requests = requestsRef.current
    const requestCancelList = requests.filter((i) => i.type === type)
    const requestCancelIdList = requestCancelList.map((i) => i.request.requestId)
    if (requestCancelList.length) {
      requestCancelList.forEach((i) => i.request.abort(messages.abort))
      requestsRef.current = [...requests].filter((i) => !requestCancelIdList.includes(i.request.requestId))
    }
  }

  const addRequest = (type: TParcelRequest, request: TRequest) => {
    const req = { type, request }
    requestsRef.current = [...requestsRef.current, req]
  }

  const createControlledRequest = (type: TParcelRequest, request: TRequest) => {
    cancelRequests(type)
    addRequest(type, request)
  }

  const parcelRequest = () => {
    const type = "pagination"
    const searchWithDefaults = parseSearchWithDefaults(true)
    const request = dispatch($app_actions.fetchParcels({ query: searchWithDefaults, offmarket: isOffmarket }))
    createControlledRequest(type, request)
  }

  const parcelInViewRequest = (boundingBox: IBounding) => {
    const type = "view"
    const searchWithDefaults = parseSearchWithDefaults()
    const request = dispatch(
      $app_actions.fetchParcelsInView({ boundingBox, query: searchWithDefaults, offmarket: isOffmarket })
    )
    createControlledRequest(type, request)
  }

  const polygonInViewRequest = (boundingBox: IBounding) => {
    const type = "view"
    const searchWithDefaults = parseSearchWithDefaults()
    const request = dispatch($app_actions.fetchPolygonsGeojsonInView({ boundingBox, query: searchWithDefaults }))
    createControlledRequest(type, request)
  }

  const value: IParcelRequestsContext = { parcelRequest, parcelInViewRequest, polygonInViewRequest }

  return <ParcelsRequestsContext.Provider value={value}>{props.children}</ParcelsRequestsContext.Provider>
}
