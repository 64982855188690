import { ReactComponent as ArrowBack } from "assets/icons/propertyPage/backArrow.svg"
import { useNavigate } from "react-router-dom"
import styles from "./TitleHeader.module.scss"
import React from "react"
import { motion } from "framer-motion"
import { INavigationContext, NavigationContext } from "context/navigationContext"

type Props = {
  title: string
  redirectPath?: string
}

const TitleHeader: React.FC<Props> = ({ title, redirectPath }) => {
  const navigate = useNavigate()
  const { goBack } = React.useContext(NavigationContext) as INavigationContext

  const back = () => {
    if (redirectPath) return navigate(redirectPath)
    goBack(true)
  }

  return (
    <div className={styles.container}>
      <motion.div className={styles.backArrow} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <ArrowBack onClick={back} />
      </motion.div>
      <h2>{title}</h2>
    </div>
  )
}

export default TitleHeader
