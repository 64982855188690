import { ReactComponent as ArrowDown } from "assets/icons/propertyPage/arrowDown.svg"
import { TDivClickEvent } from "types/components.types"
import s from "./Dropdown.module.scss"
import { FC, useEffect, useState } from "react"
import clsx from "clsx"

interface IDropdownConfig {
  id: number
  text: string
  active: boolean
  value: string
}

type Props = {
  config: IDropdownConfig[]
  callback: (value: Record<string, any>) => void
  keyToValue: string
  initial?: string
}

const Dropdown: FC<Props> = ({ config, keyToValue, initial, callback }) => {
  const [list, setList] = useState(config)
  const [opened, setOpened] = useState(false)

  const active = list.find((i) => i.active)?.text || "Select"

  const update = (id: number) => {
    const updated = list.map((i) => {
      const active = i.id === id
      return { ...i, active }
    })
    setList(updated)
  }

  const onUpdate = (next: IDropdownConfig) => {
    const { value, id } = next
    update(id)
    setOpened(false)
    callback({ [keyToValue]: value })
  }

  const onItemClick = (e: TDivClickEvent) => {
    const id = Number(e.target.getAttribute("data-id"))
    const next = list.find((i) => i.id === id)
    if (next) onUpdate(next)
  }

  const onMount = () => {
    if (!initial) return
    setList((prev) => prev.map((i) => ({ ...i, active: i.value === initial })))
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <div className={s.container}>
      <div className={s.selector} onClick={() => setOpened(!opened)}>
        <h2>{active}</h2>
        <ArrowDown />
      </div>
      <div
        className={clsx({
          [s.dropdown]: true,
          [s.dropdown_opened]: opened,
        })}
      >
        {list.map((i) => (
          <div
            className={clsx({
              [s.item]: true,
              [s.item_active]: i.active,
            })}
            onClick={onItemClick}
            data-id={i.id}
            key={i.id}
          >
            {i.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Dropdown
