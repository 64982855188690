import ENDPOINTS from "constants/api.constants"
import axios from "axios"

interface IUserRegistration {
  email: string
  password1: string
  password2: string
}

const register = async (user: IUserRegistration) => {
  const registration = await axios.post(ENDPOINTS.REGISTER_USER, user)
  return registration.data.key as string
}

export default register
