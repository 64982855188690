import s from "./EditProfileDivider.module.scss"
import React, { FC } from "react"

type Props = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
}

const EditProfileDivider: FC<Props> = ({ icon, text }) => {
  const Icon = icon

  return (
    <div className={s.divider}>
      <Icon />
      <h2>{text}</h2>
    </div>
  )
}

export default EditProfileDivider
