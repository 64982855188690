import { TDivClickEvent, TInputChangeEvent } from "types/components.types"
import { IFilterData, TFilterObject } from "types/filter.types"
import styles from "./CardFilter.module.scss"
import { TProperty } from "types/api.types"
import config from "./CardFilter.config"
import UseQuery from "hooks/UseQuery"
import React from "react"
import clsx from "clsx"
import { motion } from "framer-motion"

type Props = {
  noReset: boolean
  maxActiveValues?: number
  cardClickCallback?: (i: TProperty[]) => void
  updateCallback?: (updatedValue: TFilterObject) => void
  cardFilterConfig?: typeof config
  defaultActiveValues?: TProperty[]
  noSelectAll?: boolean
}

interface ICardFilterConfig {
  id: number
  text: string
  key: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  active: boolean
}

const CardFilter: React.FC<Props> = ({
  cardClickCallback,
  updateCallback,
  noReset = false,
  maxActiveValues = 100,
  cardFilterConfig = config,
  defaultActiveValues,
  noSelectAll = true,
}) => {
  const { property_type } = UseQuery()
  const allPropertiesRef = React.useRef<HTMLInputElement>(null)
  const [cardList, setCardList] = React.useState<ICardFilterConfig[]>(cardFilterConfig)

  const addListingUpdateCallback = (value: TProperty[]) => {
    if (cardClickCallback) cardClickCallback(value)
  }

  const filterUpdateCallback = (selectedKeys: string[]) => {
    if (updateCallback) updateCallback({ property_type: selectedKeys } as IFilterData)
  }

  const handleAllPropertyTypes = (e: TInputChangeEvent) => updateAllBlock(e.target.checked)

  const updateAllBlock = (state: boolean) => {
    const updated = cardList.map((i) => ({ ...i, active: state }))
    const keys = getSelectedKeys(updated)
    filterUpdateCallback(keys)
    setCardList(updated)
  }

  const handleReset = () => {
    const inputref = allPropertiesRef.current
    if (inputref) inputref.checked = true
    updateAllBlock(true)
  }

  const setActiveBlockitem = (e: TDivClickEvent) => {
    const optionID = e.target.getAttribute("data-id")
    const inputref = allPropertiesRef.current

    if (optionID) {
      const updatedCardList = getUpdatedSaleTypesList(optionID)
      const selectedKeys = getSelectedKeys(updatedCardList)
      if (!updatedCardList.find((i) => !i.active) && inputref) inputref.checked = true
      setCardList(updatedCardList)
      addListingUpdateCallback(selectedKeys)
      filterUpdateCallback(selectedKeys)
    }
  }
  const getSelectedKeys = (list: ICardFilterConfig[]) =>
    list.map((i) => (i.active ? i.key : null)).filter((i) => i) as TProperty[]

  const getActiveItemsLength = () => cardList.filter((i) => i.active).length

  const handleSingleTypeSelection = (optionID: string) => {
    return cardList.map((i) => (i.id === Number(optionID) ? { ...i, active: true } : { ...i, active: false }))
  }

  const handleMultiTypeSelection = (optionID: string) => {
    const activeAmount = getActiveItemsLength()
    const inputref = allPropertiesRef.current

    return cardList.map((i) => {
      const isCurrent = i.id === Number(optionID)
      const isCanSelect = activeAmount < maxActiveValues

      if (isCurrent) {
        if (i.active) {
          if (inputref) inputref.checked = false
          return { ...i, active: false }
        }
        if (isCanSelect) return { ...i, active: true }
      }
      return i
    })
  }

  const getUpdatedSaleTypesList = (optionID: string) => {
    if (maxActiveValues !== 1) return handleMultiTypeSelection(optionID)
    return handleSingleTypeSelection(optionID)
  }

  const handleInitValues = () => {
    const keyList = property_type ? property_type : defaultActiveValues
    const keyListNoUndef = Array.isArray(keyList) ? keyList : [keyList]
    const newCardList = cardFilterConfig.map((i) => {
      const isExist = (keyListNoUndef || []).find((def) => def === i.key)
      const standart = property_type ? { ...i, active: false } : i
      return isExist ? { ...i, active: true } : standart
    })
    setCardList(newCardList)
  }

  React.useEffect(() => {
    handleInitValues()
  }, [])

  return (
    <>
      <div className={styles.container}>
        {cardList.map((i, index) => {
          return (
            <motion.div
              onClick={setActiveBlockitem}
              data-id={i.id}
              key={`cardFilter${index}`}
              className={clsx({
                [styles.cardBlock]: true,
                [styles.cardBlock_active]: i.active,
              })}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
            >
              <i.icon />
              <h2>{i.text}</h2>
            </motion.div>
          )
        })}
      </div>

      <div
        className={clsx({
          [styles.additionalOptions]: true,
          [styles.additionalOptions_noReset]: noReset,
        })}
      >
        {noSelectAll ? null : (
          <div className={styles.chooseAllProperties}>
            <input
              type="checkbox"
              onChange={handleAllPropertyTypes}
              ref={allPropertiesRef}
              defaultChecked={!property_type}
            />
            <h2>All Property Types</h2>
          </div>
        )}

        {noReset ? null : <button onClick={handleReset}>Reset</button>}
      </div>
    </>
  )
}

export default CardFilter
