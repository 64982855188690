import CheckboxInput from "components/CheckboxInput/CheckboxInput"
import { FC, Fragment } from "react"
import { ILoggedUser } from "types/api.types"

type Props = {
  onUpdate: (value: Partial<ILoggedUser>) => void
  updateData: Partial<ILoggedUser>
}

const EditProfileOptionalNotifications: FC<Props> = ({ updateData, onUpdate }) => {
  const {
    notification_bookmarked_listing_price_change,
    notification_bookmarked_listing_removed,
    notification_bookmarked_listing_has_new_article,
    notification_bookmarked_listing_has_sold,
  } = updateData

  return (
    <Fragment>
      <CheckboxInput
        text="Watch list property has a price change"
        keyToValue="notification_bookmarked_listing_price_change"
        defaultValue={notification_bookmarked_listing_price_change}
        callback={onUpdate}
      />
      <CheckboxInput
        text="Watch list property is removed"
        keyToValue="notification_bookmarked_listing_removed"
        defaultValue={notification_bookmarked_listing_removed}
        callback={onUpdate}
      />
      {/*<CheckboxInput*/}
      {/*  text="Listing that i have favourited has a new article associated to it"*/}
      {/*  keyToValue="notification_bookmarked_listing_has_new_article"*/}
      {/*  defaultValue={notification_bookmarked_listing_has_new_article}*/}
      {/*  callback={onUpdate}*/}
      {/*/>*/}
      <CheckboxInput
        text="Watch list property has been sold"
        keyToValue="notification_bookmarked_listing_has_sold"
        defaultValue={notification_bookmarked_listing_has_sold}
        callback={onUpdate}
      />
    </Fragment>
  )
}

export default EditProfileOptionalNotifications
