import { ReactComponent as Setting } from "assets/icons/settings.svg"
import { ReactComponent as Terms } from "assets/icons/policies.svg"
import { ReactComponent as Policy } from "assets/icons/policy.svg"
import { ReactComponent as Logout } from "assets/icons/logout.svg"
import { ReactComponent as Bell } from "assets/icons/bell.svg"
import styles from "./UserCircleDropDown.module.scss"
import { FC, Fragment, RefObject } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

type Props = {
  logoutOnClick: () => void
  componentRef: RefObject<HTMLDivElement>
}

const UserCircleDropDown: FC<Props> = ({ logoutOnClick, componentRef }) => {
  const navigate = useNavigate()

  const handleManageAccount = () => navigate("/me")

  const handleNotifications = () => navigate("/me/notifications")

  const handlePrivacyPolicy = () => navigate("/privacy")

  const handleTerms = () => navigate("/tos")

  return (
    <Fragment>
      <motion.div
        ref={componentRef}
        className={styles.dropdown}
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{ opacity: { duration: 0.1 } }}
      >
        <div className={styles.dropdownItem} onClick={handleManageAccount}>
          <Setting />
          <h2>Manage Account</h2>
        </div>
        <div className={styles.dropdownItem} onClick={handleNotifications}>
          <Bell />
          <h2>Notifications</h2>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.dropdownItem} onClick={handlePrivacyPolicy}>
          <Policy />
          <h2>Privacy policy</h2>
        </div>
        <div className={styles.dropdownItem} onClick={handleTerms}>
          <Terms />
          <h2>Terms</h2>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.dropdownItem} onClick={logoutOnClick}>
          <Logout />
          <h2>Logout</h2>
        </div>
      </motion.div>
    </Fragment>
  )
}

export default UserCircleDropDown
