import { ReactComponent as ForSale } from "assets/icons/forSale.svg"
import { ReactComponent as More } from "assets/icons/more.svg"
import ForSaleFilter from "../Filters/ForSaleFilter/ForSaleFiter"
import AdvancedFIlters from "../Filters/AdvancedFilters/AdvancedFIlters"

const config = [
  {
    id: 0,
    icon: ForSale,
    text: "For Lease",
    keyToValue: ["listing_type"],
    valueType: "single",
    active: false,
    component: ForSaleFilter,
  },
  {
    id: 4,
    icon: More,
    text: "Filters",
    keyToValue: [],
    active: false,
    component: AdvancedFIlters,
  },
]

export default config
