import { combineComponents } from "utils/combineProviders"
import { CredentialsProvider } from "./credentialsContext"
import { FiltersProvider } from "./FiltersContext"
import { MapMarkerContextProvider } from "./mapMarkerContext"
import { MapPolygonContextProvider } from "./mapPolygonContext"
import { NavigationProvider } from "./navigationContext"
import { ParcelContextProvider } from "./parcelContext"
import { ParcelRequestsProvider } from "./parcelRequestsContext"

const providers = [
  ParcelContextProvider,
  MapMarkerContextProvider,
  MapPolygonContextProvider,
  ParcelRequestsProvider,
  NavigationProvider,
  CredentialsProvider,
  FiltersProvider,
]

export const AppContextProvider = combineComponents(...providers)
