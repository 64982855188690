import { ReactComponent as Retail } from "assets/icons/retail.svg"
import { ReactComponent as Warehouse } from "assets/icons/cardFilter/warehouse.svg"
import { ReactComponent as Industrial } from "assets/icons/cardFilter/industrial.svg"
import { ReactComponent as Office } from "assets/icons/cardFilter/office.svg"
import { ReactComponent as Multifamily } from "assets/icons/cardFilter/multifamily.svg"
import { ReactComponent as Flex } from "assets/icons/cardFilter/flex.svg"
import { ReactComponent as Hotelmotel } from "assets/icons/cardFilter/hotelmotel.svg"
import { ReactComponent as Healthcare } from "assets/icons/cardFilter/healthcare.svg"
import { ReactComponent as Parkinglot } from "assets/icons/cardFilter/parkinglot.svg"
import { ReactComponent as Vacantland } from "assets/icons/cardFilter/vacantland.svg"
import { ReactComponent as Other } from "assets/icons/cardFilter/other.svg"

const icons = [
  {
    key: "retail",
    icon: Retail,
  },
  {
    key: "warehouse",
    icon: Warehouse,
  },
  {
    key: "industrial",
    icon: Industrial,
  },
  {
    key: "office",
    icon: Office,
  },
  {
    key: "multifamily",
    icon: Multifamily,
  },
  {
    key: "flex",
    icon: Flex,
  },
  {
    key: "hotel",
    icon: Hotelmotel,
  },
  {
    key: "healthcare",
    icon: Healthcare,
  },
  {
    key: "parking",
    icon: Parkinglot,
  },
  {
    key: "vacant",
    icon: Vacantland,
  },
  {
    key: "other",
    icon: Other,
  },
]

export default icons
