export const polygonFillStyle = {
  id: "parcels",
  type: "fill",
  source: "my-data",
  minzoom: 14,
  paint: {
    "fill-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "#9554FF",
      ["boolean", ["feature-state", "selected"], false],
      "#9554FF",
      "#BF9AFF",
    ],
    "fill-opacity": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      1,
      ["boolean", ["feature-state", "selected"], false],
      1,
      0.3,
    ],
  },
}

export const polygonBorderStyle = {
  id: "state-borders",
  source: "my-data",
  minzoom: 14,
  type: "line",
  layout: {},
  paint: {
    "line-color": "#9554FF",
    "line-width": 2,
  },
}
