import axios from "axios"
import ENDPOINTS from "constants/api.constants"

interface IUserLogin {
  email: string
  password: string
}

const login = async (user: IUserLogin) => {
  const login = await axios.post(ENDPOINTS.LOGIN_USER, user)
  return login.data.key
}

export default login
