import { polygonBorderStyle, polygonFillStyle } from "components/Map/layerStyles"
import { parcelsPolygonsGeojsonInViewSelector } from "redux/reducers/app.reducer"
import { Layer, LayerProps, Source } from "react-map-gl"
import { TParcelsGeojsonResponse } from "types/api.types"
import { useSelector } from "react-redux"

const emptyPolygonsData = {
  type: "FeatureCollection",
  features: [],
} as TParcelsGeojsonResponse

const MapPolygons = () => {
  const parcelsPolygonsGeojsonInView = useSelector(parcelsPolygonsGeojsonInViewSelector)

  return (
    <Source
      id="my-data"
      type="geojson"
      data={parcelsPolygonsGeojsonInView ? parcelsPolygonsGeojsonInView : emptyPolygonsData}
    >
      <Layer {...(polygonFillStyle as LayerProps)} />
      <Layer {...(polygonBorderStyle as LayerProps)} />
    </Source>
  )
}

export default MapPolygons
