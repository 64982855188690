import { TSorting } from "types/filter.types"
import { config } from "./listFilter.config"
import styles from "./ListFilter.module.scss"
import React from "react"
import clsx from "clsx"

type Props = {
  opened: boolean
  active: string
  onUpdate: (value: TSorting) => void
}

const ListFilter: React.FC<Props> = ({ opened, active, onUpdate }) => {
  return (
    <React.Fragment>
      {opened ? (
        <div className={styles.dropdown}>
          {config.map((i) => (
            <div
              key={`dropItem${i.id}`}
              className={clsx({
                [styles.sortItem]: true,
                [styles.sortItem_active]: i.value === active,
              })}
              onClick={() => onUpdate(i.value)}
            >
              {i.text}
            </div>
          ))}
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default ListFilter
