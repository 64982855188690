import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import ConfirmationLayout from "components/ConfirmationLayout/ConfirmationLayout"
import { Dispatch, FC, SetStateAction } from "react"
import { AnimatePresence } from "framer-motion"
import s from "./AboutUsPopup.module.scss"

type Props = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const AboutUsPopup: FC<Props> = ({ show, setShow }) => {
  const close = () => setShow(false)

  const renderAboutUs = (
    <div className={s.content}>
      <div className={s.logo}>
        <BigLogo />
      </div>
      <div className={s.text}>
        <h2>
          comprops is a novel platform for buying, selling, leasing, and monitoring commercial real estate, empowering
          all stakeholders in the CRE ecosystem.
        </h2>
        <h2>Founded in Pittsburgh, PA and currently only available for properties in the greater Pittsburgh region.</h2>
        <h2>To learn more about our services, contact us at</h2>
        <a href="mailto:hello@comprops.com">hello@comprops.com</a>
      </div>
    </div>
  )

  return (
    <AnimatePresence>
      {show ? (
        <ConfirmationLayout cancelCallback={close} showButtons={false}>
          {renderAboutUs}
        </ConfirmationLayout>
      ) : null}
    </AnimatePresence>
  )
}

export default AboutUsPopup
