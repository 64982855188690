import Skeleton from "react-loading-skeleton"
import React from "react"

type Props = {
  src: string
}

const CardSlide: React.FC<Props> = ({ src }) => {
  const [loaded, setLoaded] = React.useState(false)

  const onLoad = () => setLoaded(true)

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {!loaded ? <Skeleton height={"100%"} width={"100%"} /> : null}
      <img
        src={src}
        alt="sliderImage"
        style={{ display: loaded ? "unset" : "none" }}
        onLoad={onLoad}
        onError={onLoad}
      />
    </div>
  )
}

export default CardSlide
