import AddListingInput from "components/AddListingInput/AddListingInput"
import { ILoggedUser } from "types/api.types"
import { FC, Fragment } from "react"

interface IEditProfileValidationList {
  name: boolean
  surname: boolean
  email: boolean
  phone: boolean
}

type Props = {
  onUpdate: (value: Partial<ILoggedUser>) => void
  validationList: IEditProfileValidationList
  updateData: Partial<ILoggedUser>
}

const EditProfileContactInformation: FC<Props> = ({ updateData, validationList, onUpdate }) => {
  const { first_name, last_name, email_address, company, phone_number, position } = updateData

  return (
    <Fragment>
      <AddListingInput
        type="text"
        title="First Name"
        required
        placeholder="First Name"
        changeCallback={onUpdate}
        keyToValue={"first_name"}
        maxLength={20}
        validated={validationList.name}
        defaultValue={first_name}
      />
      <AddListingInput
        type="text"
        title="Last Name"
        required
        placeholder="Last Name"
        changeCallback={onUpdate}
        keyToValue={"last_name"}
        maxLength={20}
        validated={validationList.surname}
        defaultValue={last_name}
      />
      <AddListingInput
        type="text"
        title="Email"
        required
        placeholder="Email"
        changeCallback={onUpdate}
        keyToValue={"email_address"}
        validated={validationList.email}
        defaultValue={email_address}
      />
      <AddListingInput
        type="phone"
        title="Phone Number"
        required
        placeholder="Phone Number"
        changeCallback={onUpdate}
        validated={validationList.phone}
        keyToValue={"phone_number"}
        maxLength={12}
        defaultValue={phone_number}
      />
      <AddListingInput
        type="text"
        title="Company"
        placeholder="Company"
        changeCallback={onUpdate}
        keyToValue={"company"}
        defaultValue={company}
      />
      <AddListingInput
        type="text"
        title="Position"
        placeholder="Position"
        changeCallback={onUpdate}
        keyToValue={"position"}
        defaultValue={position}
      />
    </Fragment>
  )
}

export default EditProfileContactInformation
