import clsx from "clsx"
import React from "react"
import s from "./LinkButton.module.scss"

type Props = {
  text: string
  disabled?: boolean
  secondary?: boolean
  onClick?: () => void | (() => Promise<void>)
}

const LinkButton: React.FC<Props> = ({ disabled = false, secondary = false, text, onClick }) => {
  const click = () => (!disabled && onClick ? onClick() : undefined)

  return (
    <div
      onClick={click}
      className={clsx({
        [s.link]: true,
        [s.link_disabled]: disabled,
        [s.link_secondary]: secondary,
      })}
    >
      {text}
    </div>
  )
}

export default LinkButton
