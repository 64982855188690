import { ReactComponent as AreaSize } from "assets/icons/areaSize.svg"
import { ReactComponent as Address } from "assets/icons/address.svg"
import CardPreviewSlider from "../CardPreviewSlider/CardPreviewSlider"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import { formatListingType } from "utils/formatListingType"
import icons from "../PropertyCard/properyIconsConfig"
import React, { BaseSyntheticEvent } from "react"
import LikeButton from "../LikeButton/LikeButton"
import { formatCash } from "utils/FormatPrice"
import { IApiParcel } from "types/api.types"
import styles from "./Card.module.scss"
import UseQuery from "hooks/UseQuery"
import clsx from "clsx"

type Props = {
  parcel: IApiParcel
  handleCardClick: (e: BaseSyntheticEvent) => void
  onBookmark?: (id: number, bookmarked: boolean) => void
  isMap?: boolean
  typeAffected?: boolean
}

const Card: React.FC<Props> = ({ handleCardClick, onBookmark, parcel, isMap, typeAffected }) => {
  const query = UseQuery()
  const {
    id,
    most_recent_sales_price,
    price,
    formatted_full_address,
    parcel_sqft,
    listing_type,
    property_type,
    photos,
  } = parcel as IApiParcel

  const renderPreview = () => {
    return (
      <div className={styles.preview}>
        <CardPreviewSlider isMap parcelPhotosList={photos} />
      </div>
    )
  }

  const formatCardPrice = (price: string) => {
    return String(price).length > 5 ? formatCash(price) : formatPriceNoDecimal(price)
  }

  const renderInfoHeader = () => {
    const cardPrice = price || most_recent_sales_price
    const isPriceExist = cardPrice && String(cardPrice)?.length

    return (
      <div className={styles.cardHeader}>
        <div className={styles.price}>
          <h2>{isPriceExist ? `$${formatCardPrice(cardPrice)}` : "Price N/A"}</h2>
          {listing_type === "FOR_LEASE" ? <h3>/month</h3> : null}
        </div>

        <div className={styles.like}>
          <LikeButton parcel={parcel} onBookmark={onBookmark} />
        </div>
      </div>
    )
  }

  const renderInfoItems = () => {
    return (
      <React.Fragment>
        <div
          className={clsx({
            [styles.infoItem]: true,
            [styles.infoItem_area]: true,
          })}
        >
          <Address />
          <h4>{formatted_full_address ? formatted_full_address : "N/A"}</h4>
        </div>

        <div className={styles.infoItem}>
          <AreaSize />
          <h4>{parcel_sqft ? `${formatPriceNoDecimal(parcel_sqft)} SQFT` : "N/A"}</h4>
        </div>
      </React.Fragment>
    )
  }

  const renderSellType = () => {
    const type = query.listing_type || listing_type
    const text = formatListingType(type)

    const styleFormatted = type.replace(/(FOR_|-|_)/g, "").toLowerCase()
    const background = styles[`typeItemFilled_${styleFormatted}`]
    const border = styles[`typeItemFilled_bordered_${styleFormatted}`]

    return (
      <div
        className={clsx({
          [styles.typeItem]: true,
          [styles.typeItemFilled]: true,
          [background]: true,
          [border]: styleFormatted === "offmarket" || typeAffected,
        })}
      >
        <h5>{text}</h5>
      </div>
    )
  }

  const renderPropertyType = () => {
    const activeIcon = icons.find((i) => i.key === property_type)
    const Icon = activeIcon?.icon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    return Icon ? (
      <div className={styles.typeItem}>
        <Icon />
        <h5>{property_type}</h5>
      </div>
    ) : null
  }

  const renderTypeList = (
    <div className={styles.typeList}>
      {renderSellType()}
      {renderPropertyType()}
    </div>
  )

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [styles.container_atMap]: isMap,
        [styles.container_lease]: listing_type === "FOR_LEASE" && typeAffected,
        [styles.container_sale]: listing_type === "FOR_SALE" && typeAffected,
        [styles.container_sold]: listing_type === "SOLD" && typeAffected,
        [styles.container_offmarket]: listing_type === "OFF-MARKET" && typeAffected,
      })}
    >
      {renderPreview()}
      <div
        className={clsx({
          [styles.cardInfo]: true,
          [styles.cardInfo_atMap]: isMap,
        })}
        onClick={handleCardClick}
        data-id={id}
      >
        {renderInfoHeader()}

        {renderInfoItems()}

        {renderTypeList}
      </div>
    </div>
  )
}

export default Card
