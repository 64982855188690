import { ReactComponent as PriceBadge } from "assets/icons/priceBadge.svg"
import { ReactComponent as AreaSize } from "assets/icons/areaSize.svg"
import { ReactComponent as Address } from "assets/icons/address.svg"
import CardPreviewSlider from "../CardPreviewSlider/CardPreviewSlider"
import DeleteButton from "components/DeleteButton/DeleteButton"
import { ReactComponent as Show } from "assets/icons/show.svg"
import { ReactComponent as Hide } from "assets/icons/hide.svg"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import { formatListingType } from "utils/formatListingType"
import React, { BaseSyntheticEvent, useState } from "react"
import EditButton from "components/EditButton/EditButton"
import { IApiParcelByUuid } from "types/api.types"
import styles from "./PropertyCard.module.scss"
import { formatCash } from "utils/FormatPrice"
import icons from "./properyIconsConfig"
import { motion } from "framer-motion"
import clsx from "clsx"

type Props = {
  handleCardClick: (e: BaseSyntheticEvent) => void
  handleDeleteButton: (parcel: IApiParcelByUuid) => void
  handleEditButton: (parcel: IApiParcelByUuid) => void
  handleListingVisibility: (data: { id: number; hidden: boolean }) => Promise<void>
  parcel: IApiParcelByUuid
}

const PropertyCard: React.FC<Props> = ({
  handleCardClick,
  handleDeleteButton,
  handleEditButton,
  handleListingVisibility,
  parcel,
}) => {
  const {
    id,
    price,
    formatted_full_address,
    parcel_sqft,
    listing_type = "N/A",
    property_type = "N/A",
    hidden,
    photos,
  } = parcel as IApiParcelByUuid

  const [pending, setPending] = useState(false)

  const handleVisibility = async (hidden: boolean) => {
    setPending(true)
    await handleListingVisibility({ id, hidden })
    setPending(false)
  }

  const renderPreview = (
    <div className={styles.preview}>
      <CardPreviewSlider isMap parcelPhotosList={photos} />
    </div>
  )

  const renderTitlePrice = (
    <div className={styles.cardHeader}>
      <div className={styles.price}>
        <h2>{`$${formatCash(price)}`}</h2>
        {listing_type === "FOR_LEASE" ? <h3>/month</h3> : null}
      </div>
    </div>
  )

  const renderHideButton = (
    <motion.div
      className={clsx({
        [styles.svgContainer]: true,
        [styles.svgContainer_disabled]: pending,
      })}
      whileTap={{ scale: pending ? 1 : 0.9 }}
      whileHover={{ scale: 1.05 }}
    >
      <Hide onClick={() => handleVisibility(false)} />
    </motion.div>
  )

  const renderShowButton = (
    <motion.div
      className={clsx({
        [styles.svgContainer]: true,
        [styles.svgContainer_disabled]: pending,
      })}
      whileTap={{ scale: pending ? 1 : 0.9 }}
      whileHover={{ scale: 1.05 }}
    >
      <Show onClick={() => handleVisibility(true)} />
    </motion.div>
  )

  const renderTools = (
    <div className={styles.toolsContainer}>
      <EditButton onClick={() => handleEditButton(parcel as IApiParcelByUuid)} />
      <div className={styles.visibilityTools}>
        <motion.div className={styles.hidingTool}>{hidden ? renderHideButton : renderShowButton}</motion.div>
        <DeleteButton onClick={() => handleDeleteButton(parcel as IApiParcelByUuid)} />
      </div>
    </div>
  )

  const renderAddress = (
    <div className={styles.infoItem}>
      <AreaSize />
      <h4>{`${formatPriceNoDecimal(parcel_sqft)} SQFT`}</h4>
    </div>
  )

  const renderArea = (
    <div
      className={clsx({
        [styles.infoItem]: true,
        [styles.infoItem_area]: true,
      })}
    >
      <Address />
      <h4>{`${formatted_full_address}`}</h4>
    </div>
  )

  const renderPrice = (
    <div className={styles.infoItem}>
      <PriceBadge />
      <h4>{"$" + formatPriceNoDecimal(price)}</h4>
    </div>
  )

  const renderInfoItems = (
    <div className={styles.information}>
      {renderArea}
      {renderAddress}
      {renderPrice}
    </div>
  )

  const renderSellType = () => {
    const type = formatListingType(listing_type)
    return (
      <div
        className={clsx({
          [styles.typeItem]: true,
          [styles.typeItemFilled]: true,
          [styles.typeItemFilled_lease]: listing_type === "FOR_LEASE",
          [styles.typeItemFilled_sale]: listing_type === "FOR_SALE",
          [styles.typeItemFilled_sold]: listing_type === "SOLD",
        })}
      >
        <h5>{type}</h5>
      </div>
    )
  }

  const renderPropertyType = () => {
    const activeIcon = icons.find((i) => i.key === property_type)
    const Icon = activeIcon?.icon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    return (
      <div className={styles.typeItem}>
        <Icon />
        <h5>{property_type}</h5>
      </div>
    )
  }

  const renderTypeList = (
    <div className={styles.typeList}>
      {renderSellType()}
      {renderPropertyType()}
    </div>
  )

  return (
    <div className={styles.container}>
      {renderPreview}
      <div className={styles.cardInfo} data-id={id} onClick={handleCardClick}>
        {renderTitlePrice}
        {renderInfoItems}
        {renderTypeList}
      </div>
      {renderTools}
    </div>
  )
}

export default PropertyCard
