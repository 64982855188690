const defaultPath = {
  initial: "hide",
  animate: "show",
  exit: "hide",
}

const advancedPath = {
  initial: "hide",
  animate: "show",
  exit: "exit",
}

export const fadeIn = {
  variants: {
    hide: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
  },
  ...defaultPath,
  transition: { opacity: { duration: 0.4 } },
}

export const scaleIn = {
  variants: {
    hide: {
      scale: 0,
    },
    show: {
      scale: 1,
    },
  },
  ...defaultPath,
  transition: { opacity: { duration: 0.4 } },
}

export const slideInBottom = {
  variants: {
    hide: {
      top: "200vh",
    },
    show: {
      top: 0,
    },
  },
  ...defaultPath,
  transition: { top: { duration: 1 } },
}

export const tiltInBottom = {
  variants: {
    show: {
      y: 0,
      opacity: 1,
    },
    hide: {
      y: 50,
      opacity: 0,
    },
    exit: {
      opacity: 0,
      x: "-100vw",
    },
  },
  ...advancedPath,
}
