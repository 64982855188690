import { ReactComponent as BuildingGrade } from "assets/icons/advancedFilters/buildinggrade.svg"
import { ReactComponent as ListerStatus } from "assets/icons/advancedFilters/listerstatus.svg"
import { ReactComponent as ParcelSqft } from "assets/icons/advancedFilters/parcelsqft.svg"
import { ReactComponent as Buildingsqft } from "assets/icons/advancedFilters/buildingSqft.svg"
import { ReactComponent as PropertyType } from "assets/icons/advancedFilters/propertytype.svg"
import { ReactComponent as Yearbuilt } from "assets/icons/advancedFilters/yearbuilt.svg"
import { ReactComponent as Sorting } from "assets/icons/advancedFilters/sorting.svg"
import { ReactComponent as Parking } from "assets/icons/advancedFilters/parking.svg"
import { ReactComponent as Price } from "assets/icons/advancedFilters/price.svg"
import ListerStatusFilter from "../Filters/ListerStatusFilter/ListerStatusFilter"
import ParcelSqftFilter from "../Filters/ParcelSqftFilter/ParcelSqftFilter"
import AccessFilter from "../Filters/AccessFilter/AccessFilter"
import YearBuiltFilter from "../Filters/YearBuiltFilter/YearBuiltFilter"
import ParkingFilter from "../Filters/ParkingFIilter/ParkingFilter"
import SortingFilter from "../Filters/SortingFilter/SortingFilter"
import CardFilter from "components/Filters/CardFilter/CardFilter"
import SliderFilter from "components/Filters/SliderFilter/SliderFilter"
import SqftFilter from "components/Filters/SqftFilter/SqftFilter"

const config = [
  {
    text: "Sorting",
    icon: Sorting,
    filterKey: "sortingFilter",
    component: SortingFilter,
  },
  {
    text: "Property Type",
    icon: PropertyType,
    filterKey: "property_type",
    component: CardFilter,
  },
  {
    text: "Price",
    icon: Price,
    filterKey: "priceFilter",
    component: SliderFilter,
  },
  {
    text: "Building SQFT",
    icon: Buildingsqft,
    filterKey: "sqftFilter",
    component: SqftFilter,
  },
  {
    text: "Parcel SQFT",
    icon: ParcelSqft,
    filterKey: "parcelSqftFilter",
    component: ParcelSqftFilter,
  },
  {
    text: "Year Built",
    icon: Yearbuilt,
    filterKey: "yearBuiltFilter",
    component: YearBuiltFilter,
  },
  {
    text: "Parking",
    icon: Parking,
    filterKey: "parking",
    component: ParkingFilter,
  },
  {
    text: "Lister Status",
    icon: ListerStatus,
    filterKey: "lister_status",
    component: ListerStatusFilter,
  },
  {
    text: "Access",
    icon: BuildingGrade,
    filterKey: "access",
    component: AccessFilter,
  },
]

export default config
