import { IListingFormData } from "types/addListing.types"
import { IApiParcelByUuid } from "types/api.types"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"
import axios from "axios"

export const updateParcel = async (uuid: string | number, data: IListingFormData) => {
  const headers = getHeaders(false)
  return (await axios.patch(`${ENDPOINTS.GET_USER_LISTINGS_PAGINATION}${uuid}/`, data, headers)) as IApiParcelByUuid
}
