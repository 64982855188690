import { IFilterData, InitialFilterData, TFilterObject } from "types/filter.types"
import postSearchCriteria from "api/searchCriteria/postSearchCriteria"
import { createContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { debounce, isEmpty } from "lodash"
import UseQuery from "hooks/UseQuery"
import { UseError } from "hooks"
import qs from "qs"

export interface IFiltersContext {
  saveSearchCriteria: () => Promise<void>
  queryApplyProperties: (updatedValue: TFilterObject) => void
  queryProperties: (updatedValue: TFilterObject) => void
  applyQuery: () => void
  resetQuery: () => void
}

export const FiltersContext = createContext({} as IFiltersContext)

export const FiltersProvider = (props: { children: JSX.Element }) => {
  const { notify } = UseError()
  const navigate = useNavigate()
  const query = UseQuery()
  const [filterData, setFilterData] = useState<TFilterObject>({})
  const filterDataRef = useRef<TFilterObject>()
  filterDataRef.current = filterData

  const removeInitialValue = (updatedValue: TFilterObject) => {
    const filters = filterDataRef.current
    const newFilterData = { ...filters, ...updatedValue } as IFilterData
    const newFilterDataEntries = Object.entries(newFilterData)
    const updatedFilterDataEntries = newFilterDataEntries.filter(
      ([key, value]) => InitialFilterData[key as keyof IFilterData] !== value
    )
    return Object.fromEntries(updatedFilterDataEntries) as IFilterData
  }

  const updateFilterData = (updatedValue: TFilterObject) => {
    const updatedFilterData = removeInitialValue(updatedValue)
    setFilterData(updatedFilterData)
  }

  const updateQuery = (filters: Partial<IFilterData>) => {
    const seachQuery = qs.stringify(filters, { arrayFormat: "repeat", encode: false })
    navigate({
      pathname: "/",
      search: seachQuery,
    })
  }

  const updateApplyQuery = (updatedValue: TFilterObject) => {
    const updatedFilterData = removeInitialValue(updatedValue)
    setFilterData(updatedFilterData)
    updateQuery(updatedFilterData)
  }

  const applyQuery = () => {
    updateQuery(filterData)
  }

  const resetQuery = () => {
    setFilterData({})
    navigate("/")
  }

  const saveSearchCriteria = async () => {
    try {
      await postSearchCriteria({ ...filterData, location_query: "Pittsburgh" })
    } catch (err) {
      notify("cannot save search criteria")
    }
  }

  const queryProperties = debounce(updateFilterData, 500)
  const queryApplyProperties = debounce(updateApplyQuery, 500)

  useEffect(() => {
    const isEmty = isEmpty(query)
    if (!isEmty) setFilterData({ ...filterData, ...query })
  }, [])

  const value: IFiltersContext = {
    queryApplyProperties,
    saveSearchCriteria,
    queryProperties,
    applyQuery,
    resetQuery,
  }

  return <FiltersContext.Provider value={value}>{props.children}</FiltersContext.Provider>
}
