import {
  TInputChangeEvent,
  TInputRef,
  TInputTextareaKeydownEvent,
  TInputWheelEvent,
  TTextAreaChangeEvent,
  TTextAreaRef,
} from "types/components.types"
import { ReactComponent as Calendar } from "assets/icons/addListing/calendar.svg"
import { formatWithCommas } from "utils/FormatWithCommas"
import styles from "./AddListingInput.module.scss"
import { addDashes } from "utils/addDashesPhone"
import React from "react"
import clsx from "clsx"

type Props = {
  title: string
  placeholder: string
  keyToValue: string
  changeCallback: (value: Record<string, string | number | object>) => void
  keyDownCallback?: (e: TInputTextareaKeydownEvent) => void
  validated?: boolean
  required?: boolean
  textarea?: boolean
  maxLength?: number | undefined
  type?: "text" | "date" | "number" | "phone"
  defaultValue?: string
}

const nonNumericalRegex = /[^\d-]+/g

const AddListingInput: React.FC<Props> = ({
  title,
  placeholder,
  textarea = false,
  keyToValue,
  required,
  changeCallback,
  keyDownCallback,
  validated = true,
  type = "text",
  maxLength = 10000,
  defaultValue,
}) => {
  const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

  const getCallbackValue = (value: string | number) => {
    const stringified = value.toString()
    switch (type) {
      case "phone": {
        return stringified.replaceAll("-", "")
      }
      case "number": {
        return Number(stringified.replaceAll(",", ""))
      }
      default: {
        return value
      }
    }
  }

  const setDefaultValue = () => {
    const input = inputRef.current as HTMLInputElement
    if (!defaultValue || !input) return

    switch (type) {
      case "phone": {
        input.value = addDashes(defaultValue)
        return
      }
      case "number": {
        input.value = formatWithCommas(defaultValue)
        return
      }
      default: {
        input.value = defaultValue
        return
      }
    }
  }

  const setDateInputDefaultValue = () => {
    const input = inputRef.current as HTMLInputElement
    if (!input) return
    if (input.type !== "date") return

    input.valueAsDate = new Date()
    input.min = new Date().toISOString().split("T")[0]
    changeCallback({ [keyToValue]: input.value })
  }

  const onInput = (e: TInputChangeEvent) => {
    const { value } = e.target
    switch (type) {
      case "phone": {
        const formattedValue = e.target.value.replaceAll("-", "")
        e.target.value = addDashes(formattedValue).replace(nonNumericalRegex, "")
        return
      }
      case "number": {
        e.target.value = formatWithCommas(value.replace(nonNumericalRegex, ""))
        return
      }
    }
  }

  const onChange = (e: TInputChangeEvent | TTextAreaChangeEvent) => {
    const { value } = e.target
    if (value.length > maxLength) {
      return (e.target.value = value.slice(0, maxLength))
    }
    if (changeCallback) changeCallback({ [keyToValue]: getCallbackValue(value) })
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "number") {
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(e.key) || e.key === "e") return e.preventDefault()
    }
    if (keyDownCallback) keyDownCallback(e)
  }

  const onWheel = (e: TInputWheelEvent) => e.target.blur()

  const renderTextArea = () => (
    <textarea
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={keyDownCallback}
      ref={inputRef as TTextAreaRef}
    />
  )

  const renderDefaultInput = () => (
    <input
      placeholder={placeholder}
      type="text"
      onChange={onChange}
      onInput={onInput}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
      onWheel={onWheel}
      ref={inputRef as TInputRef}
    />
  )

  const renderDateInput = () => (
    <div className={styles.dateInputContainer}>
      <input type="date" onChange={onChange} ref={inputRef as TInputRef} />
      <span className={styles.openButton}>
        <button type="button">
          <Calendar />
        </button>
      </span>
    </div>
  )

  const renderInput = () => (type === "date" ? renderDateInput() : renderDefaultInput())

  React.useEffect(() => {
    setDateInputDefaultValue()
    setDefaultValue()
  }, [])

  return (
    <div
      className={clsx({
        [styles.propertyInfoItem]: true,
        [styles.propertyInfoItem_error]: !validated,
      })}
    >
      <div className={styles.PropertyInfoItemTitleWrapper}>
        <h2>{title}</h2>
        {required ? <h3>*</h3> : null}
      </div>
      {textarea ? renderTextArea() : renderInput()}
    </div>
  )
}

export default AddListingInput
