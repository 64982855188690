import { TAddListingFormDataUpdateCallback, TPropertyInformation, IListingFormData } from "types/addListing.types"
import SortingFilter from "components/Filters/SortingFilter/SortingFilter"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import AddListingInput from "components/AddListingInput/AddListingInput"
import AccessFilter from "components/Filters/AccessFilter/AccessFilter"
import { inputsConfig, sortingFilter } from "./AddListingStep2.config"
import styles from "./AddListingStep2.module.scss"
import { motion } from "framer-motion"
import React from "react"
import clsx from "clsx"
import LinkButton from "components/LinkButton/LinkButton"
import { fadeIn } from "constants/animationContants"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  listingFormData: IListingFormData
}

const requiredKeys = ["building_sqft", "parcel_sqft", "price", "lister_status", "parking"]

type TErrorsList = Partial<Record<keyof TPropertyInformation, boolean>>

const AddListingStep2: React.FC<Props> = ({ formDataUpdateCallback, handleNext, handleBack, listingFormData }) => {
  const {
    listing_type,
    available_from,
    building_sqft,
    parcel_sqft,
    price,
    description,
    lister_status,
    parking,
    parking_offstreet_spots,
    access,
    year_built,
    zone_code,
  } = listingFormData
  const initialInformation = {
    available_from,
    building_sqft,
    parcel_sqft,
    price,
    description,
    lister_status,
    parking,
    parking_offstreet_spots,
    access,
    year_built,
    zone_code,
  } as TPropertyInformation
  const [propertyInformation, setPropertyInformation] = React.useState<TPropertyInformation>(initialInformation)
  const [errorList, setErrorsList] = React.useState<TErrorsList>({})

  const updatePropertyInformation = (value: Partial<TPropertyInformation>) =>
    setPropertyInformation((prev) => ({ ...prev, ...value }))

  const computeErrors = () => {
    const propertyInformationKeys = Object.keys(propertyInformation) as Array<keyof TPropertyInformation>

    return propertyInformationKeys.reduce((prev, curr) => {
      const isRequired = requiredKeys.find((key) => key === curr)
      const isErrored = !propertyInformation[curr]
      return isRequired && isErrored ? { ...prev, [curr]: false } : prev
    }, {})
  }

  const validate = () => {
    const errors = computeErrors()
    setErrorsList(errors)
    return Object.keys(errors).length ? false : true
  }

  const handleNextButton = () => {
    const validated = validate()
    if (validated) {
      formDataUpdateCallback(propertyInformation)
      handleNext()
    }
  }

  React.useEffect(() => {
    formDataUpdateCallback(propertyInformation)
  }, [propertyInformation])

  return (
    <motion.div {...fadeIn}>
      <AddListingTitle heading="Property Information" subheading="(Fill in required fields)" />
      <div className={styles.propertyInfoWrapper}>
        {inputsConfig.map((i) => (
          <AddListingInput
            placeholder={i.placeholder}
            title={i.keyToValue === "price" && listing_type === "FOR_SALE" ? "Sale Price" : i.title}
            changeCallback={updatePropertyInformation}
            keyToValue={i.keyToValue}
            required={i.required}
            type={i.type}
            defaultValue={propertyInformation[i.keyToValue] as string}
            maxLength={i.maxLength}
            textarea={i.textarea}
            validated={i.required ? errorList[i.keyToValue] : undefined}
            key={`${i.id}step2Input`}
          />
        ))}

        {sortingFilter.map((i) => (
          <div className={styles.propertyInfoItem} key={`${i.id}step2SortingFilter`}>
            <div
              className={clsx({
                [styles.PropertyInfoItemTitleWrapper]: true,
                [styles.PropertyInfoItemTitleWrapper_error]: errorList[i.keyToValue] === false,
              })}
            >
              <h2>{i.title}</h2>
              {i.required ? <span>*</span> : null}
            </div>
            <SortingFilter
              mapConfig={i.config}
              defaultActiveValue={propertyInformation[i.keyToValue] as string}
              layoutKey={i.layoutKey}
              activeAmount={i.activeAmount as "single"}
              filterLayout={i.filterLayout}
              changeCallback={updatePropertyInformation}
              keyToValue={i.keyToValue}
            />
          </div>
        ))}

        {parking === "OFFSTREET" ? (
          <AddListingInput
            placeholder="e.g. 3"
            title="If Off-Street Parking indicate number of spots"
            changeCallback={updatePropertyInformation}
            keyToValue="parking_offstreet_spots"
            type="number"
            maxLength={5}
            defaultValue={propertyInformation["parking_offstreet_spots"]}
          />
        ) : null}

        <div className={styles.propertyInfoItem}>
          <div className={styles.PropertyInfoItemTitleWrapper}>
            <h2>Access (select one or more)</h2>
          </div>
          <AccessFilter changeCallback={updatePropertyInformation} keyToValue="access" defaultActiveValue={access} />
        </div>
      </div>
      <div className={styles.navigationButtons}>
        <LinkButton onClick={handleBack} text="Back" secondary />
        <LinkButton onClick={handleNextButton} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingStep2
