import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import { IApiParcelByUuid } from "types/api.types"
import getHeaders from "utils/GetHeaders"

export const getSimmilarProperties = async (uuid: string | number): Promise<AxiosResponse<IApiParcelByUuid[]>> => {
  const endpoint = `${ENDPOINTS.GET_USER_LISTINGS_PAGINATION}${uuid}/similar-properties/`
  const headers = getHeaders(false)
  return await axios.get(endpoint, headers)
}

export default getSimmilarProperties
