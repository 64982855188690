import { ReactComponent as Searches } from "assets/icons/searches.svg"
import { ReactComponent as MyProps } from "assets/icons/myProps.svg"
import { ReactComponent as AddListing } from "assets/icons/addListing.svg"
import { ReactComponent as WatchList } from "assets/icons/watchList.svg"
import { ReactComponent as marketIntel } from "assets/icons/marketIntel.svg"
import { ReactComponent as Plus } from "assets/icons/plus.svg"

const config = [
  {
    id: 0,
    text: "Searches",
    icon: Searches,
    modifier: false,
    path: "/saved-searches",
    active: false,
  },
  {
    id: 1,
    text: "My Props",
    icon: MyProps,
    modifier: false,
    path: "/my-properties",
    active: false,
  },
  //TODO WE NEED THAT LATER
  // {
  //   id: 2,
  //   text: "Add Listing",
  //   icon: AddListing,
  //   path: "/add-listing",
  //   modifier: true,
  //   active: false,
  // },
  {
    id: 2,
    text: "Watch List",
    icon: WatchList,
    modifier: false,
    path: "/watch-list",
    active: false,
  },
  //TODO WE NEED THAT LATER
  // {
  //   id: 3,
  //   text: "Market Intel",
  //   icon: marketIntel,
  //   modifier: false,
  //   path: "/market-intel",
  //   active: false,
  // },
  {
    id: 3,
    text: "Add Listing",
    icon: Plus,
    modifier: true,
    path: "/add-listing",
    active: false,
  },
]

export default config
