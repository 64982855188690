import { ISearchCriteria, TFilterObject, TPriceFilter, TSliderRange } from "types/filter.types"
import UseSliderFactory from "hooks/UseSliderFactory"
import { motion } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import "./SliderFilter.scss"
import React from "react"

//client said remove it "for now" prolly later will be needed as well with all commented code below
// const radioConfig: { id: TPriceFilter; label: string }[] = [
//   {
//     id: "total",
//     label: "Total",
//   },
//   {
//     id: "per_sqft",
//     label: "$ / SQFT (Building)",
//   },
// ]

type Props = {
  updateCallback: (updatedValue: TFilterObject) => void
  dataObject?: ISearchCriteria
}

const defaultMax = 20000000

const SliderFilter: React.FC<Props> = ({ updateCallback, dataObject }) => {
  const { price_min = 0, price_max = defaultMax, price_type = "total" } = dataObject || UseQuery()
  const radioValue = React.useRef(price_type)

  const updateFilters = (updatedValue: TFilterObject) => updateCallback(updatedValue)

  // const radioChangeCallback = (type: TPriceFilter) => {
  //   radioValue.current = type
  //   if (price_min !== 0 || price_max !== defaultMax) {
  //     updateFilters({ price_type: type })
  //   }
  // }

  const sliderChangeCallback = (range: TSliderRange) => {
    const [min, max] = [range[0], range[1]]
    const isInitial = min === "0" && max === defaultMax.toString()
    updateFilters({ price_min: min, price_max: max, price_type: isInitial ? "" : (radioValue.current as TPriceFilter) })
  }

  const { startRef, endRef, containerRef, inputChangeCallback, reset } = UseSliderFactory({
    min: price_min || 0,
    max: price_max || defaultMax,
    minInitial: 0,
    maxInitial: defaultMax,
    sliderSetCallback: sliderChangeCallback,
    valueModifier: "$",
    numberFormatting: true,
  })

  // const renderRadio = radioConfig.map((i) => (
  //   <div className="radioSetterItem" key={i.id}>
  //     <input
  //       type="radio"
  //       name="setter"
  //       id={i.id}
  //       defaultChecked={price_type === i.id}
  //       onChange={() => radioChangeCallback(i.id)}
  //     />
  //     <label htmlFor={i.id}>{i.label}</label>
  //   </div>
  // ))

  return (
    <div className="filterWrapper">
      <div className="slider-area">
        <div className="slider" ref={containerRef}></div>
      </div>
      <div className="min-max-controls">
        <input
          onChange={inputChangeCallback}
          ref={startRef}
          type="text"
          placeholder="No Min"
          data-id="0"
          id="input-0"
        />
        <span className="divider">-</span>
        <input onChange={inputChangeCallback} ref={endRef} type="text" data-id="1" id="input-1" />
      </div>
      {/* <div className="radioSetters">{renderRadio}</div> */}
      <motion.div className="resetButtonWrapper" onClick={reset}>
        <h3>Reset</h3>
      </motion.div>
    </div>
  )
}

export default SliderFilter
