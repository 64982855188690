import { Dispatch, RefObject, SetStateAction, useEffect, useRef } from "react"

type Props = {
  setter: Dispatch<SetStateAction<boolean>>
  exclusions?: RefObject<HTMLDivElement>[]
}

const UseOutsideCick = ({ setter, exclusions }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null)

  const getExclusion = (target: EventTarget | null) => {
    if (!target || !exclusions) return false
    const containsList = exclusions?.map((i) => i.current?.contains(target as Node))
    return containsList?.find((i) => i === true)
  }

  const detectOutsideClick = (event: MouseEvent | TouchEvent) => {
    const component = componentRef.current
    const { target } = event
    const isExclusion = getExclusion(target)
    if (!target || !component || component.contains(target as Node) || isExclusion) return
    setter(false)
  }

  const onMount = () => {
    document.addEventListener("mousedown", detectOutsideClick)
    document.addEventListener("touchstart", detectOutsideClick)
  }

  const onUnMount = () => {
    document.removeEventListener("mousedown", detectOutsideClick)
    document.removeEventListener("touchstart", detectOutsideClick)
  }

  useEffect(() => {
    onMount()
    return () => {
      onUnMount()
    }
  }, [])

  return componentRef
}

export default UseOutsideCick
