import { ReactComponent as BuildingGrade } from "assets/icons/advancedFilters/buildinggrade.svg"
import { ReactComponent as Mountains } from "assets/icons/propertyPage/mountains.svg"
import { ReactComponent as ListerStatus } from "assets/icons/advancedFilters/listerstatus.svg"
import { ReactComponent as BuildingSqft } from "assets/icons/advancedFilters/buildingSqft.svg"
import { ReactComponent as Yearbuilt } from "assets/icons/advancedFilters/yearbuilt.svg"
import { ReactComponent as Parking } from "assets/icons/advancedFilters/parking.svg"
import { ReactComponent as PropertyType } from "assets/icons/propertyType.svg"
import { ReactComponent as Price } from "assets/icons/price.svg"
import ListerStatusFilter from "../ListerStatusFilter/ListerStatusFilter"
import ParcelSqftFilter from "../ParcelSqftFilter/ParcelSqftFilter"
import AccessFilter from "../../Filters/AccessFilter/AccessFilter"
import YearBuiltFilter from "../YearBuiltFilter/YearBuiltFilter"
import ParkingFilter from "../ParkingFIilter/ParkingFilter"
import SliderFilter from "../SliderFilter/SliderFilter"
import SqftFilter from "../SqftFilter/SqftFilter"
import CardFilter from "../CardFilter/CardFilter"

const config = [
  {
    text: "Property Type",
    icon: PropertyType,
    filterKey: "price",
    component: CardFilter,
  },
  {
    text: "Price",
    icon: Price,
    filterKey: "property_type",
    component: SliderFilter,
  },
  {
    text: "Building SQFT",
    icon: BuildingSqft,
    filterKey: "sqft",
    component: SqftFilter,
  },
  {
    text: "Parcel SQFT",
    icon: Mountains,
    filterKey: "sqft",
    component: ParcelSqftFilter,
  },
  //probably we need that code later , because client asked to remove it "for now"
  // {
  //   text: "Year Built",
  //   icon: Yearbuilt,
  //   filterKey: "yearBuiltFilter",
  //   component: YearBuiltFilter,
  // },
  {
    text: "Parking",
    icon: Parking,
    filterKey: "parking",
    component: ParkingFilter,
  },
  {
    text: "Lister Status",
    icon: ListerStatus,
    filterKey: "listerStatusFilter",
    component: ListerStatusFilter,
  },
  {
    text: "Access",
    icon: BuildingGrade,
    filterKey: "accessFilter",
    component: AccessFilter,
  },
]

export default config
