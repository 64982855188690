import { IListingFormData, TAddListingFormDataUpdateCallback, TlistingType } from "types/addListing.types"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import styles from "./AddListingStep0.module.scss"
import React from "react"
import { clsx } from "clsx"
import { motion } from "framer-motion"
import LinkButton from "components/LinkButton/LinkButton"
import { fadeIn } from "constants/animationContants"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  listingFormData: IListingFormData
}

const AddListingStep0: React.FC<Props> = ({ formDataUpdateCallback, handleNext, listingFormData }) => {
  const { listing_type } = listingFormData
  const [listingType, setListingType] = React.useState<TlistingType>(listing_type)

  const handleNextButton = () => {
    if (listingType) {
      formDataUpdateCallback({ listing_type: listingType })
      handleNext()
    }
  }

  React.useEffect(() => {
    if (listing_type) setListingType(listing_type)
  }, [listing_type])

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <AddListingTitle heading="Select Listing Type" subheading="(Check One)" />
      <div className={styles.buttonGroup}>
        <motion.div
          whileTap={{ scale: 0.96 }}
          className={clsx({
            [styles.leaseButton]: true,
            [styles.leaseButton_active]: listingType === "FOR_LEASE",
          })}
          onClick={() => setListingType("FOR_LEASE")}
        >
          <h2> Lease </h2>
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.96 }}
          className={clsx({
            [styles.SaleButton]: true,
            [styles.SaleButton_active]: listingType === "FOR_SALE",
          })}
          onClick={() => setListingType("FOR_SALE")}
        >
          <h2> Sale </h2>
        </motion.div>
      </div>
      <div className={styles.navigationButtons}>
        <LinkButton text="Back" disabled />
        <LinkButton onClick={handleNextButton} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingStep0
