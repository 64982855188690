import { ISearchCriteria } from "types/filter.types"
import ENDPOINTS from "constants/api.constants"
import axios, { AxiosResponse } from "axios"
import getHeaders from "utils/GetHeaders"

export const patchSearchCriteria = async (data: ISearchCriteria): Promise<AxiosResponse<ISearchCriteria>> => {
  const { uuid } = data
  const headers = getHeaders(false)
  return await axios.patch(`${ENDPOINTS.SEARCH_CRITERIA}${uuid}/`, data, headers)
}

export default patchSearchCriteria
