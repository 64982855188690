import placeholder from "assets/icons/placeholder.png"
import CardSlide from "components/CardSlide/CardSlide"
import { Swiper, SwiperSlide } from "swiper/react"
import { TParcelPhotos } from "types/api.types"
import { Navigation, Pagination } from "swiper"
import "./CardPreviewSlider.scss"
import "swiper/css/navigation"
import "swiper/css/pagination"
import React from "react"
import "swiper/css"

type Props = {
  parcelPhotosList?: TParcelPhotos[]
  isMap?: boolean
}

const CardPreviewSlider: React.FC<Props> = ({ isMap = undefined, parcelPhotosList }) => {
  const photos = parcelPhotosList?.length ? parcelPhotosList.map((i) => i.url) : [placeholder]

  return (
    <div className={`absoluteContainer ${isMap ? "absoluteContainerAtMap" : null}`}>
      <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        spaceBetween={-1}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {photos.map((i) => (
          <SwiperSlide key={i}>
            <CardSlide src={i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CardPreviewSlider
