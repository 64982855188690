import { parcelsSelector } from "redux/reducers/app.reducer"
import useLocalStorageState from "use-local-storage-state"
import { createContext, useEffect, useRef, useState } from "react"
import { IApiParcel } from "types/api.types"
import { useSelector } from "react-redux"
import UseQuery from "hooks/UseQuery"
import { MapRef } from "react-map-gl"

export interface IParcelContext {
  parcelsList: IApiParcel[] | null
  activeParcel: IApiParcel | null
  viewedParcelsList: string[]
  isOffmarket: boolean
  mapRef: React.MutableRefObject<MapRef | null>
  setViewed: (id: string) => void
  setActiveParcel: React.Dispatch<React.SetStateAction<IApiParcel | null>>
  updateParcels: (id: number, obj: Partial<IApiParcel>) => void
}

export const ParcelContext = createContext({} as IParcelContext)

export const ParcelContextProvider = (props: { children: JSX.Element }) => {
  const { listing_type } = UseQuery()
  const parcels = useSelector(parcelsSelector)

  const [parcelsList, setParcelsList] = useState<IApiParcel[] | null>(parcels)
  const [activeParcel, setActiveParcel] = useState<IApiParcel | null>(null)
  const [viewedParcelsList, setViewedParcelsList] = useLocalStorageState<string[]>("viewedParcelsIdList", {
    defaultValue: [],
  })
  const mapRef = useRef<MapRef | null>(null)

  const isOffmarket = listing_type === "OFF_MARKET" || listing_type === "SOLD"

  const setViewed = (id: string) => setViewedParcelsList([...viewedParcelsList, id])

  const updateParcels = (id: number, obj: Partial<IApiParcel>) => {
    if (parcelsList) setParcelsList((list) => list!.map((i) => (i.id === id ? { ...i, ...obj } : i)))
    if (activeParcel?.id === id) setActiveParcel((parcel) => ({ ...(parcel as IApiParcel), ...obj }))
  }

  useEffect(() => {
    setParcelsList(parcels)
  }, [parcels])

  const value = {
    parcelsList,
    activeParcel,
    viewedParcelsList,
    isOffmarket,
    mapRef,
    setViewed,
    setActiveParcel,
    updateParcels,
  }

  return <ParcelContext.Provider value={value}>{props.children}</ParcelContext.Provider>
}
