import { TlistingType } from "types/addListing.types"

export const formatListingType = (type: TlistingType | null | "N/A") => {
  var convertedType = type || ""
  if (type === "FOR_LEASE" || type === "FOR_SALE") {
    convertedType = convertedType.split("_")[1]
  }
  convertedType = convertedType.replace(/-|_/g, " ").toLowerCase()
  return convertedType[0].toUpperCase() + convertedType.slice(1)
}
