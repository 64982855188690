import styles from "./BigButton.module.scss"
import React from "react"
import { TDivClickEvent } from "types/components.types"
import { motion } from "framer-motion"

type Props = {
  text: string
  clickCallback?: (e: TDivClickEvent) => void
}

const BigButton: React.FC<Props> = ({ text, clickCallback }) => {
  return (
    <motion.div
      className={styles.bigButton}
      onClick={clickCallback}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.95 }}
    >
      <h2>{text}</h2>
    </motion.div>
  )
}

export default BigButton
