import { AppContextProvider } from "context/appContextProvider"
import { injectStyle } from "react-toastify/dist/inject-style"
import { cloneElement, ReactElement, useEffect } from "react"
import { AnimatePresence } from "framer-motion"
import { ToastContainer } from "react-toastify"
import CONSTANTS from "constants/constants"
import RoutesComponent from "./router"
import { gapi } from "gapi-script"
import "./App.css"

function App() {
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {}
  }

  const pathCloned = cloneElement(RoutesComponent({}) as NonNullable<ReactElement>, {
    key: RoutesComponent({})?.props.children.key,
  })

  useEffect(() => {
    const start = () => {
      gapi.auth2.init({
        clientId: CONSTANTS.googleClientId,
        scope: "",
      })
    }
    gapi.load("client:auth2", start)
    injectStyle()
  }, [])

  return (
    <AppContextProvider>
      <AnimatePresence mode="wait">
        <ToastContainer key="toastContainer" />
        {pathCloned}
      </AnimatePresence>
    </AppContextProvider>
  )
}

export default App
