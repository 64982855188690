import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

interface IResetData {
  new_password1: string
  new_password2: string
  uid: string
  token: string
}

export const resetPasswordConfirm = async (data: IResetData) => {
  const headers = getHeaders(false)
  return await axios.post(ENDPOINTS.RESET_PASSWORD_CONFIRM, data, headers)
}

export default resetPasswordConfirm
