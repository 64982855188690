import SignUpStepMain from "./Steps/SignUpStepMain/SignUpStepMain"
import SignUpStep0 from "./Steps/SignUpStep0/SignUpStep0"
import SignUpStep1 from "./Steps/SignUpStep1/SignUpStep1"
import SignUpStep2 from "./Steps/SignUpStep2/SignUpStep2"
import SignUpStep3 from "./Steps/SignUpStep3/SignUpStep3"

const config = [
  { id: 0, component: SignUpStepMain },
  { id: 1, component: SignUpStep0 },
  { id: 2, component: SignUpStep1 },
  //TODO NEED IT WHEN API GONNA BE READY
  // { id: 4, component: SignUpStep2 },
  { id: 3, component: SignUpStep3 },
  { id: 4, component: () => null },
]

export default config
