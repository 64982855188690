import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import { ReactComponent as Circle } from "assets/icons/signup/circle.svg"
import { ReactComponent as Tick } from "assets/icons/signup/tick.svg"
import PopupBackground from "components/PopupBackground/PopupBackground"
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy"
import PasswordInput from "components/PasswordInput/PasswordInput"
import BackButton from "components/BackButton/BackButton"
import BigButton from "components/BigButton/BigButton"
import config from "./SignUpStep1Validation.config"
import { useNavigate } from "react-router-dom"
import styles from "./SignUpStep1.module.scss"
import { ILoginErrors } from "types/api.types"
import React, { ChangeEvent } from "react"
import { motion } from "framer-motion"
import clsx from "clsx"
import { slideInBottom } from "constants/animationContants"

type Props = {
  backButtonCallback: () => void
  buttonClickCallback: () => void
  inputChangeCallback: (type: "email" | "password" | "name" | "surname", value: string) => void
  registerUser: () => Promise<Partial<ILoginErrors> | undefined>
}
interface IValidationItem {
  id: number
  active: boolean
  text: string
}

const SignUpStep1: React.FC<Props> = ({
  backButtonCallback,
  buttonClickCallback,
  inputChangeCallback,
  registerUser,
}) => {
  const navigate = useNavigate()

  const [validationList, setValidationList] = React.useState<IValidationItem[]>(config)

  const [passwordValue, setPasswordValue] = React.useState<string>("")
  const [repeatPasswordValue, setRepeatPasswordValue] = React.useState<string>("")
  const [inputHightlight, setInputHighlight] = React.useState({ repeat: true, password: true })
  const [passwordError, setPasswordError] = React.useState<string | null>(null)

  const validatePasswordCallback = (e: ChangeEvent<HTMLInputElement>): void => {
    const passwordValue = e.target.value

    const isOneCapital = passwordValue.toLowerCase() !== passwordValue && passwordValue.length > 0
    const isOneNumber = /\d/.test(passwordValue) && passwordValue.length > 0
    const isEightCharachers = passwordValue.length >= 8

    setValidationList(
      [isOneCapital, isOneNumber, isEightCharachers].map((i, index) => {
        return { ...validationList[index], active: i }
      })
    )
    setPasswordValue(passwordValue)
    inputChangeCallback("password", passwordValue)
  }

  const validateRepeatPasswordCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setRepeatPasswordValue(e.target.value)
  }

  const validateEverything = async () => {
    const isPasswordValidated = !validationList.find((i) => !i.active)
    const isPasswordRepeated = passwordValue === repeatPasswordValue
    setInputHighlight({ repeat: isPasswordRepeated, password: isPasswordValidated })

    if (isPasswordRepeated && isPasswordValidated) {
      const status = await registerUser()
      const passwordError = status?.["non_field_errors"]
      if (!passwordError) {
        setPasswordError(null)
        buttonClickCallback()
      } else {
        setInputHighlight({ repeat: false, password: false })
        setPasswordError(passwordError[0])
      }
    }
  }

  const renderError = () => {
    return passwordError ? <div className={styles.error}>{passwordError}</div> : null
  }

  const handleLoginHereButton = () => navigate("/login")

  const renderValidationItem = (i: IValidationItem) => (
    <div
      className={clsx({
        [styles.validationItem]: true,
        [styles.validationItem_active]: i.active,
      })}
      key={`validationItem${i.id}`}
    >
      {i.active ? <Tick /> : <Circle />}
      <h2>{i.text}</h2>
    </div>
  )

  return (
    <motion.div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.backButton}>
          <BackButton clickCallback={backButtonCallback} />
        </div>
        <div className={styles.title}>
          <h2>Set up password</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>

        <div className={styles.inputsList}>
          <PasswordInput changeCallback={validatePasswordCallback} validated={inputHightlight["password"]} />
          <div className={styles.passwordValidation}>{validationList.map((i) => renderValidationItem(i))}</div>
          <PasswordInput
            text="Repeat Password"
            changeCallback={validateRepeatPasswordCallback}
            validated={inputHightlight["repeat"]}
          />
          {renderError()}
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Sign Up" clickCallback={validateEverything} />
        </div>
        <PrivacyPolicy />
        <div className={styles.haveAnAccount}>
          <h2>
            Already have an account? <span onClick={handleLoginHereButton}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default SignUpStep1
