import styles from "./PropertyPageParcelInformation.module.scss"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import { formatListingType } from "utils/formatListingType"
import { IApiParcelByUuid } from "types/api.types"
import React from "react"
import clsx from "clsx"
import {
  IParcelInformationIconKey,
  IParcelInformationIcons,
  parcelInformationConfig,
} from "./PropertyPageParcelInformation.config"
import { TlistingType } from "types/addListing.types"

type Props = {
  parcel: IApiParcelByUuid
}

const PropertyPageParcelInformation: React.FC<Props> = ({ parcel }) => {
  const {
    building_sqft,
    parcel_sqft,
    year_built,
    formatted_full_address,
    property_type,
    sqft_price,
    listing_type,
    most_recent_sale,
    most_recent_sales_price,
    most_recent_sales_date,
    zone_code,
  } = parcel

  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString("en-US")

  const renderType = () => {
    const type = formatListingType(listing_type)
    return listing_type !== "SOLD" ? (
      <div
        className={clsx({
          [styles.typeBase]: true,
          [styles.lease]: listing_type === "FOR_LEASE",
          [styles.sale]: listing_type === "FOR_SALE",
        })}
      >
        {type}
      </div>
    ) : null
  }

  const renderItem = (iconKey: IParcelInformationIconKey, value: string | number, listingType: string = "") => {
    const data = parcelInformationConfig.find((i) => i.key === iconKey) as IParcelInformationIcons
    const { text, Icon, align } = data
    return (
      <div
        className={clsx({
          [styles.infoItem]: true,
          [styles.infoItem_alignStart]: align === "start",
        })}
      >
        <div className={styles.svgWrapper}>
          <Icon />
        </div>
        <div className={styles.infoWrapper}>
          <h3>
            {text} {listingType}
          </h3>
          <h2>{value}</h2>
        </div>
      </div>
    )
  }

  const renderLastSale = () => {
    const price = most_recent_sales_price || most_recent_sale?.value
    const formattedPrice = price ? `$${formatPriceNoDecimal(Number(price))}` : "N/A"
    const dateValue = most_recent_sales_date || most_recent_sale?.date
    const formattedDate = dateValue ? formatDate(dateValue) : "N/A"
    const isShowDate = formattedPrice !== "N/A"
    const date = isShowDate ? `(${formattedDate})` : ""
    return renderItem("hammer", formattedPrice + " " + date)
  }

  const renderBuildingSqft = () => {
    const value = building_sqft ? `${formatPriceNoDecimal(building_sqft)} SQFT` : "N/A"
    return renderItem("ruller", value)
  }

  const renderParcelSqft = () => {
    const value = parcel_sqft ? `${formatPriceNoDecimal(parcel_sqft)} SQFT` : "N/A"
    return renderItem("mountains", value)
  }

  const renderZoneCode = () => {
    const code = zone_code ? zone_code : "N/A"
    return renderItem("zonecode", code)
  }

  const renderYearBuit = () => {
    const year = year_built ? year_built : "N/A"
    return renderItem("calendar", year)
  }

  const renderPropertyType = () => {
    const value = property_type ? property_type : "N/A"
    return renderItem("building", value)
  }

  const renderSqftPrice = () => {
    const value = sqft_price ? `$${formatPriceNoDecimal(sqft_price)} / SQFT` : "N/A"
    const listingType = {
      FOR_LEASE: "(lease)",
      FOR_SALE: "(sale)",
      SOLD: "(sold)",
    }[listing_type as Exclude<TlistingType, "OFF-MARKET" | undefined>]
    return renderItem("pricesqft", value, listingType)
  }

  const renderFullAdress = () => {
    const value = formatted_full_address ? formatted_full_address : "N/A"
    return renderItem("navigation", value)
  }

  return (
    <div className={styles.container}>
      {renderFullAdress()}
      {renderType()}
      {renderPropertyType()}
      {renderBuildingSqft()}
      {renderParcelSqft()}
      {renderLastSale()}
      {renderSqftPrice()}
      {renderYearBuit()}
      {renderZoneCode()}
    </div>
  )
}

export default PropertyPageParcelInformation
