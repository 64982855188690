import { toast, Slide } from "react-toastify"

export const UseError = () => {
  const notify = (err: string) => {
    toast.error(err, {
      position: toast.POSITION.TOP_CENTER,
      pauseOnFocusLoss: false,
      autoClose: 3500,
      role: "alert",
      theme: "colored",
      transition: Slide,
    })
  }

  const notifySuccess = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      pauseOnFocusLoss: false,
      autoClose: 3500,
      role: "alert",
      theme: "colored",
      transition: Slide,
    })
  }

  return { notify, notifySuccess }
}

export default UseError
