import { BaseSyntheticEvent, FC, useContext } from "react"
import { ParcelContext } from "context/parcelContext"
import Card from "../Card/Card"
import "./MapMobileSlider.scss"

type Props = {
  handleCardClick: (e: BaseSyntheticEvent) => void
}

const MapMobileSlider: FC<Props> = ({ handleCardClick }) => {
  const { activeParcel } = useContext(ParcelContext)

  return (
    <div className="MapMobileSliderContainer">
      {activeParcel ? <Card isMap parcel={activeParcel} handleCardClick={handleCardClick} /> : null}
    </div>
  )
}

export default MapMobileSlider
