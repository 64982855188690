import { ReactComponent as UserCircle } from "assets/icons/signup/userCircle.svg"
import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import BigButton from "components/BigButton/BigButton"
import styles from "./SignUpStep3.module.scss"
import { motion } from "framer-motion"
import React from "react"
import { useNavigate } from "react-router-dom"
import { fadeIn } from "constants/animationContants"

type Props = {}

const SignUpStep3: React.FC<Props> = () => {
  const navigate = useNavigate()

  const next = () => {
    navigate("/")
  }

  const redirectAccount = () => {
    navigate("/me")
  }

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <div className={styles.logo}>
        <BigLogo />
      </div>
      <div className={styles.title}>
        <h2>Welcome!</h2>
        <h2>Your account has been created.</h2>
      </div>
      <div className={styles.emailCircleWrapper}>
        <UserCircle />
      </div>
      <div className={styles.titleSmall}>
        <h2>Now you can</h2>
        <h2>buy, sell, lease, and monitor</h2>
        <h2>CRE — for free!</h2>
      </div>
      <div className={styles.bigButtonContainer}>
        <BigButton text="Next" clickCallback={next} />
      </div>
      <div className={styles.haveAnAccount}>
        <h2>
          It's time to personalize it! <span onClick={redirectAccount}>Go to your account</span>
        </h2>
      </div>
    </motion.div>
  )
}

export default SignUpStep3
