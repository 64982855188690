import PropertyPageMediaAndPressReports from "components/PropertyPageMediaAndPressReports/PropertyPageMediaAndPressReports"
import PropertyPageSimmilarProperties from "components/PropertyPageSimmilarProperties/PropertyPageSimmilarProperties"
import PropertyPageParcelInformation from "components/PropertyPageParcelInformation/PropertyPageParcelInformation"
import PropertyPageHistoricalData from "components/PropertyPageHIstoricalData/PropertyPageHistoricalData"
import PropertyPageStickyFooter from "components/PropertyPageStickyFooter/PropertyPageStickyFooter"
import PropertyPageDescription from "components/PropertyPageDescription/PropertyPageDescription"
import { getParcelByUuidOffMarket } from "api/parcels/OffMarket/getParcelByUuidOffmarket"
import PropertyPageTaxData from "components/PropertyPageTaxData/PropertyPageTaxData"
import PropertyPageHeader from "components/PropertyPageHeader/PropertyPageHeader"
import PropertyPageSlider from "components/PropertyPageSlider/PropertyPageSlider"
import PropertyPageMap from "components/PropertyPageMap/PropertyPageMap"
import getUserPropertyById from "api/user/getUserPropertyById"
import ImageViewer from "components/ImageViewer/ImageViewer"
import { useLocation, useParams } from "react-router-dom"
import { TDivClickEvent } from "types/components.types"
import { IApiParcelByUuid } from "types/api.types"
import styles from "./PropertyPage.module.scss"
import { getParcelByUuid } from "api/parcels"
import { motion } from "framer-motion"
import React from "react"

type Props = {
  listed?: boolean
}

const PropertyPage: React.FC<Props> = ({ listed = false }) => {
  const params = useParams()
  const { uuid, hidden } = params
  const { pathname } = useLocation()
  const [propertyData, setPropertyData] = React.useState<IApiParcelByUuid | null>(null)
  const [activeImage, setActiveImage] = React.useState(0)
  const [imageViewerOpened, setImageViewerOpened] = React.useState(false)
  const isUserListed = pathname.split("/").find((i) => i === "listed")
  const isHidden = hidden === "true" ? true : false

  const openImageViewer = () => setImageViewerOpened(true)

  const handleActiveImage = (e: TDivClickEvent) => {
    const id = e.target.getAttribute("data-id")
    if (id) setActiveImage(Number(id))
  }

  const getParcel = async () => {
    try {
      if (uuid) {
        const listedRequest = isHidden ? getUserPropertyById : getParcelByUuid
        const data = isUserListed ? await listedRequest(uuid) : await getParcelByUuidOffMarket(uuid)
        setPropertyData(data)
      }
    } catch (err) {
      console.error("error while fetching parcel", err)
    }
  }

  const next = (e: TDivClickEvent) => {
    e.stopPropagation()
    if (activeImage === propertyData!.photos.length - 1) return setActiveImage(0)
    setActiveImage((prev) => prev + 1)
  }

  const back = (e: TDivClickEvent) => {
    e.stopPropagation()
    if (activeImage === 0) return setActiveImage(propertyData!.photos.length - 1)
    setActiveImage((prev) => prev - 1)
  }

  const onBookmark = (id: number, bookmarked: boolean) => {
    setPropertyData((i) => ({ ...i, bookmarked } as IApiParcelByUuid))
  }

  React.useEffect(() => {
    setPropertyData(null)
    getParcel()
  }, [])

  return (
    <motion.div
      className={styles.propertyPageContainer}
      initial={{ left: "100vw" }}
      animate={{ left: "0" }}
      exit={{ left: "100vw" }}
      transition={{ opacity: { duration: 0.4 } }}
    >
      {propertyData ? (
        <React.Fragment>
          <ImageViewer
            photos={propertyData.photos}
            setOpened={setImageViewerOpened}
            opened={imageViewerOpened}
            activeImage={activeImage}
            next={next}
            back={back}
          />
          <PropertyPageHeader
            propertyData={propertyData}
            openImageViewer={openImageViewer}
            onBookmark={onBookmark}
            activeImage={activeImage}
            next={next}
            back={back}
          />
          <PropertyPageSlider parcel={propertyData} listed={listed} imageClickCallback={handleActiveImage} />
          <PropertyPageParcelInformation parcel={propertyData} />
          <PropertyPageDescription parcel={propertyData} />
          <PropertyPageMap property={propertyData} />
          <PropertyPageHistoricalData historicalData={propertyData.sales_data} />
          <PropertyPageTaxData parcel={propertyData} />
          <PropertyPageMediaAndPressReports property={propertyData} />
          {listed ? <PropertyPageSimmilarProperties parcel={propertyData} /> : null}
          {listed ? <PropertyPageStickyFooter parcel={propertyData} /> : null}
        </React.Fragment>
      ) : null}
    </motion.div>
  )
}

export default PropertyPage
