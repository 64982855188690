import AddListingStep0 from "../AddListing/AddListingSteps/AddListingStep0/AddListingStep0"
import AddListingAddLocation from "../AddListing/AddListingSteps/AddListingAddLocation/AddListingAddLocation"
import AddListingStep1 from "../AddListing/AddListingSteps/AddListingStep1/AddListingStep1"
import AddListingStep2 from "../AddListing/AddListingSteps/AddListingStep2/AddListingStep2"
import AddListingStep3 from "../AddListing/AddListingSteps/AddListingStep3/AddListingStep3"
import AddListingStep4 from "../AddListing/AddListingSteps/AddListingStep4/AddListingStep4"
import UpdateListingSuccessfull from "components/UpdateListingSuccesfull/UpdateListingSuccessfull"

const config = [
  { id: 0, component: AddListingStep0 },
  { id: 1, component: AddListingAddLocation },
  { id: 2, component: AddListingStep1 },
  { id: 3, component: AddListingStep2 },
  { id: 4, component: AddListingStep3 },
  { id: 5, component: AddListingStep4 },
  { id: 6, component: UpdateListingSuccessfull },
]

export default config
