import PopupBackground from "components/PopupBackground/PopupBackground"
import MediumButton from "components/MediumButton/MediumButton"
import CrossButton from "components/CrossButton/CrossButton"
import styles from "./ConfirmationLayout.module.scss"
import { scaleIn } from "constants/animationContants"
import { IButtonsConfig } from "types/button.types"
import { motion } from "framer-motion"
import { FC } from "react"

type Props = {
  children: JSX.Element
  cancelCallback: () => void
  confirmCallback?: () => Promise<void>
  showButtons?: boolean
  config?: IButtonsConfig[]
}

const ConfirmationLayout: FC<Props> = ({ children, showButtons = true, config, cancelCallback, confirmCallback }) => {
  const renderDefaultButtons = (
    <div className={styles.buttons}>
      <MediumButton text="Yes" action={confirmCallback} buttonStyle="blue" />
      <MediumButton text="No" action={cancelCallback} buttonStyle="white" />
    </div>
  )

  const renderButtonsConfig = config ? (
    <div className={styles.buttons}>
      {config.map((options, id) => {
        return <MediumButton {...options} key={"confirmationButton" + id} />
      })}
    </div>
  ) : null

  const buttons = config ? renderButtonsConfig : renderDefaultButtons

  return (
    <div className={styles.container}>
      <motion.div className={styles.popup} {...scaleIn}>
        <div className={styles.close}>
          <CrossButton onClick={cancelCallback} />
        </div>
        <div className={styles.content}>
          {children}
          {showButtons ? buttons : null}
        </div>
      </motion.div>
      <PopupBackground backgroundClickCallback={cancelCallback} />
    </div>
  )
}

export default ConfirmationLayout
