import AddListingFileUploadProgressBar from "components/AddListingFileUploadProgressBar/AddListingFileUploadProgressBar"
import { IFileProgress, IListingFormData, TAddListingFormDataUpdateCallback, TFileType } from "types/addListing.types"
import { ReactComponent as Folder } from "assets/icons/addListing/folder.svg"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import LinkButton from "components/LinkButton/LinkButton"
import { fadeIn } from "constants/animationContants"
import styles from "./AddListingStep4.module.scss"
import { motion, Reorder } from "framer-motion"
import React, { ChangeEvent } from "react"
import { v4 as uuidv4 } from "uuid"
import { UseError } from "hooks"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  postParcel: () => void
  listingFormData: IListingFormData
}
const links = {
  privacy: "/privacy",
  terms: "/tos",
}
const formatIncomingFileData = (data: Partial<IFileProgress>[]) => {
  if (!data.length) return []
  return data.map((i) => (i.id ? i : { ...i, id: i.uuid, file: undefined })) as IFileProgress[]
}

const AddListingStep4: React.FC<Props> = ({
  formDataUpdateCallback,
  handleNext,
  handleBack,
  postParcel,
  listingFormData,
}) => {
  const { notify } = UseError()
  const { photos, documents } = listingFormData
  const [documentFilesList, setDocumentFilesList] = React.useState<IFileProgress[]>(formatIncomingFileData(documents))
  const [photoFilesList, setPhotoFilesList] = React.useState<IFileProgress[]>(formatIncomingFileData(photos))

  const reorderContainerRef = React.useRef<HTMLDivElement>(null)

  const onFileUpload = (type: TFileType) => (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (files?.length) updateFile(files[0], type)
  }

  const updateFile = (file: File, type: TFileType) => {
    const { name, size } = file
    const listSetter = type === "photo" ? setPhotoFilesList : setDocumentFilesList
    listSetter((prev) => [...prev, { id: uuidv4(), file, uuid: "", url: "", size: size, filename: name }])
  }

  const onFileRemove = (id: string, type: TFileType) => {
    const listSetter = type === "photo" ? setPhotoFilesList : setDocumentFilesList
    listSetter((prev) => [...prev].filter((i) => i.id !== id))
  }

  const nextButtonCallback = () => {
    if (photoFilesList.length) {
      formDataUpdateCallback({ photos: photoFilesList })
      formDataUpdateCallback({ documents: documentFilesList })
      postParcel()
      return
    }
    notify("You have to add at least one image")
  }

  React.useEffect(() => {
    formDataUpdateCallback({ photos: photoFilesList })
  }, [photoFilesList])

  //TODO WE WILL NEED THAT LATER
  // React.useEffect(() => {
  //   formDataUpdateCallback({ documents: formatFileList(documentFilesList) })
  // }, [documentFilesList])

  const privacy = (
    <a href={links.privacy} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
  )

  const terms = (
    <a href={links.terms} target="_blank" rel="noreferrer">
      Terms of Use
    </a>
  )

  return (
    <motion.div {...fadeIn}>
      <AddListingTitle heading="Upload Photos" subheading={""} />
      <div className={styles.uploadPhotoContainer}>
        <div className={styles.uploadPhotoWrapper}>
          <div className={styles.uploadPhotoItem}>
            <Folder />
            <h2>Choose photos to upload here...</h2>
          </div>
          <input type="file" accept="image/jpeg, image/jpg, image/png" onChange={onFileUpload("photo")} />
        </div>
        <div className={styles.privacyPolicy}>
          <h2>
            By uploading the photos, you agree to our {terms} and {privacy}
          </h2>
        </div>
      </div>
      <motion.div ref={reorderContainerRef}>
        <Reorder.Group
          axis="y"
          values={photoFilesList}
          onReorder={setPhotoFilesList}
          dragConstraints={reorderContainerRef}
          dragElastic={0.05}
        >
          {photoFilesList.map((i) => (
            <Reorder.Item
              key={i.id}
              value={i}
              whileDrag={{ scale: 1.02 }}
              dragConstraints={reorderContainerRef}
              dragElastic={0.05}
            >
              <AddListingFileUploadProgressBar
                key={`${i.id}PhotoProgress`}
                type="photo"
                uploadedFile={i}
                fileList={photoFilesList}
                updateFileList={setPhotoFilesList}
                removeButtonCallback={(id: string) => onFileRemove(id, "photo")}
              />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </motion.div>
      {/*
       //TODO WE WILL NEED THAT LATER
       <div className={styles.uploadPhotoContainer}>
        <div className={styles.uploadDocumentWrapper}>
          <div className={styles.uploadDocumentPlaceholder}>
            <Folder />
            <h2>Choose documents to upload here,</h2>
            <h2>including floor plans, offering </h2>
            <h2>memorandums, and rent rolls.</h2>
            <h2>(PDF, XLS, DOC)</h2>
          </div>
          <input type="file" accept=".pdf , .csv, .xls, .doc , .docx" onChange={onFileUpload("document")} />
        </div>
      </div> */}
      {/* {documentFilesList.map((i) => (
        <AddListingFileUploadProgressBar
          key={`${i.id}FileProgress`}
          type="document"
          uploadedFile={i}
          fileList={documentFilesList}
          updateFileList={setDocumentFilesList}
          removeButtonCallback={(id: string) => onFileRemove(id, "document")}
        />
      ))} */}
      <div className={styles.navigationButtons}>
        <LinkButton onClick={handleBack} text="Back" secondary />
        <LinkButton onClick={nextButtonCallback} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingStep4
