import styles from "./SignUpStepMain.module.scss"
import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import SignUpSocialButtons from "components/SignUpSocialButtons/SignUpSocialButtons"
import SignUpDivider from "components/SignUpDivider/SignUpDivider"
import BigButton from "components/BigButton/BigButton"
import TextInput from "components/TextInput/TextInput"
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy"
import { motion } from "framer-motion"
import React, { ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"
import PopupBackground from "components/PopupBackground/PopupBackground"
import { ILoginErrors } from "types/api.types"
import CrossButton from "components/CrossButton/CrossButton"
import { slideInBottom } from "constants/animationContants"

type Props = {
  backButtonCallback: () => void
  buttonClickCallback: () => void
  inputChangeCallback: (type: "email" | "password" | "name" | "surname", value: string) => void
  registerUser: () => Promise<Partial<ILoginErrors> | undefined>
}

const SignUpStepMain: React.FC<Props> = ({ buttonClickCallback, inputChangeCallback, registerUser }) => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState<string>("")
  const [emailInputValidated, setEmailInputValidated] = React.useState<boolean>(true)
  const [emailError, setEmailError] = React.useState<string | null>(null)

  const handleLoginHereButton = () => navigate("/login")

  const validateEmail = async () => {
    const status = (await registerUser()) as ILoginErrors
    const emailError = status["email"]

    if (!emailError) {
      setEmailError(null)
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isEmailValidated = re.test(email) && email.length > 0 && email.length <= 100
      setEmailInputValidated(isEmailValidated)
      if (isEmailValidated) {
        buttonClickCallback()
      }
    } else {
      setEmailError(emailError[0])
    }
  }

  const emainInputChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)
    inputChangeCallback("email", value)
  }

  const renderError = () => {
    return emailError ? <div className={styles.error}>{emailError}</div> : null
  }

  return (
    <div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.closePopupButton}>
          <CrossButton />
        </div>
        <div className={styles.title}>
          <h2>Sign Up</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>
        <SignUpSocialButtons />
        <SignUpDivider text="OR" />
        <div className={styles.inputsList}>
          <TextInput
            text="Email"
            changeCallback={emainInputChangeCallback}
            validated={emailInputValidated}
            maxLength={100}
          />
          {renderError()}
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Sign Up" clickCallback={validateEmail} />
        </div>
        <PrivacyPolicy />
        <div className={styles.haveAnAccount}>
          <h2>
            Already have an account? <span onClick={handleLoginHereButton}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </div>
  )
}

export default SignUpStepMain
