import { TButtonAction, TButtonStyle } from "types/button.types"
import s from "./MediumButton.module.scss"
import { FC, useState } from "react"
import { motion } from "framer-motion"
import clsx from "clsx"

type Props = {
  text: string
  action: TButtonAction
  buttonStyle: TButtonStyle
}

const MediumButton: FC<Props> = ({ text, action, buttonStyle }) => {
  const [pending, setPending] = useState(false)

  const onClick = async () => {
    if (!action) return
    setPending(true)
    await action()
    setPending(false)
  }

  return (
    <motion.div
      className={clsx({
        [s.button]: true,
        [s.button_yes]: buttonStyle === "blue",
        [s.button_no]: buttonStyle === "white",
        [s.button_disabled]: pending,
      })}
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <h3>{text}</h3>
    </motion.div>
  )
}

export default MediumButton
