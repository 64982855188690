import { IApiParcel, IGetParcelsInViewProps } from "types/api.types"
import ENDPOINTS from "constants/api.constants"
import axios, { CancelToken } from "axios"
import getHeaders from "utils/GetHeaders"

export const getPropertiesInView = async (
  boundingBox: IGetParcelsInViewProps,
  query: string,
  cancelToken?: CancelToken
) => {
  const { x1, y1, x2, y2 } = boundingBox
  const headers = { cancelToken, ...getHeaders() }
  const parcels = await axios.get(`${ENDPOINTS.GET_PROPERTIES_IN_VIEW}${x1},${y1},${x2},${y2}&${query}`, headers)
  return parcels.data as IApiParcel[]
}
