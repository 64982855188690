import { ReactComponent as Edit } from "assets/icons/edit.svg"
import styles from "../../mixins/ellipseSvg.module.scss"
import { motion } from "framer-motion"
import React from "react"

type Props = {
  onClick: () => void | (() => Promise<void>)
}

const EditButton: React.FC<Props> = ({ onClick }) => {
  return (
    <motion.div className={styles.ellipseSvg} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.05 }}>
      <Edit onClick={onClick} />
    </motion.div>
  )
}

export default EditButton
