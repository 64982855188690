const config = [
  {
    id: 0,
    title: "Owner Listed",
    key: "OWNER",
    active: false,
  },
  {
    id: 1,
    title: "Agent Listed",
    key: "AGENT",
    active: false,
  },
]

export default config
