export const ROUTES = {
  HOME: "/",
  SIGN_UP: "/sign-up",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  CREATE_ACCOUNT: "/create-account",
  ADD_LISTING: "/add-listing",
  SAVED_SEARCHES: "/saved-searches",
  MY_PROPERTIES: "/my-properties",
  ACCOUNT_DETAILS: "/me",
  ACCOUNT_NOTIFICATIONS: "/me/notifications",
  EDIT_ACCOUNT_DETAILS: "/me/edit",
  LISTED_PROPERTY_PAGE: "/parcels/view/listed/:uuid/:hidden",
  UNLISTED_PROPERTY_PAGE: "/parcels/view/unlisted/:uuid/:hidden",
  PROPERTY_OWNER_PAGE: "/owners/view/:owner_id/:fullname",
  WATCH_LIST: "/watch-list",
  TERMS_OF_SERVICE: "/tos",
  PRIVACY_POLICY: "/privacy",
}
