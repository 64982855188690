import { authentificatedSelector } from "redux/reducers/app.reducer"
import { ReactComponent as Like } from "assets/icons/like.svg"
import deleteBookmarkProperty from "api/bookmark/deleteBookmarkProperty"
import deleteBookmarkListing from "api/bookmark/deleteBookmarkListing"
import bookmarkListing from "api/bookmark/bookmarkListing"
import bookmarkProperty from "api/bookmark/bookmarkProperty"
import { TDivClickEvent } from "types/components.types"
import { ParcelContext } from "context/parcelContext"
import { useNavigate } from "react-router-dom"
import styles from "./LikeButton.module.scss"
import { IApiParcel } from "types/api.types"
import { useSelector } from "react-redux"
import { motion } from "framer-motion"
import { useContext } from "react"
import { UseError } from "hooks"
import React from "react"
import clsx from "clsx"

type Props = {
  parcel: IApiParcel
  onBookmark?: (id: number, bookmarked: boolean) => void
}

const LikeButton: React.FC<Props> = ({ parcel, onBookmark }) => {
  const navigate = useNavigate()
  const { notify } = UseError()
  const { id, bookmarked, listing_type } = parcel
  const { updateParcels } = useContext(ParcelContext)
  const authentificated = useSelector(authentificatedSelector)
  const isListed = listing_type === "FOR_LEASE" || listing_type === "FOR_SALE"

  const bookmark = async () => {
    try {
      const action = isListed ? bookmarkListing : bookmarkProperty
      await action(id)
      updateParcels(id, { bookmarked: true })
      if (onBookmark) onBookmark(id, true)
    } catch (err) {
      notify("cannot bookmark property, please try later")
    }
  }

  const deleteBookmark = async () => {
    try {
      const action = isListed ? deleteBookmarkListing : deleteBookmarkProperty
      await action(id)
      updateParcels(id, { bookmarked: false })
      if (onBookmark) onBookmark(id, false)
    } catch (err) {
      notify("cannot remove bookmark, please try later")
    }
  }

  const onClick = async (e: TDivClickEvent) => {
    e.stopPropagation()
    if (!authentificated) return navigate("/create-account")
    if (bookmarked) return await deleteBookmark()
    return await bookmark()
  }

  return (
    <motion.div className={styles.container} onClick={onClick} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
      <div
        className={clsx({
          [styles.wrapper]: true,
          [styles.wrapper_active]: bookmarked,
        })}
      >
        <Like />
      </div>
    </motion.div>
  )
}

export default LikeButton
