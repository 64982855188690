import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import { ReactComponent as Circle } from "assets/icons/signup/circle.svg"
import { ReactComponent as Tick } from "assets/icons/signup/tick.svg"
import PopupBackground from "components/PopupBackground/PopupBackground"
import PasswordInput from "components/PasswordInput/PasswordInput"
import resetPasswordConfirm from "api/user/resetPasswordConfirm"
import BigButton from "components/BigButton/BigButton"
import styles from "./ResetPasswordStep2.module.scss"
import { useNavigate } from "react-router-dom"
import config from "../../validationConfig"
import React, { ChangeEvent } from "react"
import { motion } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import login from "api/user/login"
import { UseError } from "hooks"
import clsx from "clsx"
import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"
import { slideInBottom } from "constants/animationContants"

type Props = {}

const ResetPasswordStep2: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { setCredentials } = React.useContext(CredentialsContext) as ICredentialsContext
  const { notify } = UseError()
  const query = UseQuery()

  const [password, setPassword] = React.useState({ new_password1: "", new_password2: "" })
  const [inputHightlight, setInputHighlight] = React.useState({ repeat: true, password: true })
  const [validationList, setValidationList] = React.useState(config)

  const resetPassword = async () => {
    try {
      const { new_password1, new_password2 } = password
      const { uuid, token } = query
      if (uuid && token) return await resetPasswordConfirm({ new_password1, new_password2, token, uid: uuid })
      notify("token or uuid is missing")
    } catch (err) {
      notify(`reset password can't be perfomed for now , please try again later`)
    }
  }

  const loginUser = async () => {
    try {
      const { email } = query
      const { new_password1 } = password
      if (email && new_password1) {
        const key = await login({ email, password: new_password1 })
        if (key) setCredentials(key)
        return
      }
      notify("we had a problem logging you in , please login by yourself")
    } catch (err) {
      notify("we had a problem logging you in , please login by yourself")
    }
  }

  const validatePasswordCallback = (e: ChangeEvent<HTMLInputElement>): void => {
    const new_password1 = e.target.value

    const isOneCapital = new_password1.toLowerCase() !== new_password1 && new_password1.length > 0
    const isOneNumber = /\d/.test(new_password1) && new_password1.length > 0
    const isEightCharachers = new_password1.length >= 8

    setValidationList(
      [isOneCapital, isOneNumber, isEightCharachers].map((i, index) => ({ ...validationList[index], active: i }))
    )
    setPassword((pass) => ({ ...pass, new_password1 }))
  }

  const validateRepeatPasswordCallback = (e: ChangeEvent<HTMLInputElement>) => {
    const new_password2 = e.target.value
    setPassword((pass) => ({ ...pass, new_password2 }))
  }

  const validateEverything = async () => {
    const { new_password1, new_password2 } = password
    const isPasswordValidated = !validationList.find((i) => !i.active)
    const isPasswordRepeated = new_password1 === new_password2
    setInputHighlight({ repeat: isPasswordRepeated, password: isPasswordValidated })
    if (isPasswordRepeated && isPasswordValidated) {
      await resetPassword()
      await loginUser()
      navigate("/")
    }
  }

  const renderValidationItem = (i: typeof validationList[0]) => (
    <div
      className={clsx({
        [styles.validationItem]: true,
        [styles.validationItem_active]: i.active,
      })}
      key={`validationItem${i.id}`}
    >
      {i.active ? <Tick /> : <Circle />}
      <h2>{i.text}</h2>
    </div>
  )

  return (
    <motion.div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.backButton} />
        <div className={styles.title}>
          <h2>Reset Password</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>

        <div className={styles.inputsList}>
          <PasswordInput changeCallback={validatePasswordCallback} validated={inputHightlight["password"]} />
          <div className={styles.passwordValidation}>{validationList.map((i) => renderValidationItem(i))}</div>
          <PasswordInput
            text="Repeat Password"
            changeCallback={validateRepeatPasswordCallback}
            validated={inputHightlight["repeat"]}
          />
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Reset" clickCallback={validateEverything} />
        </div>
        <div className={styles.haveAnAccount}>
          <h2>
            Remembered your password? <span onClick={() => {}}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ResetPasswordStep2
