import getHeaders from "utils/GetHeaders"
import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import { IFilterData, ISearchCriteriaResponse } from "types/filter.types"

export const postSearchCriteria = async (
  data: Partial<IFilterData> & { location_query: string }
): Promise<AxiosResponse<ISearchCriteriaResponse>> => {
  const headers = getHeaders(false)
  return await axios.post(ENDPOINTS.SEARCH_CRITERIA, data, headers)
}

export default postSearchCriteria
