import HeaderUserCircle from "components/HeaderUserCircle/HeaderUserCircle"
import { ReactComponent as MobileLogo } from "assets/icons/mobileLogo.svg"
import { getMapSearchResults } from "api/parcels/map/getMapSearchResults"
import { ReactComponent as Search } from "assets/icons/search.svg"
import AboutUsPopup from "components/AboutUsPopup/AboutUsPopup"
import { Fragment, useContext, useRef, useState } from "react"
import { MapPolygonContext } from "context/mapPolygonContext"
import { ISearchApiFeatures } from "types/addListing.types"
import { MapMarkerContext } from "context/mapMarkerContext"
import { TInputChangeEvent } from "types/components.types"
import { ParcelContext } from "context/parcelContext"
import styles from "./Header.module.scss"
import { motion } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import _ from "lodash"

type Props = {}

const Header: React.FC<Props> = () => {
  const { listing_type } = UseQuery()
  const { mapRef, isOffmarket } = useContext(ParcelContext)
  const { onMarkerClick, dropMarkerSelection } = useContext(MapMarkerContext)
  const { handleFeatureClick, dropPolygonSelection } = useContext(MapPolygonContext)

  const [isResultVisible, setIsResultVisible] = useState(false)
  const [searchResultList, setSearcResultList] = useState<ISearchApiFeatures[]>([])
  const [aboutUs, setAboutUs] = useState(false)

  const searchRef = useRef<HTMLInputElement>(null)

  const handleLogo = () => setAboutUs(true)

  const fetchSearchResults = async (search: string) => {
    try {
      if (search) {
        const response = await getMapSearchResults(search)
        const { data } = response
        setSearcResultList(data)
        return data
      }
      setSearcResultList([])
      return []
    } catch (err) {
      console.error(err)
    }
  }

  const showPropertyTile = async (id: number | undefined) => {
    if (!id) {
      dropMarkerSelection()
      dropPolygonSelection()
      return
    }
    if (isOffmarket) {
      return handleFeatureClick(id)
    }
    await onMarkerClick(id.toString())
  }

  const resultClickCallback = async (e: React.MouseEvent<HTMLLIElement>) => {
    const resultID = (e.target as HTMLLIElement).getAttribute("data-id")
    const resultAddress = searchResultList.find((i) => i.id === Number(resultID))
    if (resultAddress) {
      const { address_full, coordinates, user_listing_property, id } = resultAddress
      const { latitude, longitude } = coordinates
      const propertyID = isOffmarket && listing_type ? id : user_listing_property?.[0]
      setIsResultVisible(false)
      searchRef.current!.value = address_full
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom: 16, essential: true })
      await showPropertyTile(propertyID)
    }
  }

  const inputChangeCallback = async (e: TInputChangeEvent) => {
    const search_value = e.target.value
    await fetchSearchResults(search_value)
  }

  const inputFocusCallback = () => setIsResultVisible(true)

  const inputBlurCallback = () => setIsResultVisible(false)

  const debouncedChangeCallback = _.debounce(inputChangeCallback, 500)

  return (
    <Fragment>
      <div className={styles.container}>
        <motion.div className={styles.mobileLogoContainer} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
          <MobileLogo onClick={handleLogo} />
        </motion.div>
        <div className={styles.searchContainer}>
          <div className={styles.search}>
            <input
              type="text"
              onChange={debouncedChangeCallback}
              onFocus={inputFocusCallback}
              onBlur={inputBlurCallback}
              ref={searchRef}
            />
            <Search />
          </div>
          <ul className={styles.searchResults}>
            {isResultVisible
              ? searchResultList.map((i) => (
                  <motion.li
                    className={styles.searchResultItem}
                    onMouseDown={resultClickCallback}
                    data-id={i.id}
                    key={i.id}
                    whileTap={{ scale: 0.97 }}
                  >
                    {i.address_full}
                  </motion.li>
                ))
              : null}
          </ul>
        </div>
        <HeaderUserCircle />
      </div>
      <AboutUsPopup show={aboutUs} setShow={setAboutUs} />
    </Fragment>
  )
}

export default Header
