import { useLocation, useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import React from "react"

export interface INavigationContext {
  goBack: (home?: boolean) => void
}

interface IHistory {
  pathname: string
  link: string
  id: string
}

export const NavigationContext = React.createContext({})

export const NavigationProvider = (props: { children: JSX.Element }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname, search } = location
  const [history, setHistory] = React.useState<IHistory[]>([])

  const main = () => {
    const homeLinks = history.filter((i) => i.pathname === "/").map((i) => i.link)
    const lngth = homeLinks.length
    const path = lngth ? homeLinks[lngth - 1] : "/"
    navigate(path)
  }

  const back = () => {
    if (history.length < 2) return navigate("/")
    navigate(-1)
  }

  const goBack = (home?: boolean) => {
    if (home) return main()
    return back()
  }

  const update = () => {
    const id = uuidv4()
    const updated = { id, pathname, link: pathname + search }
    setHistory((history) => [...history, updated])
  }

  React.useEffect(() => {
    update()
  }, [pathname, search])

  const value: INavigationContext = { goBack }

  return <NavigationContext.Provider value={value}>{props.children}</NavigationContext.Provider>
}
