import { ISearchCriteria, ISearchCriteriaResponse, TFilterObject } from "types/filter.types"
import SearchCriteriaCard from "components/SearchCriteriaCard/SearchCriteriaCard"
import ConfirmationLayout from "components/ConfirmationLayout/ConfirmationLayout"
import SavedSearchUpdater from "components/SavedSearchUpdater/SavedSearchUpdater"
import removeSearchCriteria from "api/searchCriteria/removeSearchCriteria"
import patchSearchCriteria from "api/searchCriteria/patchSearchCriteria"
import getSearchCriteria from "api/searchCriteria/getSearchCriteria"
import TitleHeader from "components/TitleHeader/TitleHeader"
import { AnimatePresence, motion } from "framer-motion"
import { tiltInBottom } from "constants/animationContants"
import styles from "./SavedSearches.module.scss"
import { UseError } from "hooks"
import React from "react"

type Props = {}

const SavedSearches: React.FC<Props> = () => {
  const { notify, notifySuccess } = UseError()

  const [criteria, setCriteria] = React.useState<ISearchCriteriaResponse>({ results: [], next: null, previous: null })
  const [updatedCriteria, setUpdatedCriteria] = React.useState<TFilterObject>({})
  const [activeCriteria, setActiveCriteria] = React.useState<ISearchCriteria | null>(null)
  const [confirmOpened, setConfirmOpened] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)

  const closeDeleteConfirmPopup = () => {
    setConfirmOpened(false)
    setActiveCriteria(null)
  }

  const openDeleteConfirmPopup = (criteria: ISearchCriteria) => {
    setActiveCriteria(criteria)
    setConfirmOpened(true)
  }

  const fetchCriteria = async () => {
    try {
      const response = await getSearchCriteria()
      const { data } = response
      const { results, next, previous } = data
      setCriteria({ results, next, previous })
    } catch (e) {
      notify("cannot get criteria")
    }
  }

  const removeCriteria = async () => {
    try {
      if (!activeCriteria) return
      await removeSearchCriteria(activeCriteria.uuid)
      await fetchCriteria()
      closeDeleteConfirmPopup()
    } catch (err) {
      notify("cannot delete search criteria")
    }
  }

  const patchCriteria = async () => {
    try {
      if (activeCriteria) {
        const patchData = { ...activeCriteria, ...updatedCriteria } as ISearchCriteria
        await patchSearchCriteria(patchData)
        await fetchCriteria()
        setUpdating(false)
        notifySuccess("search criteria sucessfully updated!")
      }
    } catch (e) {
      notify("error while updating criteria")
    }
  }

  const onCriteriaUpdate = (updatedValue: TFilterObject) => {
    setUpdatedCriteria((prev) => ({ ...prev, ...updatedValue }))
  }

  const openUpdatePopup = (criteria: ISearchCriteria) => {
    setActiveCriteria(criteria)
    setUpdating(true)
  }

  React.useEffect(() => {
    fetchCriteria()
  }, [])

  const renderDeleteConfirmationContent = (
    <React.Fragment>
      <h2>Are you sure that you want to delete this search criteria? </h2>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <motion.div
        className={styles.container}
        initial={{ x: "-100vw" }}
        animate={{ x: "0" }}
        exit={{ x: "-100vw" }}
        transition={{ opacity: { duration: 0.4 } }}
      >
        <TitleHeader title="Saved Searches" />
        <div className={styles.cardsContainer}>
          <AnimatePresence mode="wait">
            {criteria.results.map((i, index) => (
              <motion.div
                className={styles.searchItem}
                key={i.uuid}
                {...tiltInBottom}
                transition={{ duration: 0.3, delay: index * 0.05 }}
              >
                <SearchCriteriaCard searchCriteria={i} remove={openDeleteConfirmPopup} edit={openUpdatePopup} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </motion.div>
      <AnimatePresence mode="wait">
        {confirmOpened ? (
          <ConfirmationLayout cancelCallback={closeDeleteConfirmPopup} confirmCallback={removeCriteria}>
            {renderDeleteConfirmationContent}
          </ConfirmationLayout>
        ) : null}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {updating && activeCriteria ? (
          <SavedSearchUpdater
            close={() => setUpdating(false)}
            saveSearchCriteria={patchCriteria}
            updateCallback={onCriteriaUpdate}
            criteria={activeCriteria}
          />
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default SavedSearches
