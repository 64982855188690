export const geolocateControlSettings = {
  positionOptions: {
    enableHighAccuracy: true,
  },
  trackUserLocation: true,
  showUserHeading: true,
};

export const mapSettings = {
  style: "mapbox://styles/mapbox/streets-v11",
  center: [-79.9, 40.6],
  zoom: 9,
  projection: { name: "globe" },
};

export const navigationControlSettings = {
  showCompass: false
};
