import { TPropertyInformation } from "types/addListing.types"

interface IinputsConfig {
  id: number
  placeholder: string
  title: string
  keyToValue: keyof TPropertyInformation
  type: "text" | "date" | "number"
  maxLength: number | undefined
  textarea: boolean
  required: boolean
}

interface ISortingFilter {
  id: number
  title: string
  config: ISortingFilterConfig[]
  defaultActiveValue: string
  layoutKey: string
  activeAmount: "single" | "many"
  filterLayout: boolean
  keyToValue: keyof TPropertyInformation
  required: boolean
}

interface ISortingFilterConfig {
  id: number
  title: string
  key: string
  active: boolean
}

export const listerStatusConfig = [
  {
    id: 0,
    title: "Owner Listed",
    key: "OWNER",
    active: false,
  },
  {
    id: 1,
    title: "Broker Listed",
    key: "AGENT",
    active: false,
  },
]

export const parkingConfig = [
  {
    id: 0,
    title: "On-Street",
    key: "STREET",
    active: false,
  },
  {
    id: 1,
    title: "Off-Street",
    key: "OFFSTREET",
    active: false,
  },
]

export const inputsConfig: IinputsConfig[] = [
  {
    id: 0,
    placeholder: "mm/dd/yyyy",
    title: "The first date that the property will be available",
    keyToValue: "available_from",
    type: "date",
    maxLength: undefined,
    required: false,
    textarea: false,
  },
  {
    id: 1,
    placeholder: "e.g. 13,200",
    title: "Building SQFT",
    keyToValue: "building_sqft",
    type: "number",
    maxLength: 8,
    required: true,
    textarea: false,
  },
  {
    id: 2,
    placeholder: "e.g. 13,200",
    title: "Parcel SQFT",
    keyToValue: "parcel_sqft",
    type: "number",
    maxLength: 8,
    required: true,
    textarea: false,
  },
  {
    id: 3,
    placeholder: "e.g. $8,000",
    title: "Monthly Rent",
    keyToValue: "price",
    type: "number",
    maxLength: 12,
    required: true,
    textarea: false,
  },
  {
    id: 4,
    placeholder: "Add a short summary of your property",
    title: "Property Description",
    keyToValue: "description",
    maxLength: undefined,
    type: "text",
    required: false,
    textarea: true,
  },
]

export const sortingFilter: ISortingFilter[] = [
  {
    id: 0,
    title: "Lister Status",
    config: listerStatusConfig,
    defaultActiveValue: "owner",
    layoutKey: "ListerStatus",
    activeAmount: "single",
    filterLayout: false,
    keyToValue: "lister_status",
    required: true,
  },
  {
    id: 1,
    title: "Parking",
    config: parkingConfig,
    defaultActiveValue: "off-street",
    layoutKey: "ParkingAddListing",
    activeAmount: "single",
    filterLayout: false,
    keyToValue: "parking",
    required: true,
  },
]
