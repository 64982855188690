import { ISearchCriteriaResponse } from "types/filter.types"
import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const getSearchCriteria = async (): Promise<AxiosResponse<ISearchCriteriaResponse>> => {
  const headers = getHeaders(false)
  return await axios.get(ENDPOINTS.SEARCH_CRITERIA, headers)
}

export default getSearchCriteria
