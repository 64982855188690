import { authentificatedSelector } from "redux/reducers/app.reducer"
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import SmallButton from "components/SmallButton/SmallButton"
import React, { useContext, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FiltersContext } from "context/FiltersContext"
import { TDivClickEvent } from "types/components.types"
import { TFilterObject } from "types/filter.types"
import styles from "./Properties.module.scss"
import { UseError, UseSwipe } from "hooks"
import { useSelector } from "react-redux"
import config from "./PropertiesConfig"
import { motion } from "framer-motion"
import UseQuery from "hooks/UseQuery"
import clsx from "clsx"

interface IOptionsList {
  id: number
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
  keyToValue: string[]
  valueType: "range" | "array" | "single"
  active: boolean
  component: React.FC<{
    updateCallback: (updatedValue: TFilterObject) => void
    closeFilters: () => void
    resetQuery: () => void
    applyQuery: () => void
    noSelectAll?: boolean
  }>
}

type Props = {
  filtersOpened: boolean
  setFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const Properties: React.FC<Props> = ({ filtersOpened, setFiltersOpened }) => {
  const { queryProperties, saveSearchCriteria, resetQuery, applyQuery } = useContext(FiltersContext)
  const { notifySuccess } = UseError()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { init } = UseSwipe()
  const query = UseQuery()

  const [optionsList, setOptionsList] = useState<IOptionsList[]>(config as IOptionsList[])
  const [activeId, setActiveId] = useState("-1")

  const authentificated = useSelector(authentificatedSelector)
  const filterContainerRef = useRef<HTMLDivElement | null>(null)

  const activeFilter = optionsList.find((i) => i.id === Number(activeId))

  const initSwipeEvents = () => {
    if (!filterContainerRef.current) return
    init({ element: filterContainerRef, callback: close, direction: "top", stopPropagation: false })
  }

  const open = () => setFiltersOpened(true)

  const close = () => {
    setFiltersOpened(false)
    setOptionsList((list) => list.map((i) => ({ ...i, active: false })))
    setTimeout(() => {
      setActiveId("-1")
    }, 200)
  }

  const update = (id: string) => {
    setActiveId(id)
    setOptionsList((list) =>
      list.map((i) => (i.id === Number(id) ? { ...i, active: !i.active } : { ...i, active: false }))
    )
  }

  const onClick = (e: TDivClickEvent) => {
    const optionID = e.target.getAttribute("data-id")
    if (optionID) update(optionID)
    if (filtersOpened && optionID === activeId) return close()
    if (!filtersOpened) open()
  }

  const formatNumberArray = (arr: string[]) => arr.map((i) => formatPriceNoDecimal(i))

  const formatHeading = (value: string[] | string[][], i: IOptionsList) => {
    const { valueType, keyToValue, text } = i

    switch (valueType) {
      case "single": {
        return (value[0] as string).toLowerCase().replaceAll("_", " ")
      }
      case "array": {
        const str = Array.isArray(value[0]) && value[0].length > 1 ? `${value[0][0]} and more` : value[0]
        return (str as string).toLowerCase()
      }
      case "range": {
        const isMin = Object.keys(query).find((key) => key === keyToValue[0])
        const formatted = formatNumberArray(value as string[])
        if (value.length === 2) return `${text} ${formatted[0] + " - " + formatted[1]}`
        return isMin ? `${text} ${formatted[0]}+` : `${text} 0-${formatted[0]}`
      }
    }
  }

  const updateHeading = (i: IOptionsList) => {
    const { keyToValue, text, valueType } = i
    if (!valueType) return text
    const values = Object.keys(query).reduce((prev: string[], curr) => {
      return keyToValue.includes(curr) ? [...prev, query[curr]] : prev
    }, [])
    return values?.[0] ? formatHeading(values, i) : text
  }

  const saveSearch = async () => {
    if (authentificated) {
      await saveSearchCriteria()
      close()
      return notifySuccess("Search is sucessfully saved")
    }
    navigate("/login")
  }

  React.useEffect(() => {
    close()
  }, [pathname])

  React.useEffect(() => {
    initSwipeEvents()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.scrollableContainer}>
        <div className={styles.filterOptions}>
          {optionsList.length
            ? optionsList.map((i) => (
                <motion.div
                  className={clsx({
                    [styles.option]: true,
                    [styles.option_active]: i.active && filtersOpened,
                  })}
                  data-id={i.id}
                  key={`filters${i.id}`}
                  onClick={onClick}
                  whileHover={{ scale: 1.1 }}
                >
                  <i.icon />
                  <h2>{updateHeading(i)}</h2>

                  <div
                    className={clsx({
                      [styles.dropdownIcon]: true,
                      [styles.dropdownIcon_active]: i.active && filtersOpened,
                    })}
                  >
                    <Arrow />
                  </div>
                </motion.div>
              ))
            : null}
        </div>
        {Object.keys(query).length ? (
          <div className={styles.searchOptions}>
            <SmallButton text="Save Search" onClick={saveSearch} />
          </div>
        ) : null}
      </div>

      <div
        className={clsx({
          [styles.optionFilters]: true,
          [styles.optionFilters_active]: filtersOpened && activeFilter,
        })}
        ref={filterContainerRef}
      >
        {activeFilter && (
          <activeFilter.component
            updateCallback={queryProperties}
            applyQuery={applyQuery}
            closeFilters={close}
            resetQuery={resetQuery}
            noSelectAll={false}
          />
        )}
      </div>
    </div>
  )
}

export default Properties
