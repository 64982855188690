import { ReactComponent as ArrowBack } from "assets/icons/propertyPage/backArrow.svg"
import { INavigationContext, NavigationContext } from "context/navigationContext"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import ShareButton from "components/ShareButton/ShareButton"
import LikeButton from "components/LikeButton/LikeButton"
import styles from "./PropertyPageHeader.module.scss"
import { TDivClickEvent } from "types/components.types"
import { IApiParcelByUuid } from "types/api.types"
import Arrow from "components/Arrow/Arrow"
import { motion } from "framer-motion"
import React from "react"
import placeholder from "assets/icons/placeholder.png"

type Props = {
  propertyData: IApiParcelByUuid
  activeImage: number
  onBookmark: (id: number, bookmarked: boolean) => void
  openImageViewer: () => void
  next: (e: TDivClickEvent) => void
  back: (e: TDivClickEvent) => void
}

const PropertyPageHeader: React.FC<Props> = ({
  propertyData,
  activeImage,
  openImageViewer,
  next,
  back,
  onBookmark,
}) => {
  const { goBack } = React.useContext(NavigationContext) as INavigationContext
  const { price, most_recent_sales_price, listing_type, photos } = propertyData
  const propertyPrice = price || most_recent_sales_price
  const backgroundImage = photos.length ? `url(${photos[activeImage]?.url})` : `url(${placeholder})`

  const handleBackButton = (e: TDivClickEvent) => {
    e.stopPropagation()
    goBack()
  }

  return (
    <div className={styles.container} style={{ backgroundImage }} onClick={openImageViewer}>
      <div className={styles.topOutline} />
      <div className={styles.controls}>
        <motion.div
          className={styles.alignmentLeft}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleBackButton}
        >
          <ArrowBack />
        </motion.div>
        <div className={styles.alignmentRight}>
          <ShareButton />
          <LikeButton parcel={propertyData} onBookmark={onBookmark} />
        </div>
      </div>
      <div className={styles.arrowControls}>
        <Arrow dir="l" onClick={back} />
        <Arrow dir="r" onClick={next} />
      </div>
      <div className={styles.information}>
        <h2>
          {propertyPrice && String(propertyPrice)?.length ? (
            <span className={styles.bold}>${formatPriceNoDecimal(propertyPrice)}</span>
          ) : null}
          {listing_type === "FOR_LEASE" ? <span className={styles.small}> /month</span> : null}
        </h2>
      </div>
      <div className={styles.bottomOutline} />
    </div>
  )
}

export default PropertyPageHeader
