import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import { IApiParcelByUuid } from "types/api.types"
import getHeaders from "utils/GetHeaders"

export const getUserPropertyById = async (id: string | number): Promise<IApiParcelByUuid> => {
  const headers = getHeaders(false)
  const response = await axios.get(`${ENDPOINTS.GET_USER_PROPERTIES}${id}/`, headers)
  return response.data
}

export default getUserPropertyById
