import { useEffect, useRef } from "react"

type Tdirection = "top" | "bottom" | "left" | "right"
interface ISwipeInitProps {
  element: React.MutableRefObject<HTMLDivElement | null>
  callback: () => void
  direction: Tdirection
  stopPropagation: boolean
}

interface IUseSwipeReturn {
  init: (props: ISwipeInitProps) => void
}

type TSwipe = { start: number; end: number }

const swipeDelta = 40

export const UseSwipe = (): IUseSwipeReturn => {
  const swipeElement = useRef<HTMLDivElement | null>(null)
  const swipeCallback = useRef<(() => void) | null>(null)
  const swipeDirection = useRef<Tdirection>("top")

  const swipe = useRef<TSwipe>({ start: 0, end: 0 })

  const calculateEnd = (el: HTMLDivElement) => el.scrollHeight - el.scrollTop === el.clientHeight

  const update = (data: Partial<TSwipe>) => (swipe.current = { ...swipe.current, ...data })

  const start = (e: TouchEvent) => {
    const { screenY } = e.changedTouches[0]
    update({ start: screenY })
  }

  const end = (e: TouchEvent) => {
    const { screenY } = e.changedTouches[0]
    update({ end: screenY })
    checkDirection()
  }

  const checkDirection = () => {
    if (!swipeCallback.current) return

    const { start, end } = swipe.current
    const direction = swipeDirection.current

    switch (direction) {
      case "top": {
        const isSwipedTop = start > end && start - end > swipeDelta
        if (!isSwipedTop) return
        const target = swipeElement.current as HTMLDivElement
        const isEnd = calculateEnd(target)
        if (isEnd) swipeCallback.current()
        return
      }
      case "bottom": {
        const isSwipedBottom = start < end && end - start > swipeDelta
        if (!isSwipedBottom) return
        swipeCallback.current()
        return
      }
    }
  }

  const forceStopPropagation = (e: TouchEvent) => {
    e.preventDefault()
  }

  const init = (props: ISwipeInitProps) => {
    const { element, callback, direction, stopPropagation } = props
    const el = element.current
    if (el) {
      swipeElement.current = el
      swipeCallback.current = callback
      swipeDirection.current = direction
      if (stopPropagation) el.addEventListener("touchmove", forceStopPropagation, false)
      el.addEventListener("touchstart", start, false)
      el.addEventListener("touchend", end, false)
    }
  }

  const garbageCollect = () => {
    const el = swipeElement?.current
    if (el) {
      el.removeEventListener("touchmove", forceStopPropagation, false)
      el.removeEventListener("touchstart", start, false)
      el.removeEventListener("touchend", end, false)
    }
  }

  useEffect(() => {
    return () => {
      garbageCollect()
    }
  }, [])

  return { init }
}
