import { ReactComponent as Bubble } from "assets/icons/pins/bubblePinBase.svg"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import { formatCash } from "utils/FormatPrice"
import { IApiParcel } from "types/api.types"
import s from "./MapMarker.module.scss"
import { MapboxEvent } from "mapbox-gl"
import { motion } from "framer-motion"
import { Marker } from "react-map-gl"
import React from "react"
import clsx from "clsx"

type Props = {
  parcel: IApiParcel
  viewedParcelsIdList: string[]
  selectedPinId: string
  onClick: (e: MapboxEvent<MouseEvent>) => void
}

const MapMarker: React.FC<Props> = ({ parcel, onClick, selectedPinId, viewedParcelsIdList }) => {
  const { id, price, coordinates, listing_type } = parcel
  const isViewed = viewedParcelsIdList.find((id) => id === id.toString())
  const isActive = selectedPinId === id.toString()

  const renderPrice = () => {
    if (price) {
      const formatting = price.toString().length > 4 ? formatCash : formatPriceNoDecimal
      return <h2>{formatting(price)}</h2>
    }
    return <h2>N/A</h2>
  }

  return (
    <Marker longitude={coordinates.longitude} latitude={coordinates.latitude} anchor="bottom" onClick={onClick}>
      <motion.div
        data-id={id}
        className={clsx({
          [s.bubbleContainer]: true,
          [s.bubbleContainer_active]: isActive,
          [s.bubbleContainer_visited]: !isActive && isViewed,
          [s.bubbleContainer_lease]: listing_type !== "FOR_SALE" && listing_type !== "SOLD",
          [s.bubbleContainer_sale]: listing_type === "FOR_SALE",
          [s.bubbleContainer_sold]: listing_type === "SOLD",
        })}
        whileHover={{ scale: 1.1 }}
      >
        <Bubble />
        <div className={s.textContainer}>{renderPrice()}</div>
      </motion.div>
    </Marker>
  )
}

export default MapMarker
