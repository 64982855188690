import { INavigationContext, NavigationContext } from "context/navigationContext"
import styles from "./PopupBackground.module.scss"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import React from "react"

type Props = {
  autoNavigation?: boolean
  backgroundClickCallback?: () => void
}

const PopupBackground: React.FC<Props> = ({ autoNavigation = true, backgroundClickCallback }) => {
  const navigate = useNavigate()
  const { goBack } = React.useContext(NavigationContext) as INavigationContext

  const backgroundClick = () => {
    if (backgroundClickCallback) return backgroundClickCallback()
    if (autoNavigation) return goBack(true)
    navigate(-1)
  }

  return (
    <motion.div
      className={styles.darkBackground}
      initial={{ opacity: 0, pointerEvents: "none" }}
      animate={{ opacity: 1, pointerEvents: "all" }}
      exit={{ opacity: 0, pointerEvents: "none" }}
      transition={{ opacity: { duration: 0.4 }, pointerEvents: { duration: 0 } }}
      onClick={backgroundClick}
    />
  )
}

export default PopupBackground
