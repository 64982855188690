import { ReactComponent as SmallLogo } from "assets/icons/addListing/smallLogo.svg"
import AddListingProgressBar from "../AddListingProgressBar/AddListingProgressBar"
import PopupBackground from "components/PopupBackground/PopupBackground"
import CrossButton from "components/CrossButton/CrossButton"
import styles from "./AddListingLayout.module.scss"
import { motion } from "framer-motion"
import React from "react"

type Props = {
  children: JSX.Element
  layoutTitle: string
  currentStep: number
  progressBarList: { id: number; circleActive: boolean; lineActive: boolean }[]
  layoutSecondLineTitle?: string
  backgroundClickCallback?: () => void
  closeButtonCallback?: () => void
}

const AddListingLayout: React.FC<Props> = ({
  children,
  layoutTitle,
  currentStep,
  progressBarList,
  layoutSecondLineTitle,
  backgroundClickCallback,
  closeButtonCallback,
}) => {
  return (
    <div className={styles.addListingWrapper}>
      <PopupBackground backgroundClickCallback={backgroundClickCallback} />
      <motion.div
        className={styles.layoutContainer}
        initial={{ top: "-100vh" }}
        animate={{ top: "0" }}
        exit={{ top: "-100vh" }}
        transition={{ top: { duration: 0.7 } }}
      >
        <div className={styles.ListingPopup}>
          <motion.div className={styles.crossWrapper}>
            <CrossButton onClick={closeButtonCallback} />
          </motion.div>
          <div className={styles.LogoWrapper}>
            <SmallLogo />
          </div>
          <div className={styles.title}>
            <h2>{layoutTitle}</h2>
            {layoutSecondLineTitle && <h2 className={styles.secondLineTitle}>{layoutSecondLineTitle}</h2>}
          </div>
          <AddListingProgressBar progressBarList={progressBarList} currentStep={currentStep} />
          <motion.div className={styles.childerWrapper}>{children}</motion.div>
        </div>
      </motion.div>
    </div>
  )
}

export default AddListingLayout
