import { GeolocateControl, MapLayerMouseEvent, NavigationControl } from "mapbox-gl"
import { MapboxStyleSwitcherControl } from "components/MapViewer/SwitchViewControl/SwitchViewControl"
import { mapLoadingSelector } from "redux/reducers/app.reducer"
import MapLoadingIndicator from "components/MapLoadingIndicator/MapLoadingIndicator"
import MapPolygonsPopup from "components/MapPolygonsPopup/MapPolygonsPopup"
import { geolocateControlSettings, navigationControlSettings } from "defaultSettings/defaultSetting"
import { ParcelsRequestsContext } from "context/parcelRequestsContext"
import { MapPolygonContext } from "context/mapPolygonContext"
import MapPolygons from "components/MapPolygons/MapPolygons"
import { ROUTES } from "constants/router.constants"
import { useLocation } from "react-router-dom"
import CONSTANTS from "constants/constants"
import Map, { MapRef } from "react-map-gl"
import { useSelector } from "react-redux"
import UseQuery from "hooks/UseQuery"
import React, { useContext } from "react"
import { MapMarkerContext } from "context/mapMarkerContext"
import { ParcelContext } from "context/parcelContext"

type Props = {
  setFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const MapGeoJson: React.FC<Props> = ({ setFiltersOpened }) => {
  const { listing_type } = UseQuery()
  const { search, pathname } = useLocation()

  const { parcelInViewRequest, polygonInViewRequest } = useContext(ParcelsRequestsContext)
  const { handleFeatureHover, handleFeatureClick } = useContext(MapPolygonContext)
  const { markers, setSelectedPinId } = useContext(MapMarkerContext)
  const { mapRef, setActiveParcel } = useContext(ParcelContext)

  const [isShowTutorial, setIsShowTutorial] = React.useState(false)
  const loading = useSelector(mapLoadingSelector)

  const isPolygons = listing_type === "OFF_MARKET"

  const _onLoad = () => {
    const map = mapRef.current
    if (!map) return
    addControls(map)
    fetchController()
  }

  const addControls = (map: MapRef) => {
    map.addControl(new MapboxStyleSwitcherControl())
    map.addControl(new GeolocateControl({ ...geolocateControlSettings }))
    map.addControl(new NavigationControl({ ...navigationControlSettings }))
  }

  const getMapBboxCoords = () => {
    const map = mapRef.current
    if (!map) return null

    const bounds = map.getBounds()
    const [topLeft, bottomRight] = [bounds.getNorthWest(), bounds.getSouthEast()]
    return {
      x1: topLeft.lng,
      y1: topLeft.lat,
      x2: bottomRight.lng,
      y2: bottomRight.lat,
    }
  }

  const fetchFeatures = () => {
    const boundingBox = getMapBboxCoords()
    if (boundingBox && pathname === ROUTES.HOME) {
      const request = isPolygons ? polygonInViewRequest : parcelInViewRequest
      request(boundingBox)
    }
  }

  const onClick = (e: MapLayerMouseEvent) => {
    const { features } = e
    setSelectedPinId("-1")
    if (isPolygons && !features?.[0]) {
      setActiveParcel(null)
    }
    if (!isPolygons) setActiveParcel(null)
    if (!features?.[0]) handleFeatureClick()
  }

  const onMoveStart = () => setFiltersOpened(false)

  const onMouseMove = (event: MapLayerMouseEvent) => {
    const { features } = event
    handleFeatureHover(features?.[0])
  }

  const onMouseDown = (event: MapLayerMouseEvent) => {
    const { features } = event
    const selectedFeatureId = features?.[0]?.id
    if (selectedFeatureId) handleFeatureClick(Number(selectedFeatureId))
  }

  const polygonsFetchController = () => {
    const map = mapRef.current
    if (!map) return
    const zoom = map.getZoom()
    if (zoom >= 14) {
      fetchFeatures()
      setIsShowTutorial(false)
      return
    }
    setIsShowTutorial(true)
  }

  const fetchController = () => {
    if (!isPolygons) {
      setIsShowTutorial(false)
      return fetchFeatures()
    }
    polygonsFetchController()
  }

  const cancelSelection = () => {
    setSelectedPinId("-1")
    setActiveParcel(null)
    handleFeatureClick()
  }

  React.useEffect(() => {
    fetchController()
    cancelSelection()
  }, [search])

  return (
    <React.Fragment>
      <Map
        ref={mapRef}
        mapboxAccessToken={CONSTANTS.mapboxKey}
        doubleClickZoom={false}
        onLoad={_onLoad}
        onMoveEnd={fetchController}
        onClick={onClick}
        onMoveStart={onMoveStart}
        style={{ width: "100vw", height: "100vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
        initialViewState={CONSTANTS.pittsburgViewState}
        interactiveLayerIds={isPolygons ? ["parcels"] : undefined}
        onMouseDown={isPolygons ? onMouseDown : undefined}
        onMouseMove={isPolygons ? onMouseMove : undefined}
      >
        {isPolygons ? <MapPolygons /> : markers}
      </Map>
      {loading ? <MapLoadingIndicator /> : null}
      <MapPolygonsPopup visible={isShowTutorial} />
    </React.Fragment>
  )
}

export default MapGeoJson
