import styles from "./Login.module.scss"
import React, { ChangeEvent } from "react"
import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import SignUpSocialButtons from "components/SignUpSocialButtons/SignUpSocialButtons"
import SignUpDivider from "components/SignUpDivider/SignUpDivider"
import TextInput from "components/TextInput/TextInput"
import PasswordInput from "components/PasswordInput/PasswordInput"
import BigButton from "components/BigButton/BigButton"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import PopupBackground from "components/PopupBackground/PopupBackground"
import login from "api/user/login"
import { AxiosError } from "axios"
import { ILoginErrors } from "types/api.types"
import { UseError } from "hooks"
import CrossButton from "components/CrossButton/CrossButton"
import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"
import { slideInBottom } from "constants/animationContants"

type Props = {}

const Login: React.FC<Props> = () => {
  const { setCredentials } = React.useContext(CredentialsContext) as ICredentialsContext
  const { notifySuccess } = UseError()
  const [userLoginData, setUserLoginData] = React.useState({ email: "", password: "" })
  const [errors, setErrors] = React.useState<Partial<ILoginErrors>>({})
  const navigate = useNavigate()

  const updateLoginData = (update: Partial<Record<keyof typeof userLoginData, string>>) => {
    setUserLoginData((prev) => ({ ...prev, ...update }))
  }

  const updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    updateLoginData({ password: e.target.value })
  }

  const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
    updateLoginData({ email: e.target.value })
  }

  const handleCreateAccountButton = () => {
    navigate("/create-account")
  }

  const handleCloseButton = () => {
    navigate("/")
  }

  const handleForgotPasswordButton = () => {
    navigate("/reset-password")
  }

  const loginUser = async () => {
    try {
      const key = await login(userLoginData)
      if (key) {
        setCredentials(key)
        handleCloseButton()
        notifySuccess("Successfully signed in!")
      }
    } catch (err) {
      const messages = (err as AxiosError)?.response?.data as Partial<ILoginErrors>
      setErrors(messages)
    }
  }

  const renderError = (type: keyof ILoginErrors) => {
    const message = errors?.[type]?.[0]
    return message ? <div className={styles.error}>{message}</div> : null
  }

  return (
    <div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.closePopupButton}>
          <CrossButton />
        </div>
        <div className={styles.title}>
          <h2>Login</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>
        <SignUpSocialButtons />
        <SignUpDivider text="OR" />
        <div className={styles.inputsList}>
          <TextInput text="Email" changeCallback={updateEmail} maxLength={255} />
          {renderError("email")}
          <PasswordInput changeCallback={updatePassword} />
          {renderError("password")}
          {renderError("non_field_errors")}
        </div>
        <div className={styles.basicOptions}>
          <div className={styles.forgotPassword}>
            <h2 onClick={handleForgotPasswordButton}>Forgot password?</h2>
          </div>
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Login" clickCallback={loginUser} />
        </div>
        <SignUpDivider text="OR CREATE NEW ACCOUNT" />
        <div className={styles.createNewAccount}>
          <h2>
            New user? <span onClick={handleCreateAccountButton}>Create account</span>
          </h2>
        </div>
      </motion.div>
    </div>
  )
}

export default Login
