import { IApiParcelByUuid } from "types/api.types"
import ENDPOINTS from "constants/api.constants"
import axios from "axios"
import getHeaders from "utils/GetHeaders"

export const getParcelByUuidOffMarket = async (uuid: string | number) => {
  const headers = getHeaders()
  const parcels = await axios.get(`${ENDPOINTS.GET_PARCELS_PAGINATION}${uuid}/`, headers)
  return parcels.data as IApiParcelByUuid
}
