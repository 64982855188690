import { ReactComponent as Map } from "assets/icons/map.svg";
import { ReactComponent as List } from "assets/icons/list.svg";

const config = [
  {
    id: 0,
    text: "Map",
    icon: Map,
    active: true,
  },
  {
    id: 1,
    text: "List",
    icon: List,
    active: false,
  },
];

export default config;
