import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import { ILoggedUser } from "types/api.types"
import getHeaders from "utils/GetHeaders"

export const patchUser = async (data: Partial<ILoggedUser>): Promise<AxiosResponse<ILoggedUser>> => {
  const headers = getHeaders(false)
  return await axios.patch(ENDPOINTS.GET_PROFILE, data, headers)
}

export default patchUser
