const config = [
  {
    id: 0,
    active: false,
    text: "One capital letter",
  },
  {
    id: 1,
    active: false,
    text: "minimum one number",
  },
  {
    id: 2,
    active: false,
    text: "8 characters",
  },
]

export default config
