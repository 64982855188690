import { TAccess, TCoordinates, TProperty } from "./api.types"

export type TlistingType = "FOR_LEASE" | "FOR_SALE" | "SOLD" | "OFF-MARKET" | undefined

export type TParking = "STREET" | "OFFSTREET" | undefined

export type TListerStatus = "OWNER" | "AGENT" | undefined

export type TUpdateFormDataCallbackValue = Partial<
  string | TlistingType | TProperty[] | TlistingType[] | TPropertyInformation | TContactInformation | File[]
>

export type TAddListingFormDataUpdateCallback = (value: Partial<IListingFormData>) => void

export type TPropertyInformation = {
  available_from: string
  building_sqft: number | undefined
  parcel_sqft: number | undefined
  price: number | undefined
  description: string | undefined
  lister_status: TListerStatus | undefined
  parking: TParking | undefined
  parking_offstreet_spots: string | undefined
  access: TAccess[] | undefined
  year_built: number | undefined
  zone_code: string | undefined
}

export type TContactInformation = {
  contact_name: string
  contact_company: string
  contact_email: string
  contact_phone: string
}

export type TFileType = "photo" | "document"

export type TFileStatus = "fullfiled" | "loading" | "rejected"
export interface IFileProgress {
  id: string
  uuid: string
  file: File
  size: number
  url: string
  filename: string
}
export interface IListingFormData {
  listing_type: TlistingType
  address_full: string
  coordinates: TCoordinates
  property_type: TProperty | undefined
  available_from: string | undefined
  building_sqft: number | undefined
  parcel_sqft: number | undefined
  price: number | undefined
  description: string | undefined
  lister_status: TListerStatus | undefined
  year_built: number | undefined
  zone_code: string | undefined
  parking: TParking | undefined
  parking_offstreet_spots: string | undefined
  access: TAccess[] | undefined
  contact_name: string
  contact_company: string
  contact_email: string
  contact_phone: string
  photos: Partial<IFileProgress>[]
  documents: Partial<IFileProgress>[]
  property: number
}
export interface ISearchApiContext {
  id: string
  short_code: string
  text: string
  wikidate: string
}

export interface ISearchApiFeatures {
  id: number
  coordinates: TCoordinates
  address_full: string
  user_listing_property: number[]
}

export const initialPropertyInformation: TPropertyInformation = {
  available_from: "",
  building_sqft: undefined,
  parcel_sqft: undefined,
  price: undefined,
  description: undefined,
  lister_status: undefined,
  parking: undefined,
  parking_offstreet_spots: undefined,
  access: undefined,
  year_built: undefined,
  zone_code: undefined,
}

export const initialContactInformation = {
  contact_name: "",
  contact_company: "",
  contact_email: "",
  contact_phone: "",
}

export const initialFormData: IListingFormData = {
  listing_type: undefined,
  address_full: "",
  coordinates: { longitude: 0, latitude: 0 },
  property_type: undefined,
  available_from: "",
  building_sqft: undefined,
  parcel_sqft: undefined,
  price: undefined,
  description: undefined,
  lister_status: undefined,
  year_built: undefined,
  zone_code: undefined,
  parking: undefined,
  parking_offstreet_spots: undefined,
  access: undefined,
  contact_name: "",
  contact_company: "",
  contact_email: "",
  contact_phone: "",
  photos: [],
  documents: [],
  property: 0,
}
