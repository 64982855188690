import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login"
import { ReactComponent as Google } from "assets/icons/signup/google.svg"
import ENDPOINTS from "constants/api.constants"
import styles from "./AuthButton.module.scss"
import CONSTANTS from "constants/constants"
import React from "react"

type Props = {
  authorizeUser: (token: string, endpoint: string, code?: boolean) => void
  validate: () => boolean
}

const GoogleAuth: React.FC<Props> = ({ authorizeUser, validate }) => {
  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const token = (res as GoogleLoginResponse).accessToken
    if (token) authorizeUser(token, ENDPOINTS.GOOGLE_LOGIN)
  }

  const onFailure = (res: { error: string }) => {
    console.error("Google Auth Error", res.error)
  }

  const onClick = (action: (() => void) | undefined) => {
    const validated = validate()
    if (validated && action) action()
  }

  return (
    <GoogleLogin
      clientId={CONSTANTS.googleClientId}
      render={(renderProps) => (
        <div className={styles.LoginButtonItem} onClick={() => onClick(renderProps.onClick)}>
          <Google />
          <h2>Sign In with Google</h2>
        </div>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
    />
  )
}

export default GoogleAuth
