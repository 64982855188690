import { ReactComponent as ForSale } from "assets/icons/pins/salePin.svg"
import { ReactComponent as ForLease } from "assets/icons/pins/leasePin.svg"
import { ReactComponent as Sold } from "assets/icons/pins/soldPin.svg"

const config = [
  {
    id: 0,
    text: "For Lease",
    key: "FOR_LEASE",
    icon: ForLease,
    active: false,
  },
  {
    id: 1,
    text: "For Sale",
    key: "FOR_SALE",
    icon: ForSale,
    active: false,
  },
  {
    id: 2,
    text: "Sold",
    key: "SOLD",
    icon: Sold,
    active: false,
  },
]

export default config
