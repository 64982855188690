const config = [
  {
    id: 0,
    title: "Off-Street Parking",
    key: "OFFSTREET",
    active: false,
  },
  {
    id: 1,
    title: "Street Parking",
    key: "STREET",
    active: false,
  },
]

export default config
