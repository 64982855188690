import styles from "./MapLoadingIndicator.module.scss"
import React from "react"

type Props = {}

const MapLoadingIndicator: React.FC<Props> = () => {
  return (
    <div className={styles.mapLoading}>
      <div className={styles.dotContainer}>
        <button className={styles.dotButton}>
          <div className={styles.dotWrapper}>
            <span>
              <span className={styles.dot} />
              <span className={styles.dot} />
              <span className={styles.dot} />
            </span>
          </div>
        </button>
      </div>
    </div>
  )
}

export default MapLoadingIndicator
