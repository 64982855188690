import React from "react"
import config from "./ListerStatusFilter.config"
import SortingFilter from "../SortingFilter/SortingFilter"
import { TFilterObject } from "types/filter.types"

type Props = {
  updateCallback: (updatedValue: TFilterObject) => void
  defaultActiveValue?: string
}

const ParkingFilter: React.FC<Props> = ({ updateCallback, defaultActiveValue }) => {
  return (
    <SortingFilter
      mapConfig={config}
      layoutKey="ListerStatusFilter"
      filterLayout
      keyToValue="lister_status"
      updateCallback={updateCallback}
      defaultActiveValue={defaultActiveValue}
    />
  )
}

export default ParkingFilter
