import styles from "./CheckboxInput.module.scss"
import { FC, useState } from "react"

type Props = {
  text: string
  keyToValue: string
  defaultValue?: boolean
  callback: (value: Record<string, any>) => void
}

const CheckboxInput: FC<Props> = ({ text, defaultValue = false, keyToValue, callback }) => {
  const [checked, setChecked] = useState(defaultValue)

  const onChange = () => {
    const next = !checked
    setChecked(next)
    callback({ [keyToValue]: next })
  }

  return (
    <div className={styles.container}>
      <input type="checkbox" id={keyToValue} readOnly onChange={onChange} defaultChecked={checked} />
      <label htmlFor={keyToValue}>{text}</label>
    </div>
  )
}

export default CheckboxInput
