import { ISearchCriteria, TFilterObject } from "types/filter.types"
import { TInputChangeEvent } from "types/components.types"
import styles from "./SavedSearchUpdater.module.scss"
import { TProperty } from "types/api.types"
import { motion } from "framer-motion"
import config from "./config"
import React from "react"
import EditButton from "components/EditButton/EditButton"
import CrossButton from "components/CrossButton/CrossButton"
import BigButton from "components/BigButton/BigButton"

type Props = {
  saveSearchCriteria: () => Promise<void>
  updateCallback: (updatedValue: TFilterObject) => void
  close: () => void
  criteria: ISearchCriteria
}

const SavedSearchUpdater: React.FC<Props> = ({ saveSearchCriteria, updateCallback, close, criteria }) => {
  const [title, setTitle] = React.useState("Default search name")
  const [editingTitle, setEditingTitle] = React.useState(false)

  const onEdit = () => setEditingTitle((prev) => !prev)

  const onTitleChange = (e: TInputChangeEvent) => setTitle(e.target.value)

  return (
    <motion.div
      className={styles.container}
      initial={{ y: "-100vh" }}
      animate={{ y: 0 }}
      exit={{ y: "-100vh" }}
      transition={{ type: "tween", duration: 0.5 }}
    >
      <div className={styles.dynamicTitle}>
        <span>
          <CrossButton onClick={close} />
        </span>
        {editingTitle ? <input defaultValue={title} type="text" onChange={onTitleChange} /> : <h2>{title}</h2>}
        <EditButton onClick={onEdit} />
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.scrollWrapper}>
          {config.map((i, index) => (
            <div className={styles.filterItem} key={`advanceFilterItem${index}`}>
              <div className={styles.filteInfo}>
                <i.icon />
                <h2>{i.text}</h2>
              </div>
              <div className={styles.content}>
                <i.component
                  noReset
                  filterLayout
                  updateCallback={updateCallback}
                  keyToValue={i.filterKey}
                  defaultActiveValue={criteria?.[i.filterKey as keyof ISearchCriteria] as string}
                  defaultActiveValues={criteria?.[i.filterKey as keyof ISearchCriteria] as TProperty[]}
                  dataObject={criteria}
                />
              </div>
            </div>
          ))}
          <div className={styles.applySave}>
            <BigButton text="Apply & Save" clickCallback={saveSearchCriteria} />
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default SavedSearchUpdater
