import s from "./PropertyPageDescription.module.scss"
import { IApiParcelByUuid } from "types/api.types"
import parse from "html-react-parser"
import { FC } from "react"

type Props = {
  parcel: IApiParcelByUuid
}

const PropertyPageDescription: FC<Props> = ({ parcel }) => {
  const { description } = parcel
  const renderNoDescription = (
    <div className={s.noDescription}>
      <h2>This property has no Description</h2>
    </div>
  )

  return (
    <div className={s.container}>
      <h2>Property Description</h2>
      <div className={s.description}>
        {description ? parse("<h2>" + description.replace(/\n/g, "<br />") + "</h2>") : <h2>{renderNoDescription}</h2>}
      </div>
    </div>
  )
}

export default PropertyPageDescription
