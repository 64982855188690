import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import styles from "./PropertyPageTaxData.module.scss"
import { IApiParcelByUuid } from "types/api.types"
import React from "react"

type Props = {
  parcel: IApiParcelByUuid
}

interface ITaxConfig {
  id: number
  text: string
  value: string | number
  type: "value" | "price"
}

const PropertyPageTaxData: React.FC<Props> = ({ parcel }) => {
  const { assessed_land_value, assessed_improved_value, total_assessed_value, property_tax, tax_year } = parcel

  const config: ITaxConfig[] = [
    {
      id: 0,
      text: "Assessed Land Value",
      value: assessed_land_value,
      type: "price",
    },
    {
      id: 1,
      text: "Assessed Improved Value",
      value: assessed_improved_value,
      type: "price",
    },
    {
      id: 2,
      text: "Total Assessed Values",
      value: total_assessed_value,
      type: "price",
    },
    {
      id: 3,
      text: "Property Tax",
      value: property_tax,
      type: "price",
    },
    {
      id: 4,
      text: "Tax Year",
      value: tax_year,
      type: "value",
    },
  ]

  const formatTaxItem = (i: string | number, type: "value" | "price") => {
    if (!i || !String(i).length) return "N/A"
    switch (type) {
      case "value": {
        return i
      }
      case "price": {
        return `$${formatPriceNoDecimal(String(i))}`
      }
    }
  }

  const renderTaxData = config.map((i, index) => {
    const { text, value, type } = i
    return (
      <div className={styles.informationItem} key={value + String(index)}>
        <h2>{text}</h2>
        <h2>{formatTaxItem(value, type)}</h2>
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Tax Data</h2>
      </div>
      <div className={styles.information}>{renderTaxData}</div>
    </div>
  )
}

export default PropertyPageTaxData
