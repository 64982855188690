import BigButton from "components/BigButton/BigButton"
import styles from "./AdvancedFilters.module.scss"
import { TFilterObject } from "types/filter.types"
import config from "./AdvancedFilters.config"
import React from "react"

type Props = {
  updateCallback: (updatedValue: TFilterObject) => void
  closeFilters: () => void
  resetQuery: () => void
  applyQuery: () => void
}

const AdvancedFIlters: React.FC<Props> = ({ updateCallback, applyQuery, resetQuery, closeFilters }) => {
  const onCancel = () => closeFilters()

  const onReset = () => {
    resetQuery()
    closeFilters()
  }

  const onSearch = () => {
    applyQuery()
    closeFilters()
  }

  return (
    <div className={styles.container}>
      <div className={styles.scrollWrapper}>
        <div className={styles.resetFilters} onClick={onReset}>
          <h2>Reset Filters</h2>
        </div>
        {config.map((i, index) => (
          <div className={styles.filterItem} key={`advanceFilterItem${index}`}>
            <div className={styles.filteInfo}>
              <i.icon />
              <h2>{i.text}</h2>
            </div>
            <div className={styles.content}>
              <i.component
                updateCallback={updateCallback}
                keyToValue={i.filterKey}
                noReset={false}
                noSelectAll={false}
              />
            </div>
          </div>
        ))}
        <div className={styles.applySave}>
          <BigButton text="Search" clickCallback={onSearch} />
          <BigButton text="Cancel" clickCallback={onCancel} />
        </div>
      </div>
    </div>
  )
}

export default AdvancedFIlters
