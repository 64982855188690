import { ReactComponent as LoadingDock } from "assets/icons/accessFilter/loadingDock.svg"
import { ReactComponent as DriveIn } from "assets/icons/accessFilter/driveIn.svg"
import { ReactComponent as Elevator } from "assets/icons/accessFilter/elevator.svg"

const config = [
  {
    id: 0,
    title1: "Loading",
    title2: "Dock",
    icon: LoadingDock,
    key: "LOADING_DOCK",
    active: false,
  },
  {
    id: 1,
    title1: "Drive-in",
    title2: "Garage",
    icon: DriveIn,
    key: "GARAGE",
    active: false,
  },
  {
    id: 2,
    title1: "Elevator",
    title2: null,
    icon: Elevator,
    key: "ELEVATOR",
    active: false,
  },
]

export default config
