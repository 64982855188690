import styles from "./SignUpDivider.module.scss"
import React from "react"

type Props = {
  text: string
}

const SignUpDivider: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.divider}>
      <div className={styles.line} />
      <h2>{text}</h2>
      <div className={styles.line} />
    </div>
  )
}

export default SignUpDivider
