import { IListingFormData, initialFormData } from "../../types/addListing.types"
import { postParcel } from "api/parcels/UserListing/postParcel"
import { absoluteObject } from "utils/AbsoluteObject"
import progressBarConfig from "./progressBar.config"
import { IApiParcelByUuid } from "types/api.types"
import getLoggedUser from "api/user/getLoggedUser"
import UseProgressBar from "hooks/UseProgressBar"
import AddListingLayout from "./AddListingLayout"
import { AnimatePresence } from "framer-motion"
import { FC, useEffect, useState } from "react"
import config from "./addListing.config"
import { UseError } from "hooks"

type Props = {}

const AddListing: FC<Props> = () => {
  const { handleNext, handleBack, progressBarList, currentStep } = UseProgressBar({ progressBarConfig })
  const [listingFormData, setListingFormData] = useState<IListingFormData>(initialFormData)
  const [createdParcelId, setCreatedParcelId] = useState<number | null>(null)
  const { notify } = UseError()

  const activeComponent = config[currentStep]

  const updateFormData = (value: Partial<IListingFormData>): void => {
    setListingFormData((prev) => ({ ...prev, ...value }))
  }

  //TODO REMOVE THIS FUNCTION AND TYPE BACK TO IListingFormData WHEN API IS GONNA BE READY
  const formatListingFormData = () => {
    const photos = listingFormData.photos.map((i) => i.uuid).filter((i) => i)
    const formDataCopy = { ...listingFormData, photos } as Partial<IListingFormData>
    delete formDataCopy["documents"]
    return formDataCopy as Omit<IListingFormData, "documents">
  }

  const updateFormDataFromUser = async () => {
    try {
      const { data } = await getLoggedUser()
      const { first_name, last_name, company, phone_number, email_address } = data
      const contactInfo = {
        contact_name: first_name + " " + last_name,
        contact_company: company,
        contact_email: email_address,
        contact_phone: phone_number,
      }
      updateFormData(absoluteObject(contactInfo))
    } catch (e) {
      console.error("fetch user error")
    }
  }

  const handlePostParcel = async () => {
    try {
      const formData = formatListingFormData()
      const createdParcel = await postParcel(formData)
      const { id } = createdParcel
      setCreatedParcelId(id)
      handleNext()
    } catch (err) {
      notify("We had some technical issues while posting your listing, please try later, we are working on that")
    }
  }

  const updateFormDataSpecificParcel = (data?: IApiParcelByUuid) => {
    const removeKeys = ["listing_type", "price"]
    if (data) {
      const formData = Object.keys(data).reduce((prev, curr) => {
        const listingDataKeys = Object.keys(listingFormData)
        const isExist = listingDataKeys.find((key) => key === curr) && !removeKeys.includes(curr)
        return isExist ? { ...prev, [curr]: data[curr as keyof IApiParcelByUuid] } : prev
      }, {})
      updateFormData({ ...formData, photos: [], documents: [] })
    }
  }

  useEffect(() => {
    updateFormDataFromUser()
  }, [])

  return (
    <AddListingLayout layoutTitle={"Add Listing"} currentStep={currentStep} progressBarList={progressBarList}>
      <AnimatePresence mode="wait">
        <activeComponent.component
          updateFormDataSpecificParcel={updateFormDataSpecificParcel}
          formDataUpdateCallback={updateFormData}
          postParcel={handlePostParcel}
          handleNext={handleNext}
          handleBack={handleBack}
          listingFormData={listingFormData}
          redirectID={createdParcelId}
          prefill
          key={currentStep}
        />
      </AnimatePresence>
    </AddListingLayout>
  )
}

export default AddListing
