import { ReactComponent as Position } from "assets/icons/position.svg"
import { ReactComponent as Phone } from "assets/icons/phoneBlue.svg"
import { ReactComponent as Email } from "assets/icons/email.svg"
import placeholder from "assets/icons/avatarPlaceholder.png"
import TitleHeader from "components/TitleHeader/TitleHeader"
import getLoggedUser from "api/user/getLoggedUser"
import { useNavigate } from "react-router-dom"
import { ILoggedUser } from "types/api.types"
import styles from "./MyProfile.module.scss"
import { motion } from "framer-motion"
import React from "react"

type Props = {}

const EditAccount: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [user, setUser] = React.useState<ILoggedUser | null>(null)

  const getUser = async () => {
    try {
      const { data } = await getLoggedUser()
      setUser(data)
    } catch (e) {
      console.error("fetch user error")
    }
  }

  const onEdit = () => {
    navigate("/me/edit/")
  }

  const renderAvatar = () => {
    const { avatar_url } = user as ILoggedUser
    return <img src={avatar_url ? avatar_url : placeholder} alt="avatar" />
  }

  const renderName = () => {
    const { first_name = "", last_name = "" } = user as ILoggedUser

    return (
      <div className={styles.name}>
        <h2>{`${first_name} ${last_name}`}</h2>
      </div>
    )
  }

  const renderPosition = () => {
    const { position = "", company = "" } = user as ILoggedUser
    const modifier = position?.length ? "at" : ""
    const companyPart = company?.length ? `${modifier} ${company}` : ""
    const isRender = companyPart?.length || position?.length
    return isRender ? (
      <div className={styles.position}>
        <Position />
        <h2>{`${position} ${companyPart}`}</h2>
      </div>
    ) : null
  }

  const renderEmail = () => {
    const { email_address = "" } = user as ILoggedUser
    const isRender = email_address?.length
    return isRender ? (
      <div className={styles.email}>
        <Email />
        <h2>{email_address}</h2>
      </div>
    ) : null
  }

  const renderPhone = () => {
    const { formatted_phone_number } = user as ILoggedUser
    const isRender = formatted_phone_number?.length
    return isRender ? (
      <a className={styles.phone} href={`tel:${formatted_phone_number}`}>
        <Phone />
        <h2>{formatted_phone_number}</h2>
      </a>
    ) : null
  }

  React.useEffect(() => {
    getUser()
  }, [])

  return (
    <motion.div className={styles.container} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <TitleHeader title="My Profile" />
      {user ? (
        <motion.div
          className={styles.accountInformation}
          initial={{ y: 20, scale: 0.95 }}
          animate={{ y: 0, scale: 1 }}
          exit={{ scale: 0.95 }}
        >
          <div className={styles.avatar}>
            <div className={styles.editButton} onClick={onEdit}>
              <h2>Edit</h2>
            </div>
            {renderAvatar()}
            {renderName()}
            {renderPosition()}
            {renderEmail()}
            {renderPhone()}
          </div>
        </motion.div>
      ) : null}
    </motion.div>
  )
}

export default EditAccount
