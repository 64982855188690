import { ReactComponent as Eye } from "assets/icons/signup/eye.svg"
import styles from "./PasswordInput.module.scss"
import React, { ChangeEvent } from "react"
import clsx from "clsx"
import { motion } from "framer-motion"

type Props = {
  text?: string
  changeCallback?: (e: ChangeEvent<HTMLInputElement>) => void
  validated?: boolean
}

const PasswordInput: React.FC<Props> = ({ text = "Password", changeCallback, validated = true }) => {
  const passwordRef = React.useRef<HTMLInputElement>(null)

  const [passwordHidden, setPasswordHidden] = React.useState(true)

  const handleShowPassword = () => {
    const passwordInput = passwordRef.current
    setPasswordHidden(!passwordHidden)
    if (passwordInput?.type === "password") return (passwordInput!.type = "text")
    return (passwordInput!.type = "password")
  }

  return (
    <div
      className={clsx({
        [styles.inputItem]: true,
        [styles.inputItem_active]: !validated,
      })}
    >
      <input required type="password" ref={passwordRef} onChange={changeCallback} maxLength={32} />
      <span>{text}</span>
      <div
        className={clsx({
          [styles.showPassword]: true,
          [styles.showPassword_active]: !passwordHidden,
        })}
        onClick={handleShowPassword}
      >
        <motion.div className={styles.visibilityControl} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Eye />
        </motion.div>
      </div>
    </div>
  )
}

export default PasswordInput
