import React from "react"
import styles from "./MobileBottomMenu.module.scss"
import config from "./MobileBottomMenuConfig"
import clsx from "clsx"
import { useLocation, useNavigate } from "react-router-dom"
import { TDivClickEvent } from "types/components.types"
import { useSelector } from "react-redux"
import { authentificatedSelector } from "redux/reducers/app.reducer"
import { motion } from "framer-motion"

type Props = {}

const MobileBottomMenu: React.FC<Props> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const authentificated = useSelector(authentificatedSelector)

  const handleNavigation = (path: string) => (e: TDivClickEvent) => {
    if (!authentificated) return navigate("/create-account")
    if (pathname !== path && authentificated) navigate(path)
  }

  return (
    <div className={styles.container}>
      {config.map((i) => (
        <motion.div
          className={clsx({
            [styles.navigationItem]: true,
            [styles.navigationItem_modied]: i.modifier,
            [styles.navigationItem_active]: pathname === i.path,
          })}
          onClick={handleNavigation(i.path)}
          key={`navigationItem${i.id}`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <i.icon />

          <h2>{i.text}</h2>
        </motion.div>
      ))}
    </div>
  )
}

export default MobileBottomMenu
