import { IListingFormData, ISearchApiFeatures, TAddListingFormDataUpdateCallback } from "types/addListing.types"
import { getParcelByUuidOffMarket } from "api/parcels/OffMarket/getParcelByUuidOffmarket"
import { getMapSearchResults } from "api/parcels/map/getMapSearchResults"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import Map, { LngLatLike, MapRef, Marker } from "react-map-gl"
import { TInputChangeEvent } from "types/components.types"
import LinkButton from "components/LinkButton/LinkButton"
import styles from "./AddListingAddLocation.module.scss"
import { fadeIn } from "constants/animationContants"
import { IApiParcelByUuid } from "types/api.types"
import CONSTANTS from "constants/constants"
import { motion } from "framer-motion"
import { UseError } from "hooks"
import React from "react"
import _ from "lodash"
import clsx from "clsx"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  updateFormDataSpecificParcel: (data?: IApiParcelByUuid) => void
  listingFormData: IListingFormData
  prefill: boolean
}

const AddListingAddLocation: React.FC<Props> = ({
  handleNext,
  handleBack,
  formDataUpdateCallback,
  updateFormDataSpecificParcel,
  listingFormData,
  prefill,
}) => {
  const { notify } = UseError()
  const { address_full, coordinates } = listingFormData
  const mapRef = React.useRef<MapRef | null>(null)
  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const [searchResultList, setSearcResultList] = React.useState<ISearchApiFeatures[]>([])
  const [isResultVisible, setIsResultVisible] = React.useState(false)
  const [address, setAddress] = React.useState<ISearchApiFeatures | null>(null)
  const [validated, setValidated] = React.useState<boolean>(true)

  const fetchSearchResults = async (search: string) => {
    try {
      if (search) {
        const response = await getMapSearchResults(search)
        const { data } = response
        setSearcResultList(data)
        return data
      }
      setSearcResultList([])
      return []
    } catch (err) {
      console.error(err)
    }
  }

  const fillFormDataFromParcel = async (uuid: number) => {
    try {
      const parcel = await getParcelByUuidOffMarket(uuid)
      if (parcel) updateFormDataSpecificParcel(parcel)
    } catch (err) {
      notify("we had some technical issues while prefilling the form, you are still able to add/update listing")
    }
  }

  const inputChangeCallback = async (e: TInputChangeEvent) => {
    const search_value = e.target.value
    await fetchSearchResults(search_value)
  }

  const inputFocusCallback = () => setIsResultVisible(true)

  const inputBlurCallback = () => setIsResultVisible(false)

  const debouncedChangeCallback = _.debounce(inputChangeCallback, 500)

  const resultClickCallback = async (e: React.MouseEvent<HTMLLIElement>) => {
    const _id = (e.target as HTMLLIElement).getAttribute("data-id")
    const selectedAdress = searchResultList.find((i) => i.id === Number(_id))
    if (selectedAdress) {
      const { address_full, coordinates, id } = selectedAdress
      const { latitude, longitude } = coordinates
      setIsResultVisible(false)
      searchInputRef.current!.value = address_full
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom: 16, essential: true })
      setAddress(selectedAdress)
      setValidated(true)
      if (prefill) await fillFormDataFromParcel(id)
    }
  }

  const updateFormData = () => {
    const { address_full, coordinates, id } = address as ISearchApiFeatures
    formDataUpdateCallback({ address_full, coordinates, property: id })
  }

  const nextButtonCallback = () => {
    setValidated(false)
    if (address) {
      updateFormData()
      handleNext()
    }
  }

  const onLoad = async () => {
    const search = searchInputRef.current
    const map = mapRef.current
    if (search && address_full && map) {
      search.value = address_full
      const fetchedResults = await fetchSearchResults(address_full)
      const results = fetchedResults || []
      setAddress(results[0])
      mapRef.current?.flyTo({
        center: [coordinates.longitude, coordinates.latitude] as LngLatLike,
        zoom: 16,
        essential: true,
      })
    }
  }

  React.useEffect(() => {
    if (address) updateFormData()
  }, [address])

  return (
    <motion.div className={styles.addLocationContainer} {...fadeIn}>
      <AddListingTitle heading="Select Address" subheading="(Currently only available in Pittsburgh)" />
      <div className={styles.paddingWrapper}>
        <div
          className={clsx({
            [styles.adressInputContainer]: true,
            [styles.adressInputContainer_error]: !validated,
          })}
        >
          <input
            type="text"
            placeholder="Type Address"
            onChange={debouncedChangeCallback}
            onFocus={inputFocusCallback}
            onBlur={inputBlurCallback}
            ref={searchInputRef}
          />
          <ul className={styles.searchResults}>
            {isResultVisible
              ? searchResultList.map((i) => (
                  <motion.li
                    className={styles.searchResultItem}
                    onMouseDown={resultClickCallback}
                    data-id={i.id}
                    key={i.id}
                    whileTap={{ scale: 0.97 }}
                  >
                    {i.address_full}
                  </motion.li>
                ))
              : null}
          </ul>
        </div>
      </div>
      <div className={styles.adressMapContainer}>
        <Map
          ref={mapRef}
          onLoad={onLoad}
          mapboxAccessToken={CONSTANTS.mapboxKey}
          doubleClickZoom={false}
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          initialViewState={CONSTANTS.pittsburgViewStateFullScreen}
        >
          {address ? (
            <Marker
              longitude={address.coordinates.longitude}
              latitude={address?.coordinates.latitude}
              anchor="bottom"
            />
          ) : null}
        </Map>
      </div>
      <div className={styles.navigationButtons}>
        <LinkButton onClick={handleBack} text="Back" secondary />
        <LinkButton onClick={nextButtonCallback} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingAddLocation
