import { IListingFormData, TAddListingFormDataUpdateCallback } from "types/addListing.types"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import CardFilter from "../../../Filters/CardFilter/CardFilter"
import LinkButton from "components/LinkButton/LinkButton"
import { fadeIn } from "constants/animationContants"
import styles from "./AddListingStep1.module.scss"
import { TProperty } from "types/api.types"
import { motion } from "framer-motion"
import React from "react"
import config from "components/Filters/CardFilter/CardFilter.config"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  listingFormData: IListingFormData
}

const maxValues = 1

const cardsConfig = config.map((i) => ({ ...i, active: false }))

const AddListingStep1: React.FC<Props> = ({ formDataUpdateCallback, handleNext, handleBack, listingFormData }) => {
  const { property_type } = listingFormData
  const [selectedPropertyTypesList, setSelectedPropertyTypesList] = React.useState<TProperty[]>([])
  const [validated, setValidated] = React.useState<boolean>(true)

  const updateSelectedPropertyTypesList = (i: TProperty[]) => {
    setSelectedPropertyTypesList(i)
    if (!validated && i.length === maxValues) setValidated(true)
  }

  const handleNextButton = () => {
    if (selectedPropertyTypesList.length >= maxValues) {
      formDataUpdateCallback({ property_type: selectedPropertyTypesList?.[0] })
      handleNext()
    } else {
      setValidated(false)
    }
  }

  React.useEffect(() => {
    if (property_type) setSelectedPropertyTypesList([property_type])
  }, [])

  React.useEffect(() => {
    if (selectedPropertyTypesList.length) formDataUpdateCallback({ property_type: selectedPropertyTypesList?.[0] })
  }, [selectedPropertyTypesList])

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <AddListingTitle heading="Select Property Type" subheading={""} validated={validated} />
      <div className={styles.cardsWrapper}>
        <CardFilter
          noReset
          maxActiveValues={maxValues}
          cardClickCallback={updateSelectedPropertyTypesList}
          defaultActiveValues={property_type ? [property_type] : []}
          cardFilterConfig={cardsConfig}
        />
      </div>
      <div className={styles.navigationButtons}>
        <LinkButton onClick={handleBack} text="Back" secondary />
        <LinkButton onClick={handleNextButton} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingStep1
