import styles from "./AddListingTitle.module.scss"
import React from "react"
import clsx from "clsx"

type Props = {
  heading: string
  subheading: string | string[]
  validated?: boolean
}

const AddListingTitle: React.FC<Props> = ({ heading, subheading, validated = true }) => {
  const subheadingList = [subheading].flat()

  return (
    <div
      className={clsx({
        [styles.title]: true,
        [styles.title_error]: !validated,
      })}
    >
      <h2>{heading}</h2>
      {subheadingList.map((i, index) => (
        <h3 key={`${index}title`}>{i}</h3>
      ))}
    </div>
  )
}

export default AddListingTitle
