import { TActiveTab } from "types/components.types";
import styles from "./MobileTabsSelector.module.scss";
import config from "./MobileTabsSelectorConfig";
import React from "react";
import clsx from "clsx";
import { TDivClickEvent } from "types/components.types";

interface ITabsConfig {
  id: number;
  text: string;
  icon: string | any;
  active: boolean;
}

type Props = {
  tabChangeCallback: (type: TActiveTab) => void;
};

const MobileTabsSelector: React.FC<Props> = ({ tabChangeCallback }) => {
  const [tabsConfig, setTabsConfig] = React.useState<ITabsConfig[]>([]);

  const animateBorder = (target: HTMLElement, id: string) => {
    target.parentElement?.style.setProperty(
      "--transformAmount",
      `translateX(${100 * Number(id)}%)`
    );
  };

  const handleTabClick = (e: TDivClickEvent) => {
    const tabID = e.target.getAttribute("data-id");

    if (tabID) {
      animateBorder(e.target, tabID);

      setTabsConfig(
        tabsConfig.map((i) => {
          if (i.id === Number(tabID)) {
            tabChangeCallback(i.text.toLowerCase() as TActiveTab);
            return { ...i, active: true };
          }
          return { ...i, active: false };
        })
      );
    }
  };

  React.useEffect(() => {
    setTabsConfig(config);
  }, []);

  return (
    <div className={styles.container}>
      {tabsConfig.length
        ? tabsConfig.map((i) => (
            <div
              className={clsx({
                [styles.tab]: true,
                [styles.tab_active]: i.active,
              })}
              key={`tab${i.id}`}
              data-id={i.id}
              onClick={handleTabClick}
            >
              <i.icon />
              <h2>{i.text}</h2>
            </div>
          ))
        : null}
    </div>
  );
};

export default MobileTabsSelector;
