const getHeaders = (cookie: boolean = true) => {
  const token = localStorage.getItem("userAuthentificationToken")
  if (token) {
    const parsed = JSON.parse(token)
    const cookies = cookie ? {withCredentials: true} : {}
    if (parsed.length) return { ...cookies, headers: { Authorization: `Token ${parsed}` } }
  }
  return { withCredentials: true }
}
export default getHeaders
