import { ReactComponent as ArrowDown } from "assets/icons/propertyPage/arrowDown.svg"
import styles from "./PropertyPageMediaAndPressReports.module.scss"
import { IApiParcel, IParcelStory } from "types/api.types"
import placeholder from "assets/icons/placeholder.png"
import CardSlide from "components/CardSlide/CardSlide"
import React from "react"

type Props = { property: IApiParcel }

const step = 5
//TODO REMOVE CHECK IF STORY EXISTS WHEN API IS READY
const PropertyPageMediaAndPressReports: React.FC<Props> = ({ property }) => {
  const { stories } = property
  const [storiesList, setStoriesList] = React.useState<IParcelStory[]>(stories?.slice(0, 2) || [])

  const isSeeMoreButton = stories?.length !== storiesList.length

  const renderNoPressReports = (
    <div className={styles.emptyReportsContainer}>
      <h2>This property has no Neighborhood News</h2>
    </div>
  )

  const onExpand = () => {
    if (stories) setStoriesList((prev) => [...prev, ...stories.slice(prev.length, prev.length + step)])
  }

  const renderStories = () =>
    storiesList?.map((i) => {
      const { id, description = "N/A", image, source = "N/A", title = "N/A", url } = i
      const link = url?.includes("http") ? url : undefined
      return (
        <React.Fragment key={`report${id}`}>
          <a className={styles.pressReportsItem} href={link} target="_blank" rel="noreferrer">
            <div className={styles.reportPreview}>
              <CardSlide src={image || placeholder} />
            </div>
            <div className={styles.pressReportInfo}>
              <h2>{title}</h2>
              <h3>{description}</h3>
              <h4>{source}</h4>
            </div>
          </a>
          <div className={styles.divider}></div>
        </React.Fragment>
      )
    })

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Neighborhood News</h2>
      </div>
      {stories ? <div className={styles.pressReportsList}>{renderStories()}</div> : renderNoPressReports}
      {isSeeMoreButton && stories ? (
        <div className={styles.seeMoreButton} onClick={onExpand}>
          <h2>See More</h2>
          <ArrowDown />
        </div>
      ) : null}
    </div>
  )
}

export default PropertyPageMediaAndPressReports
