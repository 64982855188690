import { getParcelByUuid } from "api/parcels"
import { getParcelByUuidOffMarket } from "api/parcels/OffMarket/getParcelByUuidOffmarket"
import MapMarker from "components/MapMarker/MapMarker"
import { MapboxEvent } from "mapbox-gl"
import { useContext, useState, createContext } from "react"
import { useSelector } from "react-redux"
import { parcelsInViewSelector } from "redux/reducers/app.reducer"
import { IApiParcel } from "types/api.types"
import { ParcelContext } from "./parcelContext"

export interface IMapMarkerContext {
  markers: JSX.Element[] | undefined
  markerClickCallback: (e: MapboxEvent<MouseEvent>) => void
  setSelectedPinId: React.Dispatch<React.SetStateAction<string>>
  onMarkerClick: (id: string) => Promise<void>
  dropMarkerSelection: () => void
}

export const MapMarkerContext = createContext({} as IMapMarkerContext)

export const MapMarkerContextProvider = ({ children }: { children: JSX.Element }) => {
  const { viewedParcelsList, parcelsList, setActiveParcel, setViewed, isOffmarket } = useContext(ParcelContext)
  const [selectedPinId, setSelectedPinId] = useState("-1")
  const parcelsInView = useSelector(parcelsInViewSelector)

  const updateActiveParcel = (parcel: IApiParcel) => {
    if (parcel) {
      const id = parcel.id.toString()
      setActiveParcel(parcel)
      setSelectedPinId(id)
      setViewed(id)
    }
  }

  const dropMarkerSelection = () => {
    setActiveParcel(null)
    setSelectedPinId("-1")
  }

  const fetchMarkerData = async (id: string) => {
    const request = isOffmarket ? getParcelByUuidOffMarket : getParcelByUuid
    const parcel = await request(id)
    updateActiveParcel(parcel)
  }

  const onMarkerClick = async (id: string) => {
    try {
      const nextActive = parcelsList?.find((i) => i.id.toString() === id)
      if (nextActive) return updateActiveParcel(nextActive)
      await fetchMarkerData(id)
    } catch (err) {
      console.error(err)
    }
  }

  const markerClickCallback = ({ originalEvent }: MapboxEvent<MouseEvent>): void => {
    originalEvent.stopPropagation()
    const target = originalEvent.target as HTMLDivElement
    const id = target.getAttribute("data-id")
    if (!id) return
    onMarkerClick(id)
  }

  const markers = parcelsInView?.map((parcel) => (
    <MapMarker
      key={"id" + parcel.id}
      viewedParcelsIdList={viewedParcelsList}
      selectedPinId={selectedPinId}
      parcel={parcel}
      onClick={markerClickCallback}
    />
  ))

  const value = {
    markers,
    markerClickCallback,
    onMarkerClick,
    setSelectedPinId,
    dropMarkerSelection,
  }

  return <MapMarkerContext.Provider value={value}>{children}</MapMarkerContext.Provider>
}
