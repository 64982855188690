import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const deleteBookmarkListing = async (id: number | string) => {
  const headers = getHeaders(false)
  const endpoint = `${ENDPOINTS.GET_USER_LISTINGS_PAGINATION}${id}/bookmark/`
  return await axios.delete(endpoint, headers)
}

export default deleteBookmarkListing
