import getSimmilarProperties from "api/simmilarProperties/getSimmilarProperties"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import { TDivClickEvent } from "types/components.types"
import styles from "./SimmilarProperties.module.scss"
import { IApiParcelByUuid } from "types/api.types"
import { useNavigate } from "react-router-dom"
import { UseError } from "hooks"
import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { motion } from "framer-motion"
import clsx from "clsx"
import placeholder from "assets/icons/placeholder.png"

type Props = {
  parcel: IApiParcelByUuid
}

const SimmilarProperties: React.FC<Props> = ({ parcel }) => {
  const navigate = useNavigate()
  const { notify } = UseError()
  const { id } = parcel
  const [simmilar, setSimmilar] = React.useState<IApiParcelByUuid[]>([])
  const [fetching, setFetching] = React.useState(true)

  const onClick = (e: TDivClickEvent) => {
    const uuid = e.target.getAttribute("data-id")
    if (uuid) navigate(`/parcels/view/listed/${uuid}/false`)
  }

  const fetchSimmilar = async () => {
    try {
      const { data } = await getSimmilarProperties(id)
      setSimmilar(data)
      setFetching(false)
    } catch (e) {
      setFetching(false)
      notify("cannot get similar properties")
    }
  }

  React.useEffect(() => {
    fetchSimmilar()
  }, [])

  const renderSkeletonLayout = Array(3)
    .fill("")
    .map((i, index) => (
      <div className={styles.propertyListItem} key={`skeletonSimmilarCard${index}`}>
        <div className={styles.propertyListItemPreview}>
          <Skeleton height={"95%"} />
        </div>
        <div className={styles.propertyListItemDescription}>
          <Skeleton />
        </div>
      </div>
    ))

  const renderOutline = (
    <React.Fragment>
      <div
        className={clsx({
          [styles.previewOutline]: true,
          [styles.previewOutline_top]: true,
        })}
      />
      <div
        className={clsx({
          [styles.previewOutline]: true,
          [styles.previewOutline_bottom]: true,
        })}
      />
    </React.Fragment>
  )

  const renderLoadedLayout = simmilar.map((i) => {
    const { photos, price, listing_type, formatted_full_address } = i
    const isLease = listing_type === "FOR_LEASE"
    const url = photos?.[0] ? photos?.[0].url : placeholder
    return (
      <motion.div
        className={styles.propertyListItem}
        key={i.id}
        onClick={onClick}
        data-id={i.id}
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 1.01 }}
      >
        <div className={styles.propertyListItemPreview} style={{ backgroundImage: `url(${url})` }}>
          {renderOutline}
          <div
            className={clsx({
              [styles.price]: true,
              [styles.price_sale]: !isLease,
            })}
          >
            <h2>${formatPriceNoDecimal(price)}</h2>
            {isLease ? <h3>/month</h3> : null}
          </div>
        </div>
        <div className={styles.propertyListItemDescription}>
          <h2>{formatted_full_address ? formatted_full_address : "Address N/A"}</h2>
        </div>
      </motion.div>
    )
  })

  const renderEmptyLayout = (
    <div className={styles.noSimmilar}>
      <h3>No similar listings found</h3>
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.propertiesList}>
        {fetching ? renderSkeletonLayout : renderLoadedLayout}
        {!fetching && !simmilar.length ? renderEmptyLayout : null}
      </div>
    </div>
  )
}

export default SimmilarProperties
