import ListProperties from "components/ListProperties/ListProperties"
import { BaseSyntheticEvent, FC, Fragment } from "react"
import { IApiParcel } from "types/api.types"
import styles from "./CardList.module.scss"
import Card from "../Card/Card"

type Props = {
  parcelsList: IApiParcel[] | null
  handleCardClick: (e: BaseSyntheticEvent) => void
}

const CardList: FC<Props> = ({ parcelsList, handleCardClick }) => {
  return (
    <div className={styles.container}>
      <ListProperties parcelsList={parcelsList} />
      {parcelsList?.length
        ? parcelsList.map((i, index) => (
            <Fragment key={`${index}CardListCardFragment`}>
              <Card parcel={i} handleCardClick={handleCardClick} />
            </Fragment>
          ))
        : null}
    </div>
  )
}

export default CardList
