import { INavigationContext, NavigationContext } from "context/navigationContext"
import { ReactComponent as Cross } from "assets/icons/signup/cross.svg"
import styles from "./CrossButton.module.scss"
import { motion } from "framer-motion"
import React from "react"

type Props = {
  onClick?: () => void | (() => Promise<void>)
}

const CrossButton: React.FC<Props> = ({ onClick }) => {
  const { goBack } = React.useContext(NavigationContext) as INavigationContext

  const navigate = () => {
    if (onClick) return onClick()
    goBack(true)
  }

  return (
    <motion.div className={styles.cross} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <Cross onClick={navigate} />
    </motion.div>
  )
}

export default CrossButton
