import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import { ILoggedUser } from "types/api.types"
import getHeaders from "utils/GetHeaders"

export const getLoggedUser = async (): Promise<AxiosResponse<ILoggedUser>> => {
  const headers = getHeaders(false)
  return await axios.get(ENDPOINTS.GET_PROFILE, headers)
}

export default getLoggedUser
