import EditProfileOptionalNotifications from "components/EditProfileOptionalNotification/EditProfileOptionalNotifications"
import EditProfileDivider from "components/EditProfileDivider/EditProfileDivider"
import SpinnerButton from "components/SpinnerButton/SpinnerButton"
import { ReactComponent as Clock } from "assets/icons/clock.svg"
import { ReactComponent as Email } from "assets/icons/email.svg"
import TitleHeader from "components/TitleHeader/TitleHeader"
import Dropdown from "components/Dropdown/Dropdown"
import getLoggedUser from "api/user/getLoggedUser"
import { ILoggedUser } from "types/api.types"
import s from "./Notifications.module.scss"
import patchUser from "api/user/patchUser"
import { UseError } from "hooks"
import React from "react"

type Props = {}

const dropdownConfig = [
  {
    id: 0,
    text: "Hourly",
    active: false,
    value: "HOURLY",
  },
  {
    id: 1,
    text: "Daily",
    active: false,
    value: "DAILY",
  },
  {
    id: 2,
    text: "Weekly",
    active: false,
    value: "WEEKLY",
  },
]

const Notifications = () => {
  const { notifySuccess, notify } = UseError()
  const [updateData, setUpdateData] = React.useState<Partial<ILoggedUser>>({})
  const [patching, setPatching] = React.useState<boolean>(false)
  const [userLoaded, setUserLoaded] = React.useState<boolean>(false)
  const { notification_new_properties } = updateData

  const getUser = async () => {
    try {
      const { data } = await getLoggedUser()
      setUpdateData(data)
      setUserLoaded(true)
    } catch (e) {
      setUserLoaded(true)
      notify("Cannot get information about user")
      console.error("fetch user error")
    }
  }

  const updateProfile = async () => {
    try {
      await patchUser(updateData)
      notifySuccess("Notifications are successfully update")
    } catch (e) {
      setPatching(false)
      notify("Cannot update notifications")
      console.error("error while patching user")
    }
  }

  const onUpdate = (value: Partial<ILoggedUser>) => setUpdateData((prev) => ({ ...prev, ...value }))

  const onSubmit = async () => {
    setPatching(true)
    await updateProfile()
    setPatching(false)
  }

  React.useEffect(() => {
    getUser()
  }, [])

  return userLoaded ? (
    <div className={s.container}>
      <div className={s.wrapper}>
        <TitleHeader title="User Notifications" />
        <EditProfileDivider icon={Clock} text="Scheduled Notifications" />
        <div className={s.scheduledNotification}>
          <h3>New listing matching my saved searches</h3>
          <Dropdown
            keyToValue={"notification_new_properties"}
            initial={notification_new_properties}
            config={dropdownConfig}
            callback={onUpdate}
          />
        </div>
        <div className={s.OptionalNotifications}>
          <EditProfileDivider icon={Email} text="Optional Notifications" />
          <EditProfileOptionalNotifications onUpdate={onUpdate} updateData={updateData} />
        </div>
        <div className={s.buttonWrapper}>
          <SpinnerButton onClick={onSubmit} loading={patching} />
        </div>
      </div>
    </div>
  ) : null
}

export default Notifications
