import { ISearchCriteria, TFilterObject, TSliderRange } from "types/filter.types"
import UseSliderFactory from "hooks/UseSliderFactory"
import UseQuery from "hooks/UseQuery"
import "./SqftFilter.scss"
import React from "react"

type Props = {
  updateCallback: (updatedValue: TFilterObject) => void
  dataObject?: ISearchCriteria
}

const SqftFilter: React.FC<Props> = ({ updateCallback, dataObject }) => {
  const { building_sqft_min = 0, building_sqft_max = 100000 } = dataObject || UseQuery()

  const updateFilters = (updatedValue: TFilterObject) => updateCallback(updatedValue)

  const sliderChangeCallback = (range: TSliderRange) => {
    const [min, max] = [range[0], range[1]]
    updateFilters({ building_sqft_min: min, building_sqft_max: max })
  }

  const { startRef, endRef, containerRef, inputChangeCallback, reset } = UseSliderFactory({
    min: building_sqft_min || 0,
    max: building_sqft_max || 100000,
    minInitial: 0,
    maxInitial: 100000,
    sliderSetCallback: sliderChangeCallback,
    numberFormatting: true,
  })

  return (
    <div className="sqftFilterWrapper">
      <div className="slider-area">
        <div className="slider" ref={containerRef}></div>
      </div>
      <div className="min-max-controls">
        <input
          onChange={inputChangeCallback}
          ref={startRef}
          type="text"
          placeholder="No Min"
          data-id="0"
          id="input-0"
        />
        <span className="divider">-</span>
        <input onChange={inputChangeCallback} ref={endRef} type="text" data-id="1" id="input-1" />
        <h2>SQFT</h2>
      </div>
      <div className="resetButtonWrapper" onClick={reset}>
        <h3>Reset</h3>
      </div>
    </div>
  )
}

export default SqftFilter
