import styles from "./TextInput.module.scss"
import React, { ChangeEvent } from "react"
import clsx from "clsx"

type Props = {
  text: string
  changeCallback?: (e: ChangeEvent<HTMLInputElement>) => void
  validated?: boolean
  maxLength?: number
}

const TextInput: React.FC<Props> = ({ text, maxLength = 32, validated = true, changeCallback }) => {
  return (
    <div
      className={clsx({
        [styles.inputItem]: true,
        [styles.inputItem_active]: !validated,
      })}
    >
      <input required maxLength={maxLength} onChange={changeCallback} />
      <span>{text}</span>
    </div>
  )
}

export default TextInput
