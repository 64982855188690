import { IListingFormData, initialFormData } from "../../types/addListing.types"
import progressBarConfig from "../AddListing/progressBar.config"
import AddListingLayout from "../AddListing/AddListingLayout"
import { IApiParcelByUuid } from "types/api.types"
import UseProgressBar from "hooks/UseProgressBar"
import { AnimatePresence } from "framer-motion"
import config from "./UpdateListing.config"
import React from "react"

type Props = {
  layoutTitle: string
  parcel: IApiParcelByUuid
  updateProperty: (data: IListingFormData) => Promise<IApiParcelByUuid | undefined>
  lastStepButtonCallback: () => void
  closeCallback: () => void
}

const UpdateListing: React.FC<Props> = ({
  layoutTitle,
  parcel,
  updateProperty,
  lastStepButtonCallback,
  closeCallback,
}) => {
  const { formatted_full_address } = parcel
  const { handleNext, handleBack, progressBarList, currentStep } = UseProgressBar({ progressBarConfig })
  const [listingFormData, setListingFormData] = React.useState<IListingFormData>(initialFormData)
  const activeComponent = config[currentStep]

  const updateFormData = (value: Partial<IListingFormData>): void => {
    setListingFormData((prev) => ({ ...prev, ...value }))
  }

  //TODO REMOVE THIS FUNCTION AND TYPE BACK TO IListingFormData WHEN API IS GONNA BE READY
  const formatListingFormData = () => {
    const photos = listingFormData.photos.map((i) => i.uuid).filter((i) => i)
    const formDataCopy = { ...listingFormData, photos } as Partial<IListingFormData>
    delete formDataCopy["documents"]
    return formDataCopy as IListingFormData
  }

  const handleUpdateParcel = async () => {
    const formData = formatListingFormData()
    const updatedParcel = await updateProperty(formData)
    if (updatedParcel) handleNext()
  }

  const updateFormDataSpecificParcel = (data?: IApiParcelByUuid) => {
    const property = data || parcel
    if (property) {
      const formData = Object.keys(property).reduce((prev, curr) => {
        const isExist = Object.keys(listingFormData).find((key) => key === curr)
        return isExist ? { ...prev, [curr]: property[curr as keyof IApiParcelByUuid] } : prev
      }, {})
      updateFormData({ ...formData, documents: [] })
    }
  }

  React.useEffect(() => {
    updateFormDataSpecificParcel()
  }, [])

  return (
    <AddListingLayout
      layoutTitle={layoutTitle}
      layoutSecondLineTitle={formatted_full_address}
      currentStep={currentStep}
      backgroundClickCallback={closeCallback}
      closeButtonCallback={closeCallback}
      progressBarList={progressBarList}
    >
      <AnimatePresence mode="wait">
        <activeComponent.component
          updateFormDataSpecificParcel={updateFormDataSpecificParcel}
          buttonCallback={lastStepButtonCallback}
          formDataUpdateCallback={updateFormData}
          postParcel={handleUpdateParcel}
          handleNext={handleNext}
          handleBack={handleBack}
          listingFormData={listingFormData}
          prefill={false}
          key={currentStep}
        />
      </AnimatePresence>
    </AddListingLayout>
  )
}

export default UpdateListing
