import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"
import axios, { AxiosResponse } from "axios"
import { IApiParcelByUuid } from "types/api.types"

export const getMyBookmarks = async (): Promise<AxiosResponse<IApiParcelByUuid[]>> => {
  const headers = getHeaders(false)
  return await axios.get(ENDPOINTS.MY_BOOKMARKS, headers)
}

export default getMyBookmarks
