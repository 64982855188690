import styles from "./PropertyPageSimmilarProperties.module.scss"
import SimmilarProperties from "components/SimmilarProperties/SimmilarProperties"
import React from "react"
import { IApiParcelByUuid } from "types/api.types"

type Props = {
  parcel: IApiParcelByUuid
}

const PropertyPageSimmilarProperties: React.FC<Props> = ({ parcel }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Similar Properties</h2>
      </div>
      <SimmilarProperties parcel={parcel} />
    </div>
  )
}

export default PropertyPageSimmilarProperties
