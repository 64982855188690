import styles from "./SpinnerButton.module.scss"
import React from "react"
import clsx from "clsx"

type Props = {
  onClick: () => void
  loading: boolean
}

const SpinnerButton: React.FC<Props> = ({ onClick, loading }) => {
  return (
    <button
      className={clsx({
        [styles.button]: true,
        [styles.loading]: loading,
      })}
      onClick={onClick}
    >
      {!loading ? "Save" : <span className={styles.spinner} />}
    </button>
  )
}

export default SpinnerButton
