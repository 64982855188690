import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"
import UserCircleDropDown from "components/UserCircleDropDown/UserCircleDropDown"
import ConfirmationLayout from "components/ConfirmationLayout/ConfirmationLayout"
import { FC, Fragment, useContext, useEffect, useMemo, useRef, useState } from "react"
import { authentificatedSelector } from "redux/reducers/app.reducer"
import { AnimatePresence, motion } from "framer-motion"
import styles from "./HeaderUserCircle.module.scss"
import getLoggedUser from "api/user/getLoggedUser"
import UseOutsideCick from "hooks/UseOutsideCick"
import { useNavigate } from "react-router-dom"
import { ILoggedUser } from "types/api.types"
import { useSelector } from "react-redux"
import { UseError } from "hooks"

type Props = {}

const HeaderUserCircle: FC<Props> = () => {
  const navigate = useNavigate()
  const { notifySuccess } = UseError()
  const { credentialsRemove } = useContext(CredentialsContext) as ICredentialsContext

  const [popupOpened, setPopupOpened] = useState<boolean>(false)
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false)
  const [user, setUser] = useState<ILoggedUser | null>(null)

  const authentificated = useSelector(authentificatedSelector)
  const circleContainerRef = useRef<HTMLDivElement>(null)
  const componentRef = UseOutsideCick({ setter: setDropdownOpened, exclusions: [circleContainerRef] })

  const signUpOnClick = () => navigate("/login")

  const getUser = async () => {
    try {
      const { data } = await getLoggedUser()
      setUser(data)
    } catch (e) {
      console.error("fetch user error")
    }
  }

  const logoutUser = async () => {
    notifySuccess("You have been logged out!")
    credentialsRemove()
    setPopupOpened(false)
    setUser(null)
  }

  const circleOnClick = () => setDropdownOpened(!dropdownOpened)

  const openLogoutConfirmation = () => setPopupOpened(true)

  const closeLogoutConfirmation = () => setPopupOpened(false)

  useEffect(() => {
    getUser()
  }, [authentificated])

  const renderCircle = useMemo(() => {
    if (!user) return null
    const { avatar_url, first_name = "", last_name = "" } = user
    if (avatar_url)
      return <motion.img src={avatar_url} alt="avatar" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} />
    if (first_name && last_name)
      return (
        <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>{`${(
          first_name[0] + last_name[0]
        ).toUpperCase()}`}</motion.h2>
      )
    return <h2>ME</h2>
  }, [user])

  const renderConfirmationContent = (
    <Fragment>
      <h2>Are you sure that you </h2>
      <h2>want to log out?</h2>
    </Fragment>
  )

  const renderSignUpButton = (
    <button onClick={signUpOnClick}>
      <h2>Sign in</h2>
    </button>
  )

  const renderUserCircle = (
    <Fragment>
      <div className={styles.container} onClick={circleOnClick} ref={circleContainerRef}>
        {renderCircle}
        <AnimatePresence>
          {dropdownOpened ? (
            <UserCircleDropDown
              key={"userCircelDropDown"}
              logoutOnClick={openLogoutConfirmation}
              componentRef={componentRef}
            />
          ) : null}
        </AnimatePresence>
      </div>
      <AnimatePresence mode="wait">
        {popupOpened ? (
          <ConfirmationLayout cancelCallback={closeLogoutConfirmation} confirmCallback={logoutUser}>
            {renderConfirmationContent}
          </ConfirmationLayout>
        ) : null}
      </AnimatePresence>
    </Fragment>
  )

  const userCircle = authentificated ? renderUserCircle : renderSignUpButton

  return userCircle
}

export default HeaderUserCircle
