import CreateAccount from "components/CreateAccount/CreateAccount"
import PropertyPage from "components/PropertyPage/PropertyPage"
import { Route, Routes, useLocation } from "react-router-dom"
import AddListing from "components/AddListing/AddListing"
import { ROUTES } from "./constants/router.constants"
import { AnimatePresence } from "framer-motion"
import SignUp from "components/SignUp/SignUp"
import Login from "components/Login/Login"
import Home from "./components/Home/Home"
import React from "react"
import { LinkedInCallback } from "react-linkedin-login-oauth2"
import SavedSearches from "components/SavedSearches/SavedSearches"
import ResetPassword from "components/ResetPassword/ResetPassword"
import MyProperties from "components/MyProperties/MyProperties"
import EditAccount from "components/MyProfile/MyProfile"
import ChangeProfile from "components/EditProfile/EditProfile"
import WatchList from "components/WatchList/WatchList"
import Notifications from "components/Notifications/Notifications"
import TermsOfServicePage from "components/TermsOfServicePage/TermsOfServicePage"
import PrivacyPolicyPage from "components/PrivacyPolicyPage/PrivacyPolicyPage"

type Props = {}

const RoutesComponent: React.FC<Props> = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Home key="home">
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.key}>
                <Route path={ROUTES.ADD_LISTING} element={<AddListing key="addListing" />} />
                <Route path={ROUTES.CREATE_ACCOUNT} element={<CreateAccount key="accountCreate" />} />
                <Route path={ROUTES.LOGIN} element={<Login key="login" />} />
                <Route path={ROUTES.SIGN_UP} element={<SignUp key="signUp" />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword key="resetPassword" />} />
                <Route path={ROUTES.MY_PROPERTIES} element={<MyProperties key="myProperties" />} />
                <Route path={ROUTES.SAVED_SEARCHES} element={<SavedSearches key="savedSearches" />} />
                <Route path={ROUTES.LISTED_PROPERTY_PAGE} element={<PropertyPage listed key="propertyPageListed" />} />
                <Route path={ROUTES.UNLISTED_PROPERTY_PAGE} element={<PropertyPage key="propertyPageUnslisted" />} />
                <Route path={ROUTES.WATCH_LIST} element={<WatchList key="watchList" />} />
              </Routes>
            </AnimatePresence>
          </Home>
        }
      />
      <Route path={ROUTES.EDIT_ACCOUNT_DETAILS} element={<ChangeProfile key="changeProfile" />} />
      <Route path={ROUTES.ACCOUNT_DETAILS} element={<EditAccount key="editAccount" />}></Route>
      <Route path={ROUTES.ACCOUNT_NOTIFICATIONS} element={<Notifications key="notifications" />}></Route>
      <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfServicePage key="tos" />}></Route>
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage key="privacy" />}></Route>
      <Route path="/linkedin" element={<LinkedInCallback />}></Route>
    </Routes>
  )
}
export default RoutesComponent
