import MobileTabsSelector from "../MobileTabsSelector/MobileTabsSelector"
import { ParcelsRequestsContext } from "context/parcelRequestsContext"
import MobileBottomMenu from "../MobileBottomMenu/MobileBottomMenu"
import { FC, useContext, useEffect, useState } from "react"
import { ROUTES } from "constants/router.constants"
import { TActiveTab } from "types/components.types"
import Properties from "../Properties/Properties"
import { useLocation } from "react-router-dom"
import MapList from "../MapList/MapList"
import styles from "./Home.module.scss"
import Header from "../Header/Header"

type Props = {
  children?: JSX.Element | JSX.Element[]
}

const Home: FC<Props> = ({ children }) => {
  const { parcelRequest } = useContext(ParcelsRequestsContext)
  const { search, pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<TActiveTab>("map")
  const [filtersOpened, setFiltersOpened] = useState(false)

  const isHome = pathname === ROUTES.HOME

  const tabChange = (type: TActiveTab) => setActiveTab(type)

  useEffect(() => {
    if (isHome) parcelRequest()
  }, [search])

  useEffect(() => {
    if (!isHome) parcelRequest()
  }, [])

  return (
    <div className={styles.container}>
      <Header />
      <Properties filtersOpened={filtersOpened} setFiltersOpened={setFiltersOpened} />
      <MobileTabsSelector tabChangeCallback={tabChange} />
      <MapList activeTab={activeTab} setFiltersOpened={setFiltersOpened} />
      <MobileBottomMenu />
      {children}
    </div>
  )
}

export default Home
