import { ReactComponent as AddPhoto } from "assets/icons/propertyPage/addPhoto.svg"
import postPropertyPhoto from "api/parcels/OffMarket/postPropertyPhoto"
import styles from "./PropertiesAddPhotoCard.module.scss"
import { TInputClickEvent } from "types/components.types"
import { IApiParcelByUuid } from "types/api.types"
import React, { ChangeEvent } from "react"
import { UseError } from "hooks"
import clsx from "clsx"

type Props = { parcel: IApiParcelByUuid }

const messages = {
  posted: "Uploaded successfully and your photo is waiting for review",
  errored: "We issued some technical problem uploading your photo, please try later",
  sizeError: "Maximum file size is 10mb",
}

const PropertiesAddPhotoCard: React.FC<Props> = ({ parcel }) => {
  const { id } = parcel
  const { notify, notifySuccess } = UseError()

  const [posting, setPosting] = React.useState(false)

  const postPhoto = async (file: File) => {
    setPosting(true)
    try {
      await postPropertyPhoto({ file, property: id })
      notifySuccess(messages.posted)
      setPosting(false)
    } catch (err) {
      notify(messages.errored)
      setPosting(false)
    }
  }

  const validateSize = (size: number) => {
    const maxSize = 10
    const sizeMB = size / 1000000
    return sizeMB < maxSize
  }

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const photo = files?.[0]
    if (!photo) return
    const { size } = photo
    const validated = validateSize(size)
    if (validated) return await postPhoto(photo)
    notify(messages.sizeError)
  }

  const onClick = (e: TInputClickEvent) => (e.target.value = "")

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [styles.container_posting]: posting,
      })}
    >
      <input type="file" onClick={onClick} accept="image/png, image/jpeg, image/jpg, image/heic" onChange={onChange} />
      <AddPhoto />
      <h2>Add a photo</h2>
    </div>
  )
}

export default PropertiesAddPhotoCard
