const CONSTANTS = {
  mapboxKey: `${process.env.REACT_APP_MAPBOX_PRIVATE_KEY}`,
  googleClientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  facebookAppId: `${process.env.REACT_APP_FACEBOOK_APP_ID}`,
  linkedInClientId: `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`,
  pittsburgViewState:{
    longitude: -80.00336715298778,
    latitude: 40.222658317741804,
    zoom: 9.082152494800845,
  },
  pittsburgViewStateFullScreen: {
    longitude: -79.9932274157826,
    latitude: 40.426758763902086,
    zoom: 9.246883678724556,
  },
}


export default CONSTANTS
