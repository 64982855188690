import SignUpSocialButtons from "components/SignUpSocialButtons/SignUpSocialButtons"
import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import PopupBackground from "components/PopupBackground/PopupBackground"
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy"
import SignUpDivider from "components/SignUpDivider/SignUpDivider"
import CrossButton from "components/CrossButton/CrossButton"
import { slideInBottom } from "constants/animationContants"
import BigButton from "components/BigButton/BigButton"
import React, { useEffect, useState } from "react"
import styles from "./CreateAccount.module.scss"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

type Props = {}

const CreateAccount: React.FC<Props> = () => {
  const [policyAccepted, setPolicyAccepted] = useState(false)
  const [validated, setValidated] = useState(true)

  const navigate = useNavigate()

  const validate = () => {
    if (!policyAccepted) setValidated(false)
    if (policyAccepted) setValidated(true)
    return policyAccepted
  }

  const handleSignUpButton = () => {
    if (validate()) navigate("/sign-up")
  }

  const onLogin = () => navigate("/login")

  useEffect(() => {
    if (policyAccepted) setValidated(true)
  }, [policyAccepted])

  return (
    <div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.closePopupButton}>
          <CrossButton />
        </div>
        <div className={styles.title}>
          <h2>Create an account</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>
        <PrivacyPolicy setPolicyAccepted={setPolicyAccepted} validated={validated} evented />
        <div className={styles.bigButtonContainer} onClick={handleSignUpButton}>
          <BigButton text="Sign Up" />
        </div>
        <div className={styles.dividerWrapper}>
          <SignUpDivider text="OR SIGN IN WITH SOCIAL" />
        </div>
        <SignUpSocialButtons validate={validate} />
        <div className={styles.haveAnAccount}>
          <h2>
            Already have an account? <span onClick={onLogin}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </div>
  )
}

export default CreateAccount
