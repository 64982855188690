import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const deleteUser = async (id: number) => {
  const headers = getHeaders(false)
  return await axios.delete(`${ENDPOINTS.DELETE_USER}${id}/`, headers)
}

export default deleteUser
