import { ISearchCriteria, TFilterObject, TSliderRange } from "types/filter.types"
import UseSliderFactory from "hooks/UseSliderFactory"
import { currentYear } from "utils/currentYear"
import UseQuery from "hooks/UseQuery"
import "./YearBuiltFilter.scss"
import React from "react"

type Props = {
  updateCallback?: (updatedValue: TFilterObject) => void
  dataObject?: ISearchCriteria
}

const YearBuiltFilter: React.FC<Props> = ({ updateCallback, dataObject }) => {
  const { year_built_min = 1900, year_built_max = currentYear } = dataObject || UseQuery()

  const updateFilters = (updatedValue: TFilterObject) => {
    if (updateCallback) updateCallback(updatedValue)
  }

  const sliderChangeCallback = (range: TSliderRange) => {
    const [min, max] = [range[0], range[1]]
    updateFilters({ year_built_min: min, year_built_max: max })
  }

  const { startRef, endRef, containerRef, inputChangeCallback, reset } = UseSliderFactory({
    min: year_built_min || 1900,
    max: year_built_max || currentYear,
    minInitial: 1900,
    maxInitial: currentYear,
    sliderSetCallback: sliderChangeCallback,
  })

  return (
    <div className="yearBuiltFilterWrapper">
      <div className="slider-area">
        <div className="slider" ref={containerRef}></div>
      </div>
      <div className="min-max-controls">
        <input
          onChange={inputChangeCallback}
          ref={startRef}
          type="text"
          placeholder="No Min"
          data-id="0"
          id="input-0"
        />
        <span className="divider">-</span>
        <input onChange={inputChangeCallback} ref={endRef} type="text" data-id="1" id="input-1" />
      </div>

      <div className="resetButtonWrapper" onClick={reset}>
        <h3>Reset</h3>
      </div>
    </div>
  )
}

export default YearBuiltFilter
