import EditProfileContactInformation from "components/EditProfileContactInformation/EditProfileContactInformation"
import EditProfileDivider from "components/EditProfileDivider/EditProfileDivider"
import { ReactComponent as Settings } from "assets/icons/settings.svg"
import SpinnerButton from "components/SpinnerButton/SpinnerButton"
import RemoveAccount from "components/RemoveAccount/RemoveAccount"
import TitleHeader from "components/TitleHeader/TitleHeader"
import getLoggedUser from "api/user/getLoggedUser"
import styles from "./EditProfile.module.scss"
import { useNavigate } from "react-router-dom"
import { ILoggedUser } from "types/api.types"
import patchUser from "api/user/patchUser"
import React from "react"

type Props = {}

const ChangeProfile: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [updateData, setUpdateData] = React.useState<Partial<ILoggedUser>>({})
  const [patching, setPatching] = React.useState<boolean>(false)
  const [userLoaded, setUserLoaded] = React.useState<boolean>(false)
  const [validationList, setValidationList] = React.useState({ name: true, surname: true, email: true, phone: true })
  const { id = 0, first_name = "", last_name = "", email_address = "", phone_number = "" } = updateData
  const onUpdate = (value: Partial<ILoggedUser>) => setUpdateData((prev) => ({ ...prev, ...value }))

  const getUser = async () => {
    try {
      const { data } = await getLoggedUser()
      setUpdateData(data)
      setUserLoaded(true)
    } catch (e) {
      setUserLoaded(true)
      console.error("fetch user error")
    }
  }

  const updateProfile = async () => {
    try {
      await patchUser(updateData)
      navigate("/me")
    } catch (e) {
      setPatching(false)
      console.error("error while patching user")
    }
  }

  const onSubmit = async () => {
    const isValidated = validate()
    if (isValidated) {
      setPatching(true)
      await updateProfile()
    }
  }

  const validateEmail = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email_address) && email_address.length > 0
  }

  const validate = () => {
    const name = first_name.length > 0
    const surname = last_name.length > 0
    const phone = String(phone_number).length === 10
    const email = validateEmail()

    setValidationList({ name, surname, phone, email })
    return name && surname && phone && email
  }

  React.useEffect(() => {
    getUser()
  }, [])
  return userLoaded ? (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TitleHeader title="Edit Profile" redirectPath="/me" />
        <EditProfileDivider icon={Settings} text="Contact Information" />
        <div className={styles.information}>
          <EditProfileContactInformation onUpdate={onUpdate} updateData={updateData} validationList={validationList} />
        </div>
        <div className={styles.buttonWrapper}>
          <SpinnerButton onClick={onSubmit} loading={patching} />
        </div>
        <div className={styles.removeAccountWrapper}>
          <RemoveAccount id={id} />
        </div>
      </div>
    </div>
  ) : null
}

export default ChangeProfile
