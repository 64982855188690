import { ReactComponent as UpdateCircle } from "assets/icons/signup/updateCircle.svg"
import styles from "./UpdateListingSucessfull.module.scss"
import BigButton from "components/BigButton/BigButton"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import React from "react"
import { fadeIn } from "constants/animationContants"

type Props = {
  buttonCallback: () => void
}

const UpdateListingSuccessfull: React.FC<Props> = ({ buttonCallback }) => {
  const navigate = useNavigate()

  const redirectToMap = () => {
    navigate("/")
  }

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <div className={styles.title}>
        <h2>Congratulations! 🎉</h2>
      </div>
      <div className={styles.emailCircleWrapper}>
        <UpdateCircle />
      </div>
      <div className={styles.titleSmall}>
        <h2>You have successfully</h2>
        <h2>updated your listing!</h2>
      </div>
      <div className={styles.bigButtonContainer}>
        <BigButton text="View Updated Listing" clickCallback={buttonCallback} />
      </div>
      <div className={styles.haveAnAccount}>
        <h2>
          View it at the map! <span onClick={redirectToMap}>Back to map</span>
        </h2>
      </div>
    </motion.div>
  )
}

export default UpdateListingSuccessfull
