import { MapboxStyleSwitcherControl } from "components/MapViewer/SwitchViewControl/SwitchViewControl"
import { ReactComponent as Bubble } from "assets/icons/pins/bubblePinBase.svg"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import Map, { MapRef, Marker } from "react-map-gl"
import styles from "./PropertyPageMap.module.scss"
import { formatCash } from "utils/FormatPrice"
import { IApiParcel } from "types/api.types"
import CONSTANTS from "constants/constants"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import clsx from "clsx"

type Props = {
  property: IApiParcel
}

const PropertyPageMap: React.FC<Props> = ({ property }) => {
  const { listing_type } = property
  const mapRef = React.useRef<MapRef | null>(null)

  const _onLoad = () => {
    const map = mapRef.current as MapRef
    addControls(map)
  }

  const addControls = (map: MapRef): void => {
    map.addControl(new MapboxStyleSwitcherControl())
  }

  const renderEmptyBubbleMarker = () => {
    return (
      <div
        className={clsx({
          [styles.bubbleContainer]: true,
          [styles.bubbleContainer_sale]: true,
          [styles.bubbleContainer_sold]: !listing_type,
          [styles.bubbleContainer_lease]: listing_type === "FOR_LEASE",
        })}
      >
        <Bubble />
        <div className={styles.textContainer}>
          <h2>N/A</h2>
        </div>
      </div>
    )
  }

  const renderBubbleMarker = (price: number | string | undefined) => {
    const priceString = String(price)
    if (!price || !priceString?.length) return renderEmptyBubbleMarker()
    const formattedPrice = priceString.length > 4 ? formatCash(priceString) : formatPriceNoDecimal(priceString)
    return (
      <div
        className={clsx({
          [styles.bubbleContainer]: true,
          [styles.bubbleContainer_sale]: true,
          [styles.bubbleContainer_sold]: !listing_type,
          [styles.bubbleContainer_lease]: listing_type === "FOR_LEASE",
        })}
      >
        <Bubble />
        <div className={styles.textContainer}>{<h2>${formattedPrice}</h2>}</div>
      </div>
    )
  }

  return (
    <Map
      ref={mapRef}
      doubleClickZoom={false}
      mapboxAccessToken={CONSTANTS.mapboxKey}
      initialViewState={{
        longitude: property?.coordinates.longitude,
        latitude: property?.coordinates.latitude,
        zoom: 16,
      }}
      onLoad={_onLoad}
      style={{ width: "100%", height: "50%", marginTop: "1.625rem" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      <Marker longitude={property?.coordinates.longitude} latitude={property?.coordinates.latitude} anchor="bottom">
        {renderBubbleMarker(property?.price || property?.most_recent_sales_price)}
      </Marker>
    </Map>
  )
}

export default PropertyPageMap
