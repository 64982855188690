import styles from "./BackButton.module.scss"
import React from "react"

type Props = {
  clickCallback?: () => void
}

const BackButton: React.FC<Props> = ({ clickCallback }) => {
  return (
    <div className={styles.backButton} onClick={clickCallback}>
      <h2>Back</h2>
    </div>
  )
}

export default BackButton
