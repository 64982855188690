import { ReactComponent as Reload } from "assets/icons/addListing/reload.svg"
import { ReactComponent as Cancel } from "assets/icons/addListing/cancel.svg"
import { ReactComponent as Image } from "assets/icons/addListing/image.svg"
import styles from "./AddListingFileUploadProgressBar.module.scss"
import { postPhoto } from "api/parcels/UserListing/postPhoto"
import React, { Dispatch, SetStateAction } from "react"
import { IFileProgress } from "types/addListing.types"
import clsx from "clsx"

type TStatus = "loading" | "fullfiled" | "rejected"

type Props = {
  uploadedFile: IFileProgress
  type: "photo" | "document"
  fileList: IFileProgress[]
  updateFileList: Dispatch<SetStateAction<IFileProgress[]>>
  removeButtonCallback: (id: string) => void
}

const AddListingFileUploadProgressBar: React.FC<Props> = ({
  uploadedFile,
  type,
  fileList,
  updateFileList,
  removeButtonCallback,
}) => {
  const { id, filename, size, file, url } = uploadedFile
  const [status, setStatus] = React.useState<TStatus>("loading")
  const [progress, setProgress] = React.useState<number>(0)
  const [errorMessage, setErrorMessage] = React.useState("")

  const preview = file ? URL.createObjectURL(file) : url

  const onLoad = async () => {
    const message = validate()
    if (message) {
      setStatus("rejected")
      setErrorMessage(message)
      setProgress(100)
      return
    }
    await sendPhoto()
  }

  const sendPhoto = async () => {
    setStatus("loading")
    try {
      const response = await postPhoto(file, onUploadProgress)
      const { data } = response
      const { uuid } = data
      const url = data.file
      setStatus("fullfiled")
      setErrorMessage("")
      updateFileList((prev) => prev.map((i) => (i.id === id ? { ...i, uuid, url } : i)))
    } catch (err) {
      setStatus("rejected")
      setErrorMessage("non expected error")
      console.error(err)
    }
  }

  const retrySendPhoto = async () => await onLoad()

  const validate = () => {
    const isPhoto = type === "photo"
    const maxSize = isPhoto ? 10 : Infinity
    const maxAmount = isPhoto ? 20 : 10
    const sizeMB = size / 1000000
    if (fileList.length + 1 > maxAmount) return `you can add maximum ${maxAmount} ${type}'s`
    if (sizeMB > maxSize) return "file is too big"
  }

  const onUploadProgress = (e: ProgressEvent) => {
    const { loaded, total } = e
    const percentage = Math.round(loaded / (total / 100))
    setProgress(percentage)
  }

  const splitByIndex = (str: string, index: number) => {
    const result = [str.slice(0, index), str.slice(index)]
    return result
  }

  const parseName = () => {
    const splitted = splitByIndex(filename, filename.lastIndexOf("."))
    return [splitted[0].slice(0, 18), "...", splitted[1]].join("")
  }

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  React.useEffect(() => {
    if (uploadedFile.uuid) {
      setStatus("fullfiled")
      return setProgress(100)
    }
    onLoad()
  }, [])

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBarWrapper}>
        <div className={styles.leftContent}>
          <div className={styles.placeholder}>
            <img src={preview} alt="thumbnail" />
          </div>
          <div className={styles.fileInformation}>
            <h2>{parseName()}</h2>
            <div className={styles.fileStatus}>
              <h3>Size: {formatBytes(size)}</h3>
              {status === "rejected" ? <h4>{errorMessage}</h4> : null}
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          {status === "rejected" ? <Reload onClick={retrySendPhoto} /> : null}
          <Cancel onClick={() => removeButtonCallback(id)} />
        </div>
        <div
          className={clsx({
            [styles.background]: true,
            [styles.background_fullfiled]: status === "fullfiled",
            [styles.background_rejected]: status === "rejected",
          })}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  )
}

export default AddListingFileUploadProgressBar
