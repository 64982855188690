import { motion } from "framer-motion"
import s from "./SmallButton.module.scss"
import { FC } from "react"

type Props = {
  onClick: () => void
  text: string
}

const SmallButton: FC<Props> = ({ text, onClick }) => {
  return (
    <motion.div className={s.saveSearchButton} onClick={onClick} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <h2>{text}</h2>
    </motion.div>
  )
}

export default SmallButton
