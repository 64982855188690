import { IControl, Map as MapboxMap } from "mapbox-gl";
import globeSvg from "assets/icons/mapIcons/globesvg";
import streetsSvg from "assets/icons/mapIcons/streetssvg";

export type MapboxStyleDefinition = {
  title: string;
  uri: string;
};

type MapboxStyleSwitcherOptions = {
  defaultStyle?: string;
  eventListeners?: MapboxStyleSwitcherEvents;
};

type MapboxStyleSwitcherEvents = {
  onOpen?: (event: MouseEvent) => boolean;
  onSelect?: (event: MouseEvent) => boolean;
  onChange?: (event: MouseEvent, style: string) => boolean;
};

export class MapboxStyleSwitcherControl implements IControl {
  public readonly DEFAULT_STYLE = "Streets";
  public readonly DEFAULT_STYLES = [
    {
      title: "Satellite",
      uri: "mapbox://styles/mapbox/satellite-streets-v11",
      icon: streetsSvg,
    },
    {
      title: "Streets",
      uri: "mapbox://styles/mapbox/streets-v11",
      icon: globeSvg,
    },
  ];

  private controlContainer: HTMLElement | undefined;
  private events?: MapboxStyleSwitcherEvents;
  private map?: MapboxMap;
  private controlState: "satellite" | "streets" = "streets";
  private mapStyleContainer: HTMLElement | undefined;
  private styleButton: HTMLButtonElement | undefined;
  private styles: MapboxStyleDefinition[];
  private defaultStyle: string;

  constructor(
    styles?: MapboxStyleDefinition[],
    options?: MapboxStyleSwitcherOptions | string
  ) {
    this.styles = styles || this.DEFAULT_STYLES;
    const defaultStyle =
      typeof options === "string"
        ? options
        : options
        ? options.defaultStyle
        : undefined;
    this.defaultStyle = defaultStyle || this.DEFAULT_STYLE;
    this.events =
      typeof options !== "string" && options
        ? options.eventListeners
        : undefined;
  }

  public onAdd(map: MapboxMap): HTMLElement {
    this.map = map;
    this.controlContainer = document.createElement("div");
    this.controlContainer.classList.add("mapbox-switch-view-control-container");
    this.controlContainer.innerHTML = globeSvg;

    this.controlContainer.addEventListener("click", (event) => {
      if (this.events && this.events.onSelect && this.events.onSelect(event)) {
        return;
      }
      this.changeViewStyle();
    });

    return this.controlContainer;
  }

  public onRemove(): void {
    if (
      !this.controlContainer ||
      !this.controlContainer.parentNode ||
      !this.map ||
      !this.styleButton
    ) {
      return;
    }
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    this.map = undefined;
  }

  private changeViewStyle(): void {
    let style = this.DEFAULT_STYLES[1];

    if (this.controlContainer) {
      if (this.controlState === "streets") style = this.DEFAULT_STYLES[0];

      if (style?.uri) {
        this.controlContainer.innerHTML = style.icon;

        this.map?.setStyle(style?.uri);

        this.controlState = style.title.toLowerCase() as
          | "satellite"
          | "streets";
      }
    }
  }
}
