const API_URL = [
  "http://localhost:3000",
  "http://localhost:8000",
  "https://localhost:3000",
  "https://localhost:8000",
].includes(new URL(document.location.href).origin.toString())
  ? `${process.env.REACT_APP_DEVELOPMENT_URL}`
  : `${process.env.REACT_APP_PRODUCTION_URL}`
const ENDPOINTS = {
  BASE_URL: API_URL,
  GET_ALL_PARCELS: `${API_URL}api/properties/?no_page=1`,
  GET_PARCELS_PAGINATION: `${API_URL}api/properties/`,
  GET_PARCELS_IN_VIEW: `${API_URL}api/properties/?no_page=1&in_bbox=`,
  GET_ALL_USER_LISTINGS: `${API_URL}api/user-listings/?no_page=1`,
  GET_USER_LISTINGS_PAGINATION: `${API_URL}api/user-listings/`,
  GET_USER_LISTINGS_IN_VIEW: `${API_URL}api/user-listings/?no_page=1&in_bbox=`,
  GET_PROPERTIES_IN_VIEW: `${API_URL}api/properties/?no_page=1&in_bbox=`,
  GET_USER_PROPERTIES: `${API_URL}api/my-properties/`,
  GET_MAP_SEARCH_RESULTS: `${API_URL}api/properties/search-for-map/`,
  SEARCH_CRITERIA: `${API_URL}api/search-criteria/`,
  GET_PROFILE: `${API_URL}api/profile/me/`,
  PROFILE: `${API_URL}api/profile/`,
  PUBLIC_PROFILE: `${API_URL}api/public-profile/`,
  POST_USER_LISTING_PHOTO: `${API_URL}api/photos/`,
  POST_PROPERTY_PHOTO: `${API_URL}api/property-photos/`,
  RESET_PASSWORD: `${API_URL}api/auth/password/reset/`,
  RESET_PASSWORD_CONFIRM: `${API_URL}api/auth/password/reset/confirm/`,
  REGISTER_USER: `${API_URL}api/auth/registration/`,
  LOGIN_USER: `${API_URL}api/auth/login/`,
  FACEBOOK_LOGIN: `${API_URL}api/social-auth/facebook/`,
  GOOGLE_LOGIN: `${API_URL}api/social-auth/google/`,
  LINKEDIN_LOGIN: `${API_URL}api/social-auth/linkedin/`,
  DELETE_USER: `${API_URL}api/users/`,
  MY_BOOKMARKS: `${API_URL}api/my-bookmarks/`,
}
export default ENDPOINTS
