import { IGetParcelsInViewProps, TParcelsGeojsonResponse } from "types/api.types"
import ENDPOINTS from "constants/api.constants"
import axios, { CancelToken } from "axios"
import getHeaders from "utils/GetHeaders"

export const getPolygonsGeojsonParcels = async (
  boundingBox: IGetParcelsInViewProps,
  query: string,
  cancelToken?: CancelToken
) => {
  try {
    const { x1, y1, x2, y2 } = boundingBox
    const headers = { ...getHeaders(), cancelToken }
    const parcels = await axios.get(
      `${ENDPOINTS.GET_PARCELS_IN_VIEW}${x1},${y1},${x2},${y2}&geojson=1&${query}`,
      headers
    )
    return parcels.data as TParcelsGeojsonResponse
  } catch (err) {
    console.error(err)
  }
}
