import styles from "./SearchCriteriaCard.module.scss"
import { ReactComponent as Search } from "assets/icons/search.svg"
import { ReactComponent as Address } from "assets/icons/address.svg"
import { ISearchCriteria } from "types/filter.types"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import React from "react"
import qs from "qs"
import DeleteButton from "components/DeleteButton/DeleteButton"
import EditButton from "components/EditButton/EditButton"

type Props = {
  searchCriteria: ISearchCriteria
  remove: (criteria: ISearchCriteria) => void
  edit: (criteria: ISearchCriteria) => void
}

const SearchCriteriaCard: React.FC<Props> = ({ searchCriteria, remove, edit }) => {
  const { location_query, name } = searchCriteria
  const navigate = useNavigate()

  const filterCriteria = () => {
    const removeKeysList = ["user", "uuid", "updated_at", "created_at", "location_query"]
    return Object.keys(searchCriteria).reduce<Partial<ISearchCriteria>>((p, c) => {
      const k = c as keyof ISearchCriteria
      return !removeKeysList.includes(k) && searchCriteria[k] ? { ...p, [c]: searchCriteria[k] } : p
    }, {})
  }

  const applySearch = () => {
    const filtered = filterCriteria()
    const search = qs.stringify(filtered, { arrayFormat: "repeat", encode: false })
    navigate(`/?${search}`)
  }

  return (
    <motion.div className={styles.container}>
      <div className={styles.cardHeader}>
        <div className={styles.cardIcon}>
          <Search />
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyHeader}>
          <div className={styles.toolsContainer}>
            <DeleteButton onClick={() => remove(searchCriteria)} />
            <EditButton onClick={() => edit(searchCriteria)} />
          </div>
        </div>
        <div className={styles.cardBodyContent}>
          <h2>{name}</h2>
          <div className={styles.address}>
            <Address />
            <h2>{location_query}</h2>
          </div>
        </div>
        <div className={styles.cardBodyFooter}>
          <motion.div
            className={styles.viewProperties}
            onClick={applySearch}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
          >
            <h2>View Properties</h2>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default SearchCriteriaCard
