import { createAction, createAsyncThunk, createReducer, createSelector, isAnyOf } from "@reduxjs/toolkit"
import { getParcelsPaginataionOffMarket } from "api/parcels/OffMarket/getParcelsPaginationOffmarket"
import { getParcelsPaginataion, getParcelsInView, getPolygonsGeojsonParcels } from "api/parcels"
import { IApiParcel, IGetParcelsInViewProps, TParcelsGeojsonResponse } from "types/api.types"
import { getPropertiesInView } from "api/parcels/UserListing/getPropertiesInView"
import axios from "axios"

interface IAppState {
  authentificated: boolean
  mapLoading: boolean
  parcels: IApiParcel[] | null
  parcelsInView: IApiParcel[] | null
  parcelsPolygonsGeojsonInView: TParcelsGeojsonResponse | undefined
}

const initialState: IAppState = {
  authentificated: false,
  mapLoading: true,
  parcels: null,
  parcelsInView: null,
  parcelsPolygonsGeojsonInView: undefined,
}

const setAbortListener = (signal: AbortSignal) => {
  const source = axios.CancelToken.source()
  signal.onabort = () => source.cancel()
  const cancelToken = source.token
  return cancelToken
}

export const $actions = {
  setMapLoading: createAction("app/mapLoading", (key: boolean) => {
    return { payload: key }
  }),
  setAuthentificated: createAction("app/setAuthentificated", (key: boolean) => {
    return { payload: key }
  }),
  fetchParcels: createAsyncThunk(
    "app/fetchParcels",
    async ({ query = "", offmarket }: { query: string; offmarket: boolean }, { signal }) => {
      const cancelToken = setAbortListener(signal)
      try {
        if (offmarket) return await getParcelsPaginataionOffMarket(query, cancelToken)
        return await getParcelsPaginataion(query, cancelToken)
      } catch (e) {
        throw new Error("fetch parcels call error")
      }
    }
  ),
  fetchParcelsInView: createAsyncThunk(
    "app/fetchParcelsInView",
    async (
      {
        boundingBox,
        query = "",
        offmarket,
      }: {
        boundingBox: IGetParcelsInViewProps
        query: string
        offmarket: boolean
      },
      { signal }
    ) => {
      const cancelToken = setAbortListener(signal)
      try {
        if (offmarket) return await getPropertiesInView(boundingBox, query, cancelToken)
        return await getParcelsInView(boundingBox, query, cancelToken)
      } catch (e) {
        throw new Error("fetch parcels in view call error")
      }
    }
  ),
  fetchPolygonsGeojsonInView: createAsyncThunk(
    "app/fetchPolygonsGeojsonInView",
    async ({ boundingBox, query = "" }: { boundingBox: IGetParcelsInViewProps; query: string }, { signal }) => {
      const cancelToken = setAbortListener(signal)
      try {
        return await getPolygonsGeojsonParcels(boundingBox, query, cancelToken)
      } catch (e) {
        throw new Error("fetch polygons geojson in view call error")
      }
    }
  ),
}

const $reducer = createReducer(initialState, (builder) => {
  builder.addCase($actions.setMapLoading, (state, { payload }) => {
    state.mapLoading = payload
  })

  builder.addCase($actions.setAuthentificated, (state, { payload }) => {
    state.authentificated = payload
  })

  builder.addCase($actions.fetchParcels.fulfilled, (state, { payload }) => {
    state.parcels = payload
    state.mapLoading = false
  })

  builder.addCase($actions.fetchParcelsInView.fulfilled, (state, { payload }) => {
    state.parcelsInView = payload
    state.mapLoading = false
  })

  builder.addCase($actions.fetchPolygonsGeojsonInView.fulfilled, (state, { payload }) => {
    state.parcelsPolygonsGeojsonInView = payload
    state.mapLoading = false
  })

  builder.addMatcher(
    isAnyOf(
      $actions.fetchParcels.pending,
      $actions.fetchParcelsInView.pending,
      $actions.fetchPolygonsGeojsonInView.pending
    ),
    (state) => {
      state.mapLoading = true
    }
  )

  builder.addMatcher(
    isAnyOf(
      $actions.fetchParcels.rejected,
      $actions.fetchParcelsInView.rejected,
      $actions.fetchPolygonsGeojsonInView.rejected
    ),
    (state) => {
      state.mapLoading = false
    }
  )
})

export const appSelector = (state: Record<"app", IAppState>): IAppState => state.app

export const parcelsSelector = createSelector(appSelector, (app) => app.parcels)

export const parcelsInViewSelector = createSelector(appSelector, (app) => app.parcelsInView)

export const parcelsPolygonsGeojsonInViewSelector = createSelector(
  appSelector,
  (app) => app.parcelsPolygonsGeojsonInView
)

export const mapLoadingSelector = createSelector(appSelector, (app) => app.mapLoading)

export const authentificatedSelector = createSelector(appSelector, (app) => app.authentificated)

export default $reducer
