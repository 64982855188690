import { currentYearString } from "utils/currentYear"
import { TAccess, TProperty } from "./api.types"

export type TRange = [number, number]

export type TSaleFilter = "FOR_SALE" | "FOR_LEASE" | "SOLD" | "OFF_MARKET" | ""

export type TSorting = "created_at" | "-created_at" | "price" | "-price" | "building_sqft" | "-building_sqft" | ""

export type TParking = "STREET" | "OFFSTREET" | ""

export type TListerStatus = "OWNER" | "AGENT" | ""

export type TFilterObject = Partial<IFilterData>

export type TPriceFilter = "total" | "per_sqft" | ""

export type TSliderRange = [string, string]
export interface IFilterData {
  listing_type: TSaleFilter
  price_min: string
  price_max: string
  price_type: TPriceFilter
  property_type: TProperty[]
  building_sqft_min: string
  building_sqft_max: string
  parcel_sqft_min: string
  parcel_sqft_max: string
  year_built_min: string
  year_built_max: string
  ordering: TSorting
  parking: TParking
  lister_status: TListerStatus
  access: TAccess[]
  buildingGradeFilter: ""
  search: string
  sold_from_delta: string
}

export interface ISearchCriteria {
  uuid: string
  user: number
  name: string
  created_at: string
  updated_at: string
  location_query: string
  property_type: TProperty[]
  price_min: number | null
  price_max: number | null
  building_sqft_min: number | null
  building_sqft_max: number | null
  parcel_sqft_min: number | null
  parcel_sqft_max: number | null
  year_build_min: number | null
  year_build_max: number | null
  parking: TParking
  lister_status: TListerStatus
  access: TAccess[]
}

export interface ISearchCriteriaResponse {
  results: ISearchCriteria[]
  next: string | null
  previous: string | null
}

export const InitialFilterData: IFilterData = {
  listing_type: "FOR_LEASE",
  price_min: "0",
  price_max: "20000000",
  price_type: "",
  property_type: [],
  building_sqft_min: "0",
  building_sqft_max: "100000",
  parcel_sqft_min: "0",
  parcel_sqft_max: "100000",
  year_built_min: "1900",
  year_built_max: currentYearString,
  ordering: "",
  parking: "",
  lister_status: "",
  access: [],
  buildingGradeFilter: "",
  search: "",
  sold_from_delta: "",
}
