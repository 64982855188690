import ENDPOINTS from "constants/api.constants"
import { IApiParcel } from "types/api.types"
import axios, { CancelToken } from "axios"
import getHeaders from "utils/GetHeaders"

export const getParcelsPaginataionOffMarket = async (query: string = "", cancelToken?: CancelToken) => {
  const headers = { ...getHeaders(), cancelToken }
  const parcels = await axios.get(`${ENDPOINTS.GET_PARCELS_PAGINATION}${query}`, headers)
  return parcels.data.results as IApiParcel[]
}
