import { getContactInformation } from "api/parcels/UserListing/getContactInformation"
import { IApiParcelByUuid, IContactInformation } from "types/api.types"
import { ReactComponent as Phone } from "assets/icons/phoneBlue.svg"
import { FC, Fragment, useEffect, useRef, useState } from "react"
import { ReactComponent as Email } from "assets/icons/email.svg"
import placeholder from "assets/icons/avatarPlaceholder.png"
import styles from "./PropertyPageStickyFooter.module.scss"
import { addDashes } from "utils/addDashesPhone"
import Skeleton from "react-loading-skeleton"
import { UseError, UseSwipe } from "hooks"
import { motion } from "framer-motion"
import clsx from "clsx"

type Props = {
  parcel: IApiParcelByUuid
}

const PropertyPageStickyFooter: FC<Props> = ({ parcel }) => {
  const { owner, id, contact_company, contact_name, address_full } = parcel
  const { notify } = UseError()
  const { init } = UseSwipe()

  const [ownerData, setOwnerData] = useState<IContactInformation | null>(null)
  const [overlay, setOverlay] = useState(false)

  const overlayContainerRef = useRef<HTMLDivElement | null>(null)

  const initSwipeEvents = () => {
    init({ element: overlayContainerRef, callback: close, direction: "bottom", stopPropagation: true })
  }

  const close = () => setOverlay(false)

  const onOverlay = async () => {
    setOverlay(!overlay)
    if (!overlay && !ownerData) getOwner()
  }

  const getOwner = async () => {
    try {
      setOverlay(true)
      const { data } = await getContactInformation(id)
      setOwnerData(data)
    } catch (e) {
      setOverlay(false)
      notify("We issued some issues getting owner")
    }
  }

  useEffect(() => {
    if (overlayContainerRef.current) initSwipeEvents()
  }, [])

  const renderSticky = (
    <Fragment>
      <div className={styles.stickyText}>
        <h2>{contact_name}</h2>
        <h3>{contact_company || "N/A"}</h3>
      </div>
      <div className={styles.stickyButton} onClick={onOverlay}>
        <h2>{overlay ? "Hide" : "Contact"}</h2>
      </div>
    </Fragment>
  )

  const renderNoOwner = (
    <Fragment>
      <motion.div className={styles.noOwner} layout>
        <h3>Owner is not avaliable </h3>
      </motion.div>
    </Fragment>
  )

  const renderOverlaySkeleton = () => {
    return owner ? (
      <motion.div className={styles.stickyOverlay} layout>
        <div className={styles.stickyOverlayPhoto}>
          <Skeleton height={"100%"} width={"100%"} borderRadius={"50%"} />
        </div>
        <div className={`${styles.stickySkeletonItem} ${styles.stickySkeletonItem_small}`}>
          <Skeleton />
          <Skeleton />
        </div>
        <div className={`${styles.stickySkeletonItem} ${styles.stickySkeletonItem_big}`}>
          <Skeleton />
        </div>
        <div className={`${styles.stickySkeletonItem} ${styles.stickySkeletonItem_big}`}>
          <Skeleton />
        </div>
        <div className={`${styles.stickySkeletonItem} ${styles.stickySkeletonItem_big}`}>
          <Skeleton />
        </div>
      </motion.div>
    ) : (
      renderNoOwner
    )
  }

  const renderContactEmail = () => {
    const { contact_email } = ownerData || {}
    return contact_email ? (
      <div className={styles.stickyOverlayContentItem}>
        <a href={`mailto:${contact_email}?subject=Comprops: ${address_full}&body=${window.location.href}`}>
          {contact_email}
        </a>
      </div>
    ) : null
  }

  const renderContactPhone = () => {
    const { contact_phone } = ownerData || {}
    return contact_phone ? (
      <div className={styles.stickyOverlayContentItem}>
        <a href={`tel:${contact_phone}`}>{addDashes(contact_phone)}</a>
      </div>
    ) : null
  }

  const renderOverlay = () => {
    const { profile_picture, contact_name, contact_email, contact_company, contact_phone } = ownerData || {}

    const company = contact_company?.length ? contact_company : "N/A"

    return ownerData ? (
      <Fragment>
        <motion.div className={styles.stickyOverlay} layout>
          <div className={styles.stickyOverlayPhoto}>
            <img src={profile_picture ? profile_picture : placeholder} alt="avatar" />
          </div>
          <div className={styles.stickyOverlayContent}>
            <div
              className={clsx({
                [styles.stickyOverlayContentItem]: true,
                [styles.stickyOverlayContentItem_name]: true,
              })}
            >
              <h2>{contact_name}</h2>
            </div>
            <div className={styles.stickyOverlayContentItem}>
              <h2>{company}</h2>
            </div>
            {renderContactEmail()}
            {renderContactPhone()}
            <div className={styles.stickyOverlayContactButtonsContainer}>
              <a
                className={styles.stickyOverlayContactButton}
                href={`mailto:${contact_email}?subject=Comprops: ${address_full}&body=${window.location.href}`}
              >
                <Email />
              </a>
              <a className={styles.stickyOverlayContactButton} href={`tel:${contact_phone}`}>
                <Phone />
              </a>
            </div>
          </div>
        </motion.div>
      </Fragment>
    ) : (
      renderOverlaySkeleton()
    )
  }

  return (
    <motion.div className={styles.sticky} ref={overlayContainerRef} layout>
      <motion.div className={styles.stickyContent} layout>
        {renderSticky}
      </motion.div>
      {overlay ? renderOverlay() : null}
    </motion.div>
  )
}

export default PropertyPageStickyFooter
