import { ChangeEvent, FC, Fragment } from "react"
import styles from "./PrivacyPolicy.module.scss"
import clsx from "clsx"

type Props = {
  setPolicyAccepted?: React.Dispatch<React.SetStateAction<boolean>>
  validated?: boolean
  evented?: boolean
}

const links = {
  privacy: "/privacy",
  terms: "/tos",
}

const PrivacyPolicy: FC<Props> = ({ evented = false, validated = true, setPolicyAccepted }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setPolicyAccepted) setPolicyAccepted(e.target.checked)
  }

  const privacy = (
    <a href={links.privacy} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
  )

  const terms = (
    <a href={links.terms} target="_blank" rel="noreferrer">
      Terms
    </a>
  )

  const renderEvented = (
    <Fragment>
      <input type="checkbox" id="privacy" onChange={evented ? onChange : undefined} />
      <label htmlFor="privacy">
        By signing up you agree to our {privacy} and {terms}
      </label>
    </Fragment>
  )

  const renderDefault = (
    <h2>
      By signing up you agree to our {privacy} and {terms}
    </h2>
  )

  return (
    <div
      className={clsx({
        [styles.PrivacyPolicy]: true,
        [styles.PrivacyPolicy_errored]: !validated,
      })}
    >
      {evented ? renderEvented : renderDefault}
    </div>
  )
}

export default PrivacyPolicy
