
import { IApiParcelByUuid } from "types/api.types"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"
import axios from "axios"

export const changeParcelVisibility = async (uuid: string | number, hidden: boolean) => {
  const headers = getHeaders(false)
  return (await axios.patch(`${ENDPOINTS.GET_USER_LISTINGS_PAGINATION}${uuid}/`, {hidden}, headers)) as IApiParcelByUuid
}
