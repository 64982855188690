import { createContext, Dispatch, SetStateAction } from "react"
import { UseCredentials } from "hooks"

export interface ICredentialsContext {
  setCredentials: Dispatch<SetStateAction<string>>
  credentialsRemove: () => void
}
export const CredentialsContext = createContext({})

export const CredentialsProvider = (props: { children: JSX.Element }) => {
  const { setValue, remove } = UseCredentials()

  const value: ICredentialsContext = {
    setCredentials: setValue,
    credentialsRemove: remove,
  }

  return <CredentialsContext.Provider value={value}>{props.children}</CredentialsContext.Provider>
}
