import { TSorting } from "types/filter.types"

interface IListFilterConfig {
  id: number
  text: string
  active: boolean
  value: TSorting
}

export const config: IListFilterConfig[] = [
  {
    id: 0,
    text: "Newest",
    active: false,
    value: "-created_at",
  },
  {
    id: 1,
    text: "Oldest",
    active: false,
    value: "created_at",
  },
  {
    id: 2,
    text: "Price (high > low)",
    active: false,
    value: "-price",
  },
  {
    id: 3,
    text: "Price (low > high)",
    active: false,
    value: "price",
  },
  {
    id: 4,
    text: "SQFT (high > low)",
    active: false,
    value: "-building_sqft",
  },
  {
    id: 5,
    text: "SQFT (low > high)",
    active: false,
    value: "building_sqft",
  },

  //TODO UNCOMMENT WHEN API READY
  // {
  //   id: 6,
  //   text: 'City (A > Z)',
  //   active: false,
  // },
  // {
  //   id: 7,
  //   text: 'City (Z > A)',
  //   active: false,
  // },
]
