import styles from "./AddListingProgressBar.module.scss"
import React from "react"
import clsx from "clsx"

type Props = {
  progressBarList: { id: number; circleActive: boolean; lineActive: boolean }[]
  currentStep: number
}

const AddListingProgressBar: React.FC<Props> = ({ progressBarList, currentStep }) => {
  const [isLastStep, setIsLastStep] = React.useState(false)

  const updateLastStep = () => {
    if (currentStep === 6) {
      setTimeout(() => {
        setIsLastStep(true)
      }, 800)
    }
  }

  React.useEffect(() => {
    updateLastStep()
  }, [currentStep])

  return (
    <div className={styles.progressBarContainer}>
      {progressBarList.map((i) => (
        <React.Fragment key={`${i.id}progressBarItem`}>
          <div
            className={clsx({
              [styles.progressBarItem]: true,
              [styles.progressBarItem_active]: i.circleActive,
            })}
          />
          <div
            className={clsx({
              [styles.progressItem]: true,
              [styles.progressItem_active]: i.lineActive,
            })}
          />
        </React.Fragment>
      ))}

      <div
        className={clsx({
          [styles.progressBarItem]: true,
          [styles.progressBarItem_active]: isLastStep,
        })}
      />
    </div>
  )
}

export default AddListingProgressBar
