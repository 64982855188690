import { ComponentProps } from "react"

type TComponent = (props: { children: JSX.Element }) => JSX.Element

export const combineComponents = (...components: TComponent[]): TComponent => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: ComponentProps<TComponent>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}
