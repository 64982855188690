import PropertiesAddPhotoCard from "../PropertiesAddPhotoCard/PropertiesAddPhotoCard"
import { TDivClickEvent } from "types/components.types"
import styles from "./PropertyPageSlider.module.scss"
import { IApiParcelByUuid } from "types/api.types"
import React from "react"

type Props = {
  parcel: IApiParcelByUuid
  listed: boolean
  imageClickCallback: (e: TDivClickEvent) => void
}

const PropertyPageSlider: React.FC<Props> = ({ parcel, listed, imageClickCallback }) => {
  const { photos } = parcel
  const images = React.useMemo(() => photos.map((i, index) => ({ url: i.url, id: index })), [photos])

  const renderSlider = images.map((i) => {
    const { id, url } = i
    return (
      <div className={styles.sliderItem} key={`slideritem${id}`} data-id={id} onClick={imageClickCallback}>
        <img src={url} alt="slide" />
      </div>
    )
  })

  return (
    <div className={styles.container}>
      {renderSlider}
      {listed ? null : (
        <div className={styles.sliderItem}>
          <PropertiesAddPhotoCard parcel={parcel} />
        </div>
      )}
    </div>
  )
}

export default PropertyPageSlider
