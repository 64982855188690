import axios, { AxiosResponse } from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

interface IPostPhotoResponse {
  uuid: string
  file: string
}

export const postPhoto = async (
  photo: File,
  onUploadProgress: (e: ProgressEvent) => void
): Promise<AxiosResponse<IPostPhotoResponse>> => {
  const headers = getHeaders(false)
  const noCookieHeaders = {
    ...headers,
    "Content-Type": "multipart/form-data",
    onUploadProgress: (e: ProgressEvent) => onUploadProgress(e),
  }
  const formData = new FormData()
  formData.append("file", photo)
  return await axios.post(ENDPOINTS.POST_USER_LISTING_PHOTO, formData, noCookieHeaders)
}
