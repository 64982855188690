import useLocalStorageState from "use-local-storage-state"
import { $actions } from "redux/reducers/app.reducer"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

export const UseCredentials = () => {
  const dispatch = useDispatch()
  const [value, setValue, { removeItem, isPersistent }] = useLocalStorageState("userAuthentificationToken", {
    defaultValue: "",
  })

  useEffect(() => {
    if (value?.length) {
      dispatch($actions.setAuthentificated(true))
    } else {
      dispatch($actions.setAuthentificated(false))
    }
  }, [value])

  return { value, setValue, remove: removeItem, isPersistent }
}

export default UseCredentials
