import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy"
import BackButton from "components/BackButton/BackButton"
import TextInput from "components/TextInput/TextInput"
import BigButton from "components/BigButton/BigButton"
import styles from "./SignUpStep0.module.scss"
import React, { ChangeEvent } from "react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import PopupBackground from "components/PopupBackground/PopupBackground"
import { slideInBottom } from "constants/animationContants"

type Props = {
  backButtonCallback: () => void
  buttonClickCallback: () => void
  inputChangeCallback: (type: "email" | "password" | "name" | "surname", value: string) => void
}

const SignUpStep0: React.FC<Props> = ({ backButtonCallback, buttonClickCallback, inputChangeCallback }) => {
  const navigate = useNavigate()

  const [name, setName] = React.useState<string>("")
  const [surname, setSurname] = React.useState<string>("")
  const [inputHightlights, setInputHightlights] = React.useState({ name: true, surname: true })

  const nameInputChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    inputChangeCallback("name", e.target.value)
  }

  const SurnameInputChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setSurname(e.target.value)
    inputChangeCallback("surname", e.target.value)
  }

  const validate = () => {
    const isNameValidated = name.length > 0
    const isSurnameValidated = surname.length > 0

    setInputHightlights({ name: isNameValidated, surname: isSurnameValidated })

    if (isSurnameValidated && isNameValidated) {
      buttonClickCallback()
    }
  }

  const navigateToLogin = () => navigate("/login")

  return (
    <div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.backButton}>
          <BackButton clickCallback={backButtonCallback} />
        </div>
        <div className={styles.title}>
          <h2>Sign Up</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>

        <div className={styles.inputsList}>
          <TextInput text="Name" changeCallback={nameInputChangeCallback} validated={inputHightlights["name"]} />
          <TextInput
            text="Last Name"
            changeCallback={SurnameInputChangeCallback}
            validated={inputHightlights["surname"]}
          />
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Next" clickCallback={validate} />
        </div>
        <PrivacyPolicy />
        <div className={styles.haveAnAccount}>
          <h2>
            Already have an account? <span onClick={navigateToLogin}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </div>
  )
}

export default SignUpStep0
