import { IListingFormData } from "types/addListing.types"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"
import axios from "axios"

export const postParcel = async (data: Omit<IListingFormData, "documents">) => {
  const headers = getHeaders(false)
  const createdParcel = await axios.post(ENDPOINTS.GET_USER_LISTINGS_PAGINATION, data, headers)
  return createdParcel.data
}
