import { ReactComponent as BigLogo } from "assets/icons/signup/bigLogo.svg"
import PopupBackground from "components/PopupBackground/PopupBackground"
import styles from "./ResetPasswordStep1.module.scss"
import BigButton from "components/BigButton/BigButton"
import TextInput from "components/TextInput/TextInput"
import resetPassword from "api/user/resetPassword"
import { useNavigate } from "react-router-dom"
import React, { ChangeEvent } from "react"
import { motion } from "framer-motion"
import { UseError } from "hooks"
import CrossButton from "components/CrossButton/CrossButton"
import { AxiosError } from "axios"
import { slideInBottom } from "constants/animationContants"

type Props = {}

const ResetPasswordStep1: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { notify, notifySuccess } = UseError()
  const [email, setEmail] = React.useState<string>("")
  const [validated, setValidated] = React.useState<boolean>(true)

  const onLogin = () => navigate("/login")

  const sendResetEmail = async () => {
    try {
      await resetPassword(email)
      notifySuccess("instructions are sent to your email")
    } catch (err) {
      const { response } = err as AxiosError<{ error: string }>
      const data = response?.data
      const error = data?.error
      if (error) return notify(error)
      notify("password cannot be reseted now, please try later")
    }
  }

  const validateEmail = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValidated = re.test(email) && email.length > 0
    setValidated(isEmailValidated)
    return isEmailValidated
  }

  const validate = async () => {
    const isValidated = validateEmail()
    if (isValidated) await sendResetEmail()
  }

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)

  return (
    <div className={styles.container}>
      <PopupBackground />
      <motion.div className={styles.popup} {...slideInBottom}>
        <div className={styles.closePopupButton}>
          <CrossButton />
        </div>
        <div className={styles.title}>
          <h2>Reset Password</h2>
        </div>
        <div className={styles.logo}>
          <BigLogo />
        </div>
        <div className={styles.inputsList}>
          <TextInput text="Email" changeCallback={onEmailChange} validated={validated} maxLength={255} />
        </div>
        <div className={styles.bigButtonContainer}>
          <BigButton text="Reset Password" clickCallback={validate} />
        </div>
        <div className={styles.haveAnAccount}>
          <h2>
            Remembered your password? <span onClick={onLogin}>Login here</span>
          </h2>
        </div>
      </motion.div>
    </div>
  )
}

export default ResetPasswordStep1
