const config = [
  {
    id: 0,
    title: "Newest",
    key: "newest",
    active: false,
  },
  {
    id: 1,
    title: "Oldest",
    key: "oldest",
    active: false,
  },
  {
    id: 2,
    title: "Price (low > high)",
    key: "lowToHigh",
    active: false,
  },
  {
    id: 3,
    title: "Price (high > low)",
    key: "highToLow",
    active: false,
  },
  {
    id: 4,
    title: "SQFT (low > high)",
    key: "sqftLowHigh",
    active: false,
  },
  {
    id: 5,
    title: "SQFT (high > low)",
    key: "sqftHighLow",
    active: false,
  },
]

export default config
