import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const removeSearchCriteria = async (uuid: string) => {
  const headers = getHeaders(false)
  return await axios.delete(`${ENDPOINTS.SEARCH_CRITERIA}${uuid}/`, headers)
}

export default removeSearchCriteria
