import { ReactComponent as ArrowToRight } from "assets/icons/arrowToRight.svg"
import { ReactComponent as ArrowToLeft } from "assets/icons/arrowToLeft.svg"
import styles from "./Arrow.module.scss"
import { motion } from "framer-motion"
import React from "react"
import { TDivClickEvent } from "types/components.types"

type Props = {
  dir: "l" | "r"
  onClick: (e: TDivClickEvent) => void | (() => Promise<void>)
}

const Arrow: React.FC<Props> = ({ dir, onClick }) => {
  const Icon = dir === "l" ? ArrowToLeft : ArrowToRight
  return (
    <motion.div className={styles.arrow} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }} onClick={onClick}>
      <Icon />
    </motion.div>
  )
}

export default Arrow
