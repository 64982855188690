import AddListingStep0 from "./AddListingSteps/AddListingStep0/AddListingStep0"
import AddListingStep1 from "./AddListingSteps/AddListingStep1/AddListingStep1"
import AddListingStep2 from "./AddListingSteps/AddListingStep2/AddListingStep2"
import AddListingStep3 from "./AddListingSteps/AddListingStep3/AddListingStep3"
import AddListingStep4 from "./AddListingSteps/AddListingStep4/AddListingStep4"
import AddListingStep5 from "./AddListingSteps/AddListingStep5/AddListingStep5"
import AddListingAddLocation from "./AddListingSteps/AddListingAddLocation/AddListingAddLocation"

const config = [
  { id: 0, component: AddListingStep0 },
  { id: 1, component: AddListingAddLocation },
  { id: 2, component: AddListingStep1 },
  { id: 3, component: AddListingStep2 },
  { id: 4, component: AddListingStep3 },
  { id: 5, component: AddListingStep4 },
  { id: 6, component: AddListingStep5 },
]

export default config
