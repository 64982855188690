import TitleHeader from "components/TitleHeader/TitleHeader"
import getMyBookmarks from "api/watchList/getMyBookmarks"
import { IApiParcelByUuid } from "types/api.types"
import React, { BaseSyntheticEvent } from "react"
import { useNavigate } from "react-router-dom"
import s from "./WatchList.module.scss"
import Card from "components/Card/Card"
import { AnimatePresence, motion } from "framer-motion"
import { UseError } from "hooks"
import { tiltInBottom } from "constants/animationContants"

type Props = {}

const messages = {
  error: "we had some issues getting you watch list",
}

const WatchList: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { notify } = UseError()
  const [watchList, setWatchList] = React.useState<IApiParcelByUuid[]>([])

  const fetchWatchList = async () => {
    try {
      const { data } = await getMyBookmarks()
      setWatchList(data)
    } catch (err) {
      notify(messages.error)
    }
  }

  const findParcel = (id: string) => watchList.find((i) => i.id === Number(id))

  const filterList = (id: number) => watchList.filter((i) => i.id !== id)

  const onClick = (e: BaseSyntheticEvent) => {
    const id = e.target.getAttribute("data-id")
    if (!id) return
    const parcel = findParcel(id)
    const isHidden = parcel?.hidden || false
    const isUserListing = parcel?.listing_type !== "SOLD" && parcel?.listing_type !== "OFF-MARKET"
    const type = isUserListing ? "listed" : "unlisted"
    navigate(`/parcels/view/${type}/${id}/${isHidden}`)
  }

  const onRemove = (id: number) => {
    const filtered = filterList(id)
    setWatchList(filtered)
  }

  React.useEffect(() => {
    fetchWatchList()
  }, [])

  return (
    <motion.div
      className={s.watchList}
      initial={{ left: "100vw" }}
      animate={{ left: "0" }}
      exit={{ left: "100vw" }}
      transition={{ opacity: { duration: 0.4 } }}
    >
      <TitleHeader title="Watch List" />
      <motion.div className={s.list}>
        <AnimatePresence>
          {watchList.map((i, index) => (
            <motion.div
              className={s.listItem}
              key={`watchCard${i.id}`}
              {...tiltInBottom}
              transition={{ duration: 0.3, delay: index * 0.05 }}
              layout
            >
              <Card parcel={i} onBookmark={onRemove} handleCardClick={onClick} isMap typeAffected />
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  )
}

export default WatchList
