import { IListingFormData, TAddListingFormDataUpdateCallback, TContactInformation } from "types/addListing.types"
import AddListingTitle from "components/AddListingTitle/AddListingTitle"
import AddListingInput from "components/AddListingInput/AddListingInput"
import styles from "./AddListingStep3.module.scss"
import React from "react"
import { motion } from "framer-motion"
import { TInputTextareaKeydownEvent } from "types/components.types"
import LinkButton from "components/LinkButton/LinkButton"
import { fadeIn } from "constants/animationContants"

type Props = {
  formDataUpdateCallback: TAddListingFormDataUpdateCallback
  handleNext: () => void
  handleBack: () => void
  listingFormData: IListingFormData
}

const AddListingStep3: React.FC<Props> = ({ formDataUpdateCallback, handleNext, handleBack, listingFormData }) => {
  const { contact_name, contact_company, contact_email, contact_phone } = listingFormData
  const initialInformation = { contact_name, contact_company, contact_email, contact_phone }
  const [contactInfo, setContactInfo] = React.useState<TContactInformation>(initialInformation)
  const [validationList, setValidationList] = React.useState({ name: true, email: true, phone: true })

  const updateContactInfo = (value: Partial<IListingFormData>): void => {
    setContactInfo((prev) => ({ ...prev, ...value }))
  }

  const updateFormData = () => {
    const { contact_name, contact_company, contact_email, contact_phone } = contactInfo
    formDataUpdateCallback({ contact_name, contact_company, contact_email, contact_phone })
  }

  const handleNextButton = () => {
    const validated = validate()
    if (validated) {
      handleNext()
      updateFormData()
    }
  }

  const phoneKeyDownCallback = (e: TInputTextareaKeydownEvent) => {
    if (!/[0-9]|\-/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault()
    }
  }

  const validateEmail = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const email = contactInfo["contact_email"]
    return re.test(email) && email.length > 0 && email.length <= 100
  }

  const validate = () => {
    const isNameValidated = contactInfo["contact_name"].length > 0
    const isEmailValidated = validateEmail()
    const isPhoneValidate = contactInfo["contact_phone"].length === 10
    setValidationList({ name: isNameValidated, email: isEmailValidated, phone: isPhoneValidate })
    return isNameValidated && isEmailValidated && isPhoneValidate
  }

  React.useEffect(() => {
    updateFormData()
  }, [contactInfo])

  return (
    <motion.div className={styles.container} {...fadeIn}>
      <AddListingTitle heading="Contact Information" subheading="(Fill in required fields)" />
      <div className={styles.contactInformationWrapper}>
        <AddListingInput
          placeholder="Your full name"
          title="Name"
          keyToValue="contact_name"
          required
          changeCallback={updateContactInfo}
          defaultValue={contact_name}
          validated={validationList["name"]}
        />
        <AddListingInput
          placeholder="Your Company Name"
          keyToValue="contact_company"
          title="Company"
          defaultValue={contact_company}
          changeCallback={updateContactInfo}
        />
        <AddListingInput
          placeholder="Your email"
          keyToValue="contact_email"
          title="Email Address"
          defaultValue={contact_email}
          maxLength={100}
          required
          changeCallback={updateContactInfo}
          validated={validationList["email"]}
        />
        <AddListingInput
          placeholder="e.g. 412-555-555"
          keyToValue="contact_phone"
          title="Phone Number"
          defaultValue={contact_phone}
          required
          maxLength={12}
          changeCallback={updateContactInfo}
          keyDownCallback={phoneKeyDownCallback}
          validated={validationList["phone"]}
          type="phone"
        />
      </div>
      <div className={styles.navigationButtons}>
        <LinkButton onClick={handleBack} text="Back" secondary />
        <LinkButton onClick={handleNextButton} text="Next" />
      </div>
    </motion.div>
  )
}

export default AddListingStep3
