import TitleHeader from "components/TitleHeader/TitleHeader"
import styles from "./PrivacyPolicyPage.module.scss"

const PrivacyPolicyPage = () => (
  <div className={styles.PrivacyPolicyWrapper}>
    <TitleHeader title="Privacy Policy" />
    <div className={styles.PrivacyPolicy}>
      <h3>
        <strong>Comprops Privacy Policy</strong>
      </h3>
      <p>Last Modified: June 1, 2023</p>
      <h3>
        <strong>Introduction</strong>
      </h3>
      <p>
        Comprops LLC (<strong>"Company"</strong> or <strong>"We"</strong>) respect your privacy and are committed to
        protecting it through our compliance with this policy. This Privacy Policy explains how your personal
        information is collected, used, and disclosed by the Company. This Privacy Policy applies to our websites,
        including <a href="http://www.comprops.com">www.comprops.com</a> (our <strong>"Website"</strong>), and any other
        website that links to this Policy and our mobile apps for iPhone, Android, Windows and other mobile devices
        (collectively, our <strong>"Services"</strong>). By accessing or using our Services, you acknowledge and consent
        that you have read, understood, and agreed to our collection, storage, use, and disclosure of your personal
        information as described in this Privacy Policy and our Terms of Service at www.comprops.com/tos.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>On this Website.</li>
        <li>In email, text, and other electronic messages between you and this Website.</li>
        <li>
          Through mobile and desktop applications you download from this Website, which provide dedicated
          non-browser-based interaction between you and this Website.
        </li>
        <li>
          When you interact with our advertising and applications on third-party websites and services, if those
          applications or advertising include links to this policy.
        </li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          Us offline or through any other means, including on any other website operated by Company or any third party
          (including our affiliates and subsidiaries); or
        </li>
        <li>
          Any third party (including our affiliates and subsidiaries), including through any application or content
          (including advertising) that may link to or be accessible from or on the Website.
        </li>
      </ul>
      <p>
        Please read this policy carefully to understand our policies and practices regarding your information and how we
        will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By
        accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see
        Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be
        acceptance of those changes, so please check the policy periodically for updates.
      </p>
      <h3>
        <strong>Children Under the Age of 16</strong>
      </h3>
      <p>
        Our Website is not intended for children under 16 years of age. No one under age 16 may provide any information
        to or on the Website. We do not knowingly collect personal information from children under 16. If you are under
        16, do not use or provide any information on this Website or on or through any of its features. If we learn we
        have collected or received personal information from a child under 16 without verification of parental consent,
        we will delete that information. If you believe we might have any information from or about a child under 16,
        please contact us at: <a href="mailto:hello@comprops.com">hello@comprops.com</a>.
      </p>
      <p>
        California residents under 16 years of age may have additional rights regarding the collection and sale of their
        personal information. Please see Your California Privacy Rights for more information.
      </p>
      <h3>
        <strong>Information We Collect About You and How We Collect It</strong>
      </h3>
      <p>We collect several types of information from and about users of our Website, including information:</p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal address, e-mail address, telephone number, or
          any other identifier by which you may be contacted online or offline (<strong>"personal information"</strong>
          );
        </li>
        <li>That is about you but individually does not identify you; and/or</li>
        <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>
          Automatically as you navigate through the site. Information collected automatically may include usage details,
          IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
        </li>
        <li>From third parties, for example, our business partners.</li>
      </ul>
      <h3>
        <strong>Information You Provide to Us</strong>.
      </h3>
      <p>The information we collect on or through our Website may include:</p>
      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This includes information provided at the
          time of registering to use our Website, subscribing to our service, posting material, or requesting further
          services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when
          you report a problem with our Website.
        </li>
        <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
        <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
        <li>
          Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be
          required to provide financial information before placing an order through our Website.
        </li>
        <li>Your search queries on the Website.</li>
      </ul>
      <p>
        You also may provide information to be published or displayed (hereinafter, <strong>"posted"</strong>) on public
        areas of the Website, or transmitted to other users of the Website or third parties (collectively,
        <strong>"User Contributions"</strong>). Your User Contributions are posted on and transmitted to others at your
        own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or
        impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose
        to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will
        not be viewed by unauthorized persons.
      </p>
      <h3>
        <strong>Information We Collect Through Automatic Data Collection Technologies</strong>.
      </h3>
      <p>
        As you navigate through and interact with our Website, we may use automatic data collection technologies to
        collect certain information about your equipment, browsing actions, and patterns, including:
      </p>
      <ul>
        <li>
          Details of your visits to our Website, including traffic data, location data, logs, and other communication
          data and the resources that you access and use on the Website.
        </li>
        <li>
          Information about your computer and internet connection, including your IP address, operating system, and
          browser type.
        </li>
      </ul>
      <p>
        The information we collect automatically may include personal information, or we may maintain it or associate it
        with personal information we collect in other ways or receive from third parties. It helps us to improve our
        Website and to deliver a better and more personalized service, including by enabling us to:
      </p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our Website according to your individual
          interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you return to our Website.</li>
      </ul>
      <p>The technologies we use for this automatic data collection may include:</p>
      <ul>
        <li>
          <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your
          computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser.
          However, if you select this setting you may be unable to access certain parts of our Website. Unless you have
          adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct
          your browser to our Website.
        </li>
        <li>
          <strong>Flash Cookies.</strong> Certain features of our Website may use local stored objects (or Flash
          cookies) to collect and store information about your preferences and navigation to, from, and on our Website.
          Flash cookies are not managed by the same browser settings as are used for browser cookies. For information
          about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose
          Your Information.
        </li>
        <li>
          <strong>Web Beacons.</strong> Pages of our Website and our emails may contain small electronic files known as
          web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an email and for other related website
          statistics (for example, recording the popularity of certain website content and verifying system and server
          integrity).
        </li>
      </ul>
      <h3>
        <strong>Third-Party Use of Cookies and Other Tracking Technologies</strong>
      </h3>
      <p>
        Some content or applications, including advertisements, on the Website are served by third-parties, including
        advertisers, ad networks and servers, content providers, and application providers. These third parties may use
        cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you
        when you use our website. The information they collect may be associated with your personal information or they
        may collect information, including personal information, about your online activities over time and across
        different websites and other online services. They may use this information to provide you with interest-based
        (behavioral) advertising or other targeted content.
      </p>
      <p>
        We do not control these third parties' tracking technologies or how they may be used. If you have any questions
        about an advertisement or other targeted content, you should contact the responsible provider directly. For
        information about how you can opt out of receiving targeted advertising from many providers, see Choices About
        How We Use and Disclose Your Information.
      </p>
      <h3>
        <strong>How We Use Your Information</strong>
      </h3>
      <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
      <ul>
        <li>To present our Website and its contents to you.</li>
        <li>To provide you with information, products, or services that you request from us.</li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>To provide you with notices about your account, including expiration and renewal notices.</li>
        <li>
          To carry out our obligations and enforce our rights arising from any contracts entered into between you and
          us, including for billing and collection.
        </li>
        <li>To notify you about changes to our Website or any products or services we offer or provide though it.</li>
        <li>To allow you to participate in interactive features on our Website.</li>
        <li>In any other way we may describe when you provide the information.</li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <p>
        We may also use your information to contact you about our own and third-parties' goods and services that may be
        of interest to you. If you do not want us to use your information in this way, please adjust your user
        preferences in your account profile. For more information, see Choices About How We Use and Disclose Your
        Information.
      </p>
      <p>
        We may use the information we have collected from you to enable us to display advertisements to our advertisers'
        target audiences. Even though we do not disclose your personal information for these purposes without your
        consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet
        its target criteria.
      </p>
      <h3>
        <strong>Disclosure of Your Information</strong>
      </h3>
      <p>
        We may disclose aggregated information about our users, and information that does not identify any individual,
        without restriction.
      </p>
      <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>To contractors, service providers, and other third parties we use to support our business.</li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of the Company's assets, whether as a going concern or
          as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company
          about our Website users is among the assets transferred.
        </li>
        <li>
          To third parties to market their products or services to you if you have not opted out of these disclosures.
          For more information, see Choices About How We Use and Disclose Your Information.
        </li>
        <li>
          To fulfill the purpose for which you provide it. For example, if you give us an email address to use the
          "email a friend" feature of our Website, we will transmit the contents of that email and your email address to
          the recipients.
        </li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>With your consent.</li>
      </ul>
      <p>We may also disclose your personal information:</p>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to respond to any government or regulatory
          request.
        </li>
        <li>
          To enforce or apply our {"Invalid hyperlink URL"} or {"Invalid hyperlink URL"} and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
          Company, our customers, or others. This includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
      <h3>
        <strong>Choices About How We Use and Disclose Your Information</strong>
      </h3>
      <p>
        We strive to provide you with choices regarding the personal information you provide to us. We have created
        mechanisms to provide you with the following control over your information:
      </p>
      <ul>
        <li>
          <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some browser
          cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some
          parts of this site may then be inaccessible or not function properly.
        </li>
      </ul>
      <p>
        We do not control third parties' collection or use of your information to serve interest-based advertising.
        However these third parties may provide you with ways to choose not to have your information collected or used
        in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (
        <strong>"NAI"</strong>) on the NAI's&nbsp;
        <a href="http://www.networkadvertising.org/managing/opt_out.asp">website</a>.
      </p>
      <p>
        California residents may have additional personal information rights and choices. Please see Your California
        Privacy Rights for more information.
      </p>
      <p>
        Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may
        submit a request to this designated address: <a href="mailto:hello@comprops.com">hello@comprops.com</a>.
      </p>
      <h3>
        <strong>Your California Privacy Rights</strong>
      </h3>
      <p>
        If you are a California resident, California law may provide you with additional rights regarding our use of
        your personal information. To learn more about your California privacy rights, visit {"Invalid hyperlink URL"}.
      </p>
      <p>
        California's "shine the Light" law (Civil Code Section &sect; 1798.83) permits users of our App that are
        California residents to request certain information regarding our disclosure of personal information to third
        parties for their direct marketing purposes. To make such a request, please send an email to
        <a href="mailto:hello@comprops.com">hello@comprops.com</a> or write us at: 6505 Hamilton Avenue, Pittsburgh,
        Pennsylvania 15206.
      </p>
      <h3>
        <strong>Changes to Our Privacy Policy</strong>
      </h3>
      <p>
        It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to
        how we treat our users' personal information, we will notify you and update the "Last Modified" date at the top
        of this page. If you object to any changes, you may close your account. Continuing to use our Services after we
        publish changes to this Privacy Policy means that you are consenting to the changes.
      </p>
      <p>
        You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for
        periodically visiting our Website and this privacy policy to check for any changes.
      </p>
      <h3>
        <strong>Contact Information</strong>
      </h3>
      <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
      <p>
        <a href="mailto:hello@comprops.com">hello@comprops.com</a>
      </p>
    </div>
  </div>
)

export default PrivacyPolicyPage
