import styles from "./ShareButton.module.scss"
import React from "react"
import { UseError } from "hooks"
import { ReactComponent as Share } from "assets/icons/propertyPage/share.svg"
import { motion } from "framer-motion"
import { TDivClickEvent } from "types/components.types"

type Props = {}

const ShareButton: React.FC<Props> = () => {
  const { notify, notifySuccess } = UseError()

  const copyToClipboard = () => {
    if (!navigator["clipboard"]) notify("Your browser doesn't support copying to clipboard, either sharing")
    navigator.clipboard.writeText(window.location.href)
    notifySuccess(`Property URL has been copied into your clipboard!`)
  }

  const share = (e: TDivClickEvent) => {
    e.stopPropagation()
    if (navigator["share"]) return navigator.share({ url: window.location.href })
    copyToClipboard()
  }

  return (
    <motion.div className={styles.share} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }} onClick={share}>
      <Share />
    </motion.div>
  )
}

export default ShareButton
