import { ReactComponent as Retail } from "assets/icons/cardFilter/retail.svg"
import { ReactComponent as Warehouse } from "assets/icons/cardFilter/warehouse.svg"
import { ReactComponent as Industrial } from "assets/icons/cardFilter/industrial.svg"
import { ReactComponent as Office } from "assets/icons/cardFilter/office.svg"
import { ReactComponent as Multifamily } from "assets/icons/cardFilter/multifamily.svg"
import { ReactComponent as Flex } from "assets/icons/cardFilter/flex.svg"
import { ReactComponent as Hotelmotel } from "assets/icons/cardFilter/hotelmotel.svg"
import { ReactComponent as Healthcare } from "assets/icons/cardFilter/healthcare.svg"
import { ReactComponent as Parkinglot } from "assets/icons/cardFilter/parkinglot.svg"
import { ReactComponent as Vacantland } from "assets/icons/cardFilter/vacantland.svg"
import { ReactComponent as Other } from "assets/icons/cardFilter/other.svg"

const config = [
  {
    id: 0,
    text: "Retail",
    key: "retail",
    icon: Retail,
    active: true,
  },
  {
    id: 1,
    text: "Warehouse",
    key: "warehouse",
    icon: Warehouse,
    active: true,
  },
  {
    id: 2,
    text: "Industrial",
    key: "industrial",
    icon: Industrial,
    active: true,
  },
  {
    id: 3,
    text: "Office",
    key: "office",
    icon: Office,
    active: true,
  },
  {
    id: 4,
    text: "Multifamily",
    key: "multifamily",
    icon: Multifamily,
    active: true,
  },
  {
    id: 5,
    text: "Flex",
    key: "flex",
    icon: Flex,
    active: true,
  },
  {
    id: 6,
    text: "Hospitality",
    key: "hotel",
    icon: Hotelmotel,
    active: true,
  },
  {
    id: 7,
    text: "Healthcare",
    key: "healthcare",
    icon: Healthcare,
    active: true,
  },
  {
    id: 8,
    text: "Parking/ Lot",
    key: "parking",
    icon: Parkinglot,
    active: true,
  },
  {
    id: 9,
    text: "Vacant Land",
    key: "vacant",
    icon: Vacantland,
    active: true,
  },
  {
    id: 10,
    text: "Other",
    key: "other",
    icon: Other,
    active: true,
  },
]

export default config
