import { ReactComponent as Navigation } from "assets/icons/propertyPage/navigation.svg"
import { ReactComponent as Building } from "assets/icons/propertyPage/building.svg"
import { ReactComponent as Ruller } from "assets/icons/propertyPage/ruller.svg"
import { ReactComponent as Mountains } from "assets/icons/propertyPage/mountains.svg"
import { ReactComponent as Hammer } from "assets/icons/propertyPage/hammer.svg"
import { ReactComponent as PriceSqft } from "assets/icons/propertyPage/pricesqft.svg"
import { ReactComponent as Calendar } from "assets/icons/propertyPage/calendar.svg"
import { ReactComponent as ZoneCode } from "assets/icons/propertyPage/zoneCode.svg"
import { ReactComponent as Description } from "assets/icons/description.svg"

export type IParcelInformationIconKey =
  | "hammer"
  | "ruller"
  | "mountains"
  | "zonecode"
  | "calendar"
  | "building"
  | "pricesqft"
  | "navigation"
  | "description"

export interface IParcelInformationIcons {
  id: number
  text: string
  key: IParcelInformationIconKey
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  align: "center" | "start"
}

export const parcelInformationConfig: IParcelInformationIcons[] = [
  {
    id: 0,
    key: "hammer",
    Icon: Hammer,
    text: "Last Sale Price",
    align: "center",
  },
  {
    id: 1,
    key: "ruller",
    Icon: Ruller,
    text: "Building SQFT",
    align: "center",
  },
  {
    id: 2,
    key: "mountains",
    Icon: Mountains,
    text: "Parcel SQFT",
    align: "center",
  },
  {
    id: 3,
    key: "zonecode",
    Icon: ZoneCode,
    text: "Zoning Code",
    align: "center",
  },
  {
    id: 4,
    key: "calendar",
    Icon: Calendar,
    text: "Year Built",
    align: "center",
  },
  {
    id: 5,
    key: "building",
    Icon: Building,
    text: "Property Type",
    align: "center",
  },
  {
    id: 6,
    key: "pricesqft",
    Icon: PriceSqft,
    text: "Building SQFT Price",
    align: "center",
  },
  {
    id: 7,
    key: "navigation",
    Icon: Navigation,
    text: "",
    align: "center",
  },
  {
    id: 8,
    key: "description",
    Icon: Description,
    text: "Property Description",
    align: "start",
  },
]
