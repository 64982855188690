import { ISearchCriteria, TFilterObject, TSliderRange } from "types/filter.types"
import UseSliderFactory from "hooks/UseSliderFactory"
import UseQuery from "hooks/UseQuery"
import "./ParcelSqftFilter.scss"
import React from "react"

type Props = {
  updateCallback: (updatedValue: TFilterObject) => void
  dataObject?: ISearchCriteria
}

const ParcelSqftFilter: React.FC<Props> = ({ updateCallback, dataObject }) => {
  const { parcel_sqft_min = "0", parcel_sqft_max = "100000" } = dataObject || UseQuery()

  const updateFilters = (updatedValue: TFilterObject) => updateCallback(updatedValue)

  const sliderChangeCallback = (range: TSliderRange) => {
    const [min, max] = [range[0], range[1]]
    updateFilters({ parcel_sqft_min: min, parcel_sqft_max: max })
  }

  const { startRef, endRef, containerRef, inputChangeCallback, reset } = UseSliderFactory({
    min: parcel_sqft_min || "0",
    max: parcel_sqft_max || "100000",
    minInitial: 0,
    maxInitial: 100000,
    sliderSetCallback: sliderChangeCallback,
    numberFormatting: true
  })

  return (
    <div className="parcelSqftFilterWrapper">
      <div className="slider-area">
        <div className="slider" ref={containerRef}></div>
      </div>
      <div className="min-max-controls">
          <input
            onChange={inputChangeCallback}
            ref={startRef}
            type="text"
            placeholder="No Min"
            data-id="0"
            id="input-0"
          />
        <span className="divider">-</span>
          <input onChange={inputChangeCallback} ref={endRef} type="text" data-id="1" id="input-1" />
        <h2>SQFT</h2>
      </div>
      <div className="resetButtonWrapper" onClick={reset}>
        <h3>Reset</h3>
      </div>
    </div>
  )
}

export default ParcelSqftFilter
