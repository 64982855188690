import axios from "axios"
import ENDPOINTS from "constants/api.constants"
import getHeaders from "utils/GetHeaders"

export const bookmarkProperty = async (id: number | string) => {
  const headers = getHeaders(false)
  const endpoint = `${ENDPOINTS.GET_PARCELS_PAGINATION}${id}/bookmark/`
  return await axios.post(endpoint, null, headers)
}

export default bookmarkProperty
