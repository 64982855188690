import React from "react"

type Props = {
  progressBarConfig: { id: number; circleActive: boolean; lineActive: boolean }[]
}

const UseProgressBar = (props: Props) => {
  const { progressBarConfig } = props
  const [progressBarList, setPropgressBarList] = React.useState(progressBarConfig)
  const [currentStep, setCurrentStep] = React.useState(0)

  const moveProgressBar = (dir: number) => {
    const step = currentStep + dir
    const parsed = parseProgressBarList(step)
    if (dir === -1) return updateProgressBarWithDelay(parsed)
    setPropgressBarList(parsed)
  }

  const parseProgressBarList = (step: number) => {
    return progressBarList.map((i) => {
      const values = step === 5 && i.id === step - 1 ? [true, false] : [true, true]
      if (i.id < step) return { ...i, circleActive: values[0], lineActive: values[1] }
      return { ...i, circleActive: false, lineActive: false }
    })
  }

  const updateProgressBarWithDelay = (progressBar: typeof progressBarList) => {
    const id = currentStep - 1
    const barItemBefore = { id: id, circleActive: true, lineActive: false }
    const barItemAfter = { id: id, circleActive: false, lineActive: false }
    const listBase = [...progressBar.filter((i) => i.id !== id)]

    setPropgressBarList([...listBase, barItemBefore].sort((a, b) => a.id - b.id))
    setTimeout(() => {
      setPropgressBarList([...listBase, barItemAfter].sort((a, b) => a.id - b.id))
    }, 800)
  }

  const handleCurrentStep = (dir: -1 | 1) => {
    if (dir === -1 && currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
    if (dir === 1 && currentStep < 6) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleNext = () => {
    handleCurrentStep(1)
    moveProgressBar(1)
  }

  const handleBack = () => {
    handleCurrentStep(-1)
    moveProgressBar(-1)
  }

  return { handleNext, handleBack, setCurrentStep, progressBarList, currentStep }
}

export default UseProgressBar
