import { changeParcelVisibility } from "api/parcels/UserListing/changeParcelVisibility"
import ConfirmationLayout from "components/ConfirmationLayout/ConfirmationLayout"
import UpdateListing from "components/UpdateListing/UpdateListing"
import { getUserProperties } from "api/parcels/getUserProperties"
import PropertyCard from "components/PropertyCard/PropertyCard"
import TitleHeader from "components/TitleHeader/TitleHeader"
import { IListingFormData } from "types/addListing.types"
import { deleteParcel } from "api/parcels/deleteParcel"
import { AnimatePresence, motion } from "framer-motion"
import { updateParcel } from "api/parcels/updateParcel"
import { IButtonsConfig } from "types/button.types"
import { IApiParcelByUuid } from "types/api.types"
import React, { BaseSyntheticEvent, useState } from "react"
import styles from "./MyProperties.module.scss"
import { useNavigate } from "react-router-dom"
import { UseError } from "hooks"

const notifications = {
  fetchError: "we issued some problems delivering your properties, we are working on that, please try again later",
  updateError: "We had some technical issues while updating your listing, please try later, we are working on that",
  visibilityError: "error while changing property visibility",
  deleteError: "We had some technical issues while deleting your listing, please try later, we are working on that",
  hideSuccess: `Your listing won't be shown on the map!`,
  unhideSuccess: `Your listing will be shown on the map again!`,
}

const MyProperties = () => {
  const navigate = useNavigate()
  const { notify, notifySuccess } = UseError()
  const [myProperties, setMyProperties] = useState<IApiParcelByUuid[]>([])
  const [activeProperty, setActiveProperty] = useState<IApiParcelByUuid | {}>({})
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [next, setNext] = useState<string | null>(null)

  const isNoProperties = !myProperties.length && !fetching
  const isHidden = (activeProperty as IApiParcelByUuid)?.hidden

  const fetchProperties = async () => {
    setFetching(true)
    try {
      const { data } = await getUserProperties()
      const { next, results } = data
      if (!results) {
        setFetching(false)
        return notify(notifications.fetchError)
      }
      setMyProperties(results)
      setNext(next)
      setFetching(false)
    } catch (err) {
      setFetching(false)
      notify(notifications.fetchError)
    }
  }

  const updateProperty = async (data: IListingFormData) => {
    const { id } = activeProperty as IApiParcelByUuid
    try {
      const updatedParcel = await updateParcel(id, data)
      await fetchProperties()
      return updatedParcel
    } catch (err) {
      notify(notifications.updateError)
    }
  }

  const deleteProperty = async () => {
    const { id } = activeProperty as IApiParcelByUuid
    try {
      await deleteParcel(id)
      await fetchProperties()
      setIsDeleting(false)
    } catch (e) {
      notify(notifications.deleteError)
    }
  }

  const changeVisibilityProperty = async (data?: { id: number; hidden: boolean }) => {
    const { id, hidden } = data || (activeProperty as IApiParcelByUuid)
    const { hideSuccess, unhideSuccess } = notifications
    const formattedHidden = data ? hidden : !hidden
    try {
      await changeParcelVisibility(id, formattedHidden)
      await fetchProperties()
      formattedHidden ? notifySuccess(hideSuccess) : notifySuccess(unhideSuccess)
      setIsDeleting(false)
    } catch (err) {
      notify(notifications.visibilityError)
    }
  }

  const handleCardClick = (e: BaseSyntheticEvent) => {
    const uuid = e.target.getAttribute("data-id")
    const parcel = myProperties.find((i) => i.id === Number(uuid))
    const isHidden = parcel?.hidden || false
    if (uuid) navigate(`/parcels/view/listed/${uuid}/${isHidden}`)
  }

  const handleDeleteButton = (parcel: IApiParcelByUuid) => {
    setIsDeleting(true)
    setActiveProperty(parcel)
  }

  const handleEditButton = (parcel: IApiParcelByUuid) => {
    setIsEditing(true)
    setActiveProperty(parcel)
  }

  const onCancel = () => {
    setIsDeleting(false)
    setIsEditing(false)
  }

  const updateListingLastStepCallback = () => {
    const { id } = activeProperty as IApiParcelByUuid
    const parcel = myProperties.find((i) => i.id === Number(id))
    const isHidden = parcel?.hidden || false
    if (id) navigate(`/parcels/view/listed/${id}/${isHidden}`)
    setIsEditing(false)
  }

  const confirmationLayoutButtonsConfig: IButtonsConfig[] = [
    {
      text: "Hide",
      action: () => changeVisibilityProperty(),
      buttonStyle: "blue",
      asynchronous: true,
    },
    {
      text: "Delete",
      action: deleteProperty,
      buttonStyle: "white",
      asynchronous: false,
    },
  ]

  React.useEffect(() => {
    fetchProperties()
  }, [])

  const renderConfirmationContentHidden = () => {
    const { address_full } = activeProperty as IApiParcelByUuid
    return (
      <React.Fragment>
        <h2>Are you sure</h2>
        <h2>you want to delete property with address</h2>
        <h2 className={styles.smallMargin}>{address_full}</h2>
      </React.Fragment>
    )
  }

  const renderConfirmationContent = () => {
    const { address_full } = activeProperty as IApiParcelByUuid
    return (
      <React.Fragment>
        <h2>You have the option to</h2>
        <h2>either hide or delete your listing for</h2>
        <h2 className={styles.smallMargin}>{address_full}</h2>
      </React.Fragment>
    )
  }

  const renderNoProperties = (
    <div className={styles.empty}>
      <h2>You have not yet posted any listings. Click "Add Listing" to get started!</h2>
    </div>
  )

  const renderClarification = !isNoProperties ? (
    <div className={styles.clarification}>
      <h2>Review, edit, hide, and remove listings using the buttons below</h2>
    </div>
  ) : null

  return (
    <motion.div
      className={styles.container}
      initial={{ left: "100vw" }}
      animate={{ left: "0" }}
      exit={{ left: "100vw" }}
      transition={{ opacity: { duration: 0.4 } }}
    >
      <TitleHeader title="My Properties" />
      <div className={styles.PropertiesContainer}>
        {renderClarification}
        {myProperties.length
          ? myProperties.map((i) => (
              <div className={styles.property} key={i.id}>
                <PropertyCard
                  parcel={i}
                  handleListingVisibility={changeVisibilityProperty}
                  handleDeleteButton={handleDeleteButton}
                  handleEditButton={handleEditButton}
                  handleCardClick={handleCardClick}
                />
              </div>
            ))
          : null}
        {isNoProperties && renderNoProperties}
      </div>
      <AnimatePresence>
        {isDeleting && activeProperty ? (
          <ConfirmationLayout
            cancelCallback={onCancel}
            config={isHidden ? undefined : confirmationLayoutButtonsConfig}
            confirmCallback={deleteProperty}
          >
            {isHidden ? renderConfirmationContentHidden() : renderConfirmationContent()}
          </ConfirmationLayout>
        ) : null}
        {isEditing && activeProperty ? (
          <UpdateListing
            layoutTitle="Edit Listing for: "
            parcel={activeProperty as IApiParcelByUuid}
            updateProperty={updateProperty}
            lastStepButtonCallback={updateListingLastStepCallback}
            closeCallback={onCancel}
          />
        ) : null}
      </AnimatePresence>
    </motion.div>
  )
}

export default MyProperties
