import { TInputChangeEvent, TUiSLiderElement } from "types/components.types"
import { formatWithCommas } from "utils/FormatWithCommas"
import noUiSlider from "nouislider"
import React from "react"

type TVector2 = [string, string]
interface SliderParameters {
  min: number | string
  max: number | string
  minInitial: number
  maxInitial: number
  sliderSetCallback?: (range: [string, string]) => void
  valueModifier?: string
  numberFormatting?: boolean
}

interface ISliderFactoryReturn {
  reset: () => void
  inputChangeCallback: (e: TInputChangeEvent) => void
  startRef: React.RefObject<HTMLInputElement>
  endRef: React.RefObject<HTMLInputElement>
  containerRef: React.RefObject<HTMLDivElement>
}

const UseSliderFactory = (props: SliderParameters): ISliderFactoryReturn => {
  const { min, max, minInitial, maxInitial, sliderSetCallback, valueModifier = "", numberFormatting = false } = props

  const startRef = React.useRef<HTMLInputElement>(null)
  const endRef = React.useRef<HTMLInputElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)

  const init = () => {
    const rangeSlider = containerRef.current as TUiSLiderElement
    const inputs = [startRef.current, endRef.current]
    if (rangeSlider) {
      noUiSlider.create(rangeSlider, {
        start: [min, max] as string[],
        connect: true,
        step: 1,
        range: {
          min: [minInitial],
          max: [maxInitial],
        },
        format: {
          from: defaultFromFormating,
          to: defaultToFormatiing,
        },
      })

      rangeSlider.noUiSlider.on("update", (values, handle) => {
        ;(inputs[handle] as HTMLInputElement).value = `${valueModifier}${values[handle]}`
      })

      rangeSlider.noUiSlider.on("set", (values) => {
        const range = values.map((i) => {
          const formatted = i.toString().replaceAll(",", "")
          const rounded = Math.round(Number(formatted))
          return rounded.toString()
        }) as TVector2
        if (sliderSetCallback) sliderSetCallback(range)
      })
    }
  }

  const defaultFromFormating = (a: string) => {
    const convertableString = a.replaceAll(",", "")
    return Math.round(Number(convertableString))
  }

  const defaultToFormatiing = (a: number) => {
    const rounded = Math.round(a)
    return numberFormatting ? formatWithCommas(rounded) : rounded
  }

  const setRangeSlider = (i: number, value: string) => {
    const rangeSlider = containerRef.current as TUiSLiderElement
    let arr = [null, null] as (string | number | null)[]
    arr[i] = value
    rangeSlider.noUiSlider.set(arr as NonNullable<(string | number)[]>)
  }

  const inputChangeCallback = (e: TInputChangeEvent) => {
    const id = Number(e.currentTarget.getAttribute("data-id"))
    const value = e.currentTarget.value.replace("$", "")
    setRangeSlider(id, value)
  }

  const reset = () => {
    const rangeSlider = containerRef.current as TUiSLiderElement
    rangeSlider.noUiSlider.set([minInitial, maxInitial])
  }

  React.useEffect(() => {
    init()
  }, [])

  return { containerRef, endRef, startRef, inputChangeCallback, reset }
}

export default UseSliderFactory
