import styles from "./RemoveAccount.module.scss"
import React from "react"
import ConfirmationLayout from "components/ConfirmationLayout/ConfirmationLayout"
import { AnimatePresence } from "framer-motion"
import deleteUser from "api/user/deleteUser"
import { UseError } from "hooks"
import { useNavigate } from "react-router-dom"
import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"

type Props = {
  id: number
}

const RemoveAccount: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate()
  const { credentialsRemove } = React.useContext(CredentialsContext) as ICredentialsContext
  const { notify, notifySuccess } = UseError()
  const [popupOpened, setPopupOpened] = React.useState<boolean>(false)

  const popupCloseCallback = () => setPopupOpened(false)

  const popupConfirmCallback = async () => {
    try {
      await deleteUser(id)
      credentialsRemove()
      navigate("/")
      notifySuccess("Account removed!")
    } catch (e) {
      notify("user cannot be deleted now, please try again later")
    }
  }

  const openPopup = () => setPopupOpened(true)

  const renderConfirmationContent = (
    <React.Fragment>
      <h2>Are you sure you want</h2>
      <h2>to delete your account?</h2>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <div className={styles.removeAccount}>
        <h2 onClick={openPopup}>Delete My Account</h2>
      </div>
      <AnimatePresence mode="wait">
        {popupOpened ? (
          <ConfirmationLayout cancelCallback={popupCloseCallback} confirmCallback={popupConfirmCallback}>
            {renderConfirmationContent}
          </ConfirmationLayout>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default RemoveAccount
