import { ReactComponent as ArrowDown } from "assets/icons/propertyPage/arrowDown.svg"
import styles from "./PropertyPageHistoricalData.module.scss"
import { IHistoricalData } from "types/propertyData.types"
import formatPriceNoDecimal from "utils/FormatPriceNoDecimal"
import React from "react"

type Props = {
  historicalData: IHistoricalData[]
}

type TFormatType = "date" | "price" | "buyer" | "seller"

const missingList = {
  date: "Date Unavailable",
  price: "Sale Price Unavailable",
  buyer: "Buyer Name Unavailable",
  seller: "Seller Name Unavailable",
}

const PropertyPageHistoricalData: React.FC<Props> = ({ historicalData }) => {
  const step = 5
  const [historicalDataLimitedList, setHistoricalDataLimitedList] = React.useState<IHistoricalData[]>(
    historicalData.slice(0, 2)
  )
  const isSeeMoreButton = historicalData.length !== historicalDataLimitedList.length

  const formatText = (text: string | number, type: TFormatType) => {
    if (!text) return missingList[type]
    switch (type) {
      case "date": {
        return new Date(text).toLocaleDateString("en-US")
      }
      case "price": {
        return `$${formatPriceNoDecimal(text)}`
      }
      case "buyer":
      case "seller": {
        return String(text).length <= 37 ? text : `${String(text).split("").splice(0, 34).join("")}...`
      }
    }
  }

  const seeMoreHistoricalData = () =>
    setHistoricalDataLimitedList((prev) => [...prev, ...historicalData.slice(prev.length, prev.length + step)])

  const renderHistoricalDataBlock = (i: IHistoricalData) => {
    const { buyer, seller, value, date } = i

    return (
      <div className={styles.historyBlock} key={i.external_id}>
        <div className={styles.historyBlockItem}>
          <h2>{formatText(date, "date")}</h2>
          <h2 title={value}>{formatText(value, "price")}</h2>
        </div>
        <div className={styles.historyBlockItem}>
          <h2>Buyer:</h2>
          <h2 title={buyer}>{formatText(buyer, "buyer")}</h2>
        </div>
        <div className={styles.historyBlockItem}>
          <h2>Seller:</h2>
          <h2 title={seller}>{formatText(seller, "seller")}</h2>
        </div>
        <div className={styles.divider} />
      </div>
    )
  }

  const renderNoDataDescription = () => (
    <div className={styles.noData}>
      <h3>No history sales data found</h3>
    </div>
  )

  const renderSeeMoreButton = () => (
    <div className={styles.seeMoreButton} onClick={seeMoreHistoricalData}>
      <h2>See More</h2>
      <ArrowDown />
    </div>
  )

  const renderTitle = () => (
    <div className={styles.title}>
      <h2>Historical Sales Data</h2>
    </div>
  )

  return (
    <div className={styles.container}>
      {renderTitle()}
      {historicalDataLimitedList.length ? (
        <div className={styles.historicalDataItemsWrapper}>
          {historicalDataLimitedList.map((i) => renderHistoricalDataBlock(i))}
          {isSeeMoreButton ? renderSeeMoreButton() : null}
        </div>
      ) : (
        renderNoDataDescription()
      )}
    </div>
  )
}

export default PropertyPageHistoricalData
