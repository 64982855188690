import { BaseSyntheticEvent, Dispatch, FC, SetStateAction, useContext } from "react"
import MapMobileSlider from "../MapMobileSlider/MapMobileSlider"
import { ParcelContext } from "context/parcelContext"
import { TActiveTab } from "types/components.types"
import { useNavigate } from "react-router-dom"
import CardList from "../CardList/CardList"
import MapGeoJson from "../Map/MapGeoJson"
import styles from "./MapList.module.scss"
import clsx from "clsx"

type Props = {
  activeTab: TActiveTab
  setFiltersOpened: Dispatch<SetStateAction<boolean>>
}

const MapList: FC<Props> = ({ activeTab, setFiltersOpened }) => {
  const navigate = useNavigate()
  const { isOffmarket, parcelsList } = useContext(ParcelContext)

  const handleCardClick = (e: BaseSyntheticEvent) => {
    const uuid = e.target.getAttribute("data-id")
    const type = isOffmarket ? "unlisted" : "listed"
    if (uuid) navigate(`/parcels/view/${type}/${uuid}/false`)
  }

  return (
    <div
      className={clsx({
        [styles.switchContainer]: true,
        [styles.switchContainer_right]: activeTab === "list",
      })}
    >
      <div className={styles.map}>
        <MapGeoJson setFiltersOpened={setFiltersOpened} />
        <MapMobileSlider handleCardClick={handleCardClick} />
      </div>
      <div className={styles.cardList}>
        <CardList parcelsList={parcelsList} handleCardClick={handleCardClick} />
      </div>
    </div>
  )
}

export default MapList
