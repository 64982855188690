import { getParcelByUuidOffMarket } from "api/parcels/OffMarket/getParcelByUuidOffmarket"
import { createContext, useContext, useState } from "react"
import { MapboxGeoJSONFeature } from "mapbox-gl"
import { ParcelContext } from "./parcelContext"

export interface IMapPolygonContext {
  handleFeatureHover: (feature?: MapboxGeoJSONFeature) => void
  handleFeatureClick: (featureID?: number) => void
  onPolygonClick: (id: number | string | null) => Promise<void>
  dropPolygonSelection: () => void
}

export const MapPolygonContext = createContext({} as IMapPolygonContext)

export const MapPolygonContextProvider = ({ children }: { children: JSX.Element }) => {
  const { setActiveParcel, mapRef } = useContext(ParcelContext)
  const [hoveredStateId, setHoveredStateId] = useState<null | number>(null)
  const [selectedStateId, setSelectedStateId] = useState<null | number>(null)

  const changeFeatureState = (id: number | string | null, state: boolean, key: string) => {
    const map = mapRef.current
    if (!id || !map) return
    map.setFeatureState({ source: "my-data", id: id }, { [key]: state })
  }

  const onPolygonClick = async (id: number | string | null) => {
    if (!id) return
    try {
      const parcel = await getParcelByUuidOffMarket(id)
      if (parcel) {
        setActiveParcel(parcel)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const dropPolygonSelection = () => handleFeatureClick()

  const handleFeatureHover = (feature?: MapboxGeoJSONFeature) => {
    if (!mapRef.current?.getSource("my-data")) return
    if (feature) {
      const { id } = feature
      if (!id) return
      changeFeatureState(hoveredStateId as number, false, "hover")
      changeFeatureState(id as number, true, "hover")
      setHoveredStateId(id as number)
      return
    }
    if (hoveredStateId) {
      changeFeatureState(hoveredStateId, false, "hover")
      setHoveredStateId(null)
    }
  }

  const handleFeatureClick = (featureID?: number) => {
    if (!mapRef.current?.getSource("my-data")) return
    if (selectedStateId === featureID) return
    if (featureID) {
      changeFeatureState(selectedStateId, false, "selected")
      changeFeatureState(featureID, true, "selected")
      setSelectedStateId(featureID)
      onPolygonClick(featureID)
      return
    }
    changeFeatureState(selectedStateId, false, "selected")
    setSelectedStateId(null)
    onPolygonClick(null)
  }

  const value = { handleFeatureClick, handleFeatureHover, dropPolygonSelection, onPolygonClick }

  return <MapPolygonContext.Provider value={value}>{children}</MapPolygonContext.Provider>
}
