import CrossButton from "components/CrossButton/CrossButton"
import { AnimatePresence, motion } from "framer-motion"
import { TParcelPhotos } from "types/api.types"
import styles from "./ImageViewer.module.scss"
import Arrow from "components/Arrow/Arrow"
import React from "react"
import { TDivClickEvent } from "types/components.types"
import { fadeIn } from "constants/animationContants"

type Props = {
  photos: TParcelPhotos[]
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  opened: boolean
  activeImage: number
  next: (e: TDivClickEvent) => void
  back: (e: TDivClickEvent) => void
}

const ImageViewer: React.FC<Props> = ({ photos, opened, activeImage, setOpened, next, back }) => {
  const list = photos.map((i) => i.url)

  const close = () => setOpened(false)

  return (
    <AnimatePresence mode="wait">
      {opened && list.length ? (
        <motion.div className={styles.imageViewer} {...fadeIn} transition={{ duration: 0.2 }}>
          <div className={styles.imageViewerNavBar}>
            <h2>
              {activeImage + 1} / {list.length}
            </h2>
            <CrossButton onClick={close} />
          </div>
          <div className={styles.imageViewerImage}>
            <img src={list[activeImage]} alt="preview" />
            <div className={styles.imageViewerImageNavigation}>
              <Arrow dir="l" onClick={back} />
              <Arrow dir="r" onClick={next} />
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default ImageViewer
