import { useNavigate } from "react-router-dom"
import config from "./SingUp.config"
import React from "react"
import { AnimatePresence, usePresence } from "framer-motion"
import register from "api/user/register"
import { AxiosError } from "axios"
import { ILoginErrors } from "types/api.types"
import { CredentialsContext, ICredentialsContext } from "context/credentialsContext"

type Props = {}

const SignUp: React.FC<Props> = () => {
  const { setCredentials } = React.useContext(CredentialsContext) as ICredentialsContext
  const [isPresent, safeToRemove] = usePresence()
  const [personInfo, setPersonInfo] = React.useState({ email: "", password: "", name: "", surname: "" })
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const [steps, setSteps] = React.useState(config)

  const activeComponent = steps[currentStep]

  const navigate = useNavigate()

  const handleNextStep = () => {
    if (currentStep === 3) navigate("/")
    setCurrentStep((prev) => prev + 1)
  }

  const handleBackButton = () => {
    if (currentStep === 0) return
    setCurrentStep((prev) => prev - 1)
  }

  const personInfoCallback = (type: "email" | "password" | "name" | "surname", value: string) => {
    setPersonInfo((prev) => ({ ...prev, [type]: value }))
  }

  const registerUser = async () => {
    try {
      const { name, surname, email, password } = personInfo
      const data = {
        email,
        password1: password,
        password2: password,
        first_name: name,
        last_name: surname,
      }
      const token = await register(data)
      if (token) setCredentials(token)
    } catch (err) {
      const messages = (err as AxiosError)?.response?.data as Partial<ILoginErrors>
      return messages
    }
  }

  React.useEffect(() => {
    if (!isPresent) {
      handleNextStep()
      setTimeout(safeToRemove, 1000)
    }
  }, [isPresent])

  return (
    <AnimatePresence mode="wait">
      <activeComponent.component
        backButtonCallback={handleBackButton}
        buttonClickCallback={handleNextStep}
        inputChangeCallback={personInfoCallback}
        registerUser={registerUser}
        key={currentStep}
      />
    </AnimatePresence>
  )
}

export default SignUp
